import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { useFetch1 } from "./useFetch";
import { Signin, Signup, Signout, PhoneVerification, ActivationObavijest, ForgotPassword } from "./login";
import { FlashTip } from "./flash";
import { Loading, NotFound } from "./notFound";
import { VozaciIzbornik, VozilaIzbornik } from "./admin";
import { Tablica } from "./tablica";
import { DoprinosiIzbornik } from "./doprinosi";
import { PrometiIzbornik } from "./prometi";
import { Obracun, ObracunIzbornik } from "./obracun";
import { TransakcijeIzbornik } from "./transakcije";
import { Settings } from "./settings";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const ADRESA = "";
//export const ADRESA = "/lipavita";

//export const ADRESA1 = "http://localhost:3000"
export const ADRESA1 = "https://backend.novaaplikacija.eu"

export const localStorageSw = true;

type KontekstType = {
  lang: string;
  setLang: React.Dispatch<React.SetStateAction<string>>;
  kljuc: string;
  setKljuc: React.Dispatch<React.SetStateAction<string>>;
  refreshKljuc: string;
  setRefreshKljuc: React.Dispatch<React.SetStateAction<string>>;
}

type FlashKontekstTip = {
  flashPoruke: FlashTip[];
  setFlashPoruke: React.Dispatch<React.SetStateAction<FlashTip[]>>;
  //pp: (poruka:string, tip?: "danger" | "success", kod?: null | string) => void;
  postaviFlashPoruku: (poruka:string, tip:"danger" | "success", kod: null | string) => void;
}

type SettingsKontekstTip = {
  email: null | string;
  setEmail: React.Dispatch<React.SetStateAction<null | string>>;
  activationSw: null | boolean;
  setActivationSw: React.Dispatch<React.SetStateAction<null | boolean>>;
  verificationSw: null | boolean;
  setVerificationSw: React.Dispatch<React.SetStateAction<null | boolean>>;
  verificationType: null | "sms" | "email" | "whatsApp";
  setVerificationType: React.Dispatch<React.SetStateAction<null | "sms" | "email" | "whatsApp">>;
  tipProvizije: null | "fiksni" | "provizija" | "postotak";
  setTipProvizije: React.Dispatch<React.SetStateAction<null | "fiksni" | "provizija" | "postotak">>;
  provizija: null | string;
  setProvizija: React.Dispatch<React.SetStateAction<null | string>>;
  mjesecniDoprinos: string;
  setMjesecniDoprinos: React.Dispatch<React.SetStateAction<string>>;
  flashMessageTime: null | string;
  setFlashMessageTime: React.Dispatch<React.SetStateAction<null | string>>;
  flashMessageSw: null | boolean;
  setFlashMessageSw: React.Dispatch<React.SetStateAction<null | boolean>>;
}

type StateKontekstTip = {
  odabraniVozacIDVozaci: string;
  setOdabraniVozacIDVozaci: React.Dispatch<React.SetStateAction<string>>;
  checkSwVozaci: boolean;
  setCheckSwVozaci: React.Dispatch<React.SetStateAction<boolean>>;
  checkSw1Vozaci: boolean;
  setCheckSw1Vozaci: React.Dispatch<React.SetStateAction<boolean>>;
  searchStrVozaci: string;
  setSearchStrVozaci: React.Dispatch<React.SetStateAction<string>>;
  checkNickSwVozaci: boolean;
  setCheckNickSwVozaci: React.Dispatch<React.SetStateAction<boolean>>;
  checkImeSwVozaci: boolean;
  setCheckImeSwVozaci: React.Dispatch<React.SetStateAction<boolean>>;
  checkPrezimeSwVozaci: boolean;
  setCheckPrezimeSwVozaci: React.Dispatch<React.SetStateAction<boolean>>;
  odabranoVoziloIDVozila: string;
  setOdabranoVoziloIDVozila: React.Dispatch<React.SetStateAction<string>>;
  searchStrVozila: string;
  setSearchStrVozila: React.Dispatch<React.SetStateAction<string>>;

  odabraniVozacIDDoprinosi: string;
  setOdabraniVozacIDDoprinosi: React.Dispatch<React.SetStateAction<string>>;
  odabraniDoprinosIDDoprinosi: string;
  setOdabraniDoprinosIDDoprinosi: React.Dispatch<React.SetStateAction<string>>;
  searchStrDoprinosi: string;
  setSearchStrDoprinosi: React.Dispatch<React.SetStateAction<string>>;
  checkNickSwDoprinosi: boolean;
  setCheckNickSwDoprinosi: React.Dispatch<React.SetStateAction<boolean>>;
  checkImeSwDoprinosi: boolean;
  setCheckImeSwDoprinosi: React.Dispatch<React.SetStateAction<boolean>>;
  checkPrezimeSwDoprinosi: boolean;
  setCheckPrezimeSwDoprinosi: React.Dispatch<React.SetStateAction<boolean>>;

  odabraniVozacIDPrometi: string;
  setOdabraniVozacIDPrometi: React.Dispatch<React.SetStateAction<string>>;
  odabraniPrometIDPrometi: string;
  setOdabraniPrometIDPrometi: React.Dispatch<React.SetStateAction<string>>;
  searchStrPrometi: string;
  setSearchStrPrometi: React.Dispatch<React.SetStateAction<string>>;
  checkNickSwPrometi: boolean;
  setCheckNickSwPrometi: React.Dispatch<React.SetStateAction<boolean>>;
  checkImeSwPrometi: boolean;
  setCheckImeSwPrometi: React.Dispatch<React.SetStateAction<boolean>>;
  checkPrezimeSwPrometi: boolean;
  setCheckPrezimeSwPrometi: React.Dispatch<React.SetStateAction<boolean>>;

  odabraniVozacIDObracuni: string;
  setOdabraniVozacIDObracuni: React.Dispatch<React.SetStateAction<string>>;
  searchStrObracuni: string;
  setSearchStrObracuni: React.Dispatch<React.SetStateAction<string>>;
  checkNickSwObracuni: boolean;
  setCheckNickSwObracuni: React.Dispatch<React.SetStateAction<boolean>>;
  checkImeSwObracuni: boolean;
  setCheckImeSwObracuni: React.Dispatch<React.SetStateAction<boolean>>;
  checkPrezimeSwObracuni: boolean;
  setCheckPrezimeSwObracuni: React.Dispatch<React.SetStateAction<boolean>>;

  odabraniVozacIDTransakcije: string;
  setOdabraniVozacIDTransakcije: React.Dispatch<React.SetStateAction<string>>;
  searchStrTransakcije: string;
  setSearchStrTransakcije: React.Dispatch<React.SetStateAction<string>>;
  searchStr1Transakcije: string;
  setSearchStr1Transakcije: React.Dispatch<React.SetStateAction<string>>;
  odabranaTransakcijaIDTransakcije: string;
  setOdabranaTransakcijaIDTransakcije: React.Dispatch<React.SetStateAction<string>>;
  checkNickSwTransakcije: boolean;
  setCheckNickSwTransakcije: React.Dispatch<React.SetStateAction<boolean>>;
  checkImeSwTransakcije: boolean;
  setCheckImeSwTransakcije: React.Dispatch<React.SetStateAction<boolean>>;
  checkPrezimeSwTransakcije: boolean;
  setCheckPrezimeSwTransakcije: React.Dispatch<React.SetStateAction<boolean>>;
  checkBankovniRacunSwTransakcije: boolean;
  setCheckBankovniRacunSwTransakcije: React.Dispatch<React.SetStateAction<boolean>>;
  checkTransakcijeSwTransakcije: boolean;
  setCheckTransakcijeSwTransakcije: React.Dispatch<React.SetStateAction<boolean>>;
  selektorIDTransakcije: string;
  setSelektorIDTransakcije: React.Dispatch<React.SetStateAction<string>>;
}

export const Kontekst = React.createContext({} as KontekstType);
export const FlashKontekst = React.createContext({} as FlashKontekstTip);
export const StateKontekst = React.createContext({} as StateKontekstTip);
export const SettingsKontekst = React.createContext({} as SettingsKontekstTip);

type VozacType = {
  br: number;
}

function Vozac1({br=0}: VozacType) {
  const {lang, setLang, kljuc, setKljuc} = React.useContext(Kontekst);
  const [br1, setBr1] = React.useState(0);
  const [status, setStatus] = React.useState("loading");
  const [akcija, setAkcija] = React.useState("ucitaj");
  const [signoutSw, setSignoutSw] = React.useState(false);
  const [podaci, setPodaci] = React.useState<{[index:string]: any}[]>([]);
  const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
  const [pomak, setPomak] = React.useState(0);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

  const r = React.useRef<HTMLDivElement | null>(null);

  const [loading, error, value] = useFetch1(ADRESA1+"/api/zasticena", 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": akcija
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br1]);

  React.useEffect(()=>{
    console.log("loading2: " + loading);
    console.log("error2: " + error);
    console.log("value2: ");
    console.log(value);

    if (value !== undefined && typeof value !== "boolean") {
      console.log("provjerio sam kljuc " + kljuc);
      if (value.error) {
        console.log("stranica je otvorena sa greskom... " + Math.random());
        if (value.errorCode === "telefon nije verificiran") {
          setStatus("verify");
        } else if (value.errorCode === "Racun nije aktivan. Kontaktirajte administratora") {
          console.log(value.errorCode);
          //postaviFlashPoruku(value.errorCode, "danger", "signin");
          setStatus("activate");
        } else {
          console.log("niste ulogirani");
          console.log(value.errorCode);
          setStatus("sign-in");
        }
      } else if (!loading && !value.error){
        console.log("ULOGIRANI ste");
        console.log(value.errorCode);
        setStatus("logged-in");
        setPodaci(value.value.podaci);
        setOdabraniVozacID(value.value.podaci[0].id);
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    console.log("pokrenuo " + br1 + " " + Math.random());
    if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
  }, [kljuc]);

  React.useEffect(()=>{
    if (status !== "logged-in") {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setPomak(scrollTop);
      if (r.current !== null)  r.current.style.position = "fixed";
      if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
    } else {
      if (r.current !== null)  r.current.style.top = "0px";
      if (r.current !== null)  r.current.style.position = "relative";
      window.scrollTo(0, pomak);
    }
  }, [status]);

  function signout() {
    console.log("Upravo ste pokrenuli sign out proceduru. " + Math.random());
  }

  const showSw = [false,true,true,true,true,true,true,true,true,true,true,true,false,true,true,true,false,false,true,true];

  return (
    <div className="holder" ref={r}>
    <div className="okvir">
      {status === "loading" ? <Loading sw={true}/> : <>
        {status === "sign-in" ? <Signin setStatus={setStatus}/> : null}
        {status === "sign-up" ? <Signup setStatus={setStatus}/> : null}
        {status === "verify" ? <PhoneVerification resetBr={setBr1} setStatus={setStatus}/> : null}
        {status === "activate" ? <ActivationObavijest setStatus={setStatus}/> : null}
        {status === "forgot password" ? <ForgotPassword setStatus={setStatus}/> : null}
        {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
        <Navbar vozacSw={true} current={1} status={status} signout={()=>{setSignoutSw(true)}}/>
        <h2 className="naslov">Podaci o vozacu</h2>
        
        <Tablica odabraniVozacID={odabraniVozacID}  podaci={podaci} setPodaci={setPodaci} adminSw={false}
                 path="/api/zasticena" showSw={showSw} prekidaci={[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,false,true,true,true,true]}/>
        <div className="buffer-okvir"></div>
      </>}
    </div>
    <Footer />
    </div>
  )
}

function Glavna() {
  const {lang, setLang} = React.useContext(Kontekst);

  return (
    <>

      <div className="okvir">

        <Navbar current={0}/>
        <h2 className="naslov">Ovo je glavna stranica</h2>
        <p>jezik je {lang}</p>
      </div>
      <Footer /> 
    </>
  )
}

function AdminRedirect() {
  const navigate = useNavigate();

  React.useEffect(()=>{
    navigate("/admin/vozaci", {replace: true});
  },[])

  return (
    <div></div>
  )
}

function App() {
  const [lang, setLang] = React.useState("hr");
  const [kljuc, setKljuc] = React.useState("-");
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [refreshKljuc, setRefreshKljuc] = React.useState("-");
  const [flashPoruke, setFlashPoruke] = React.useState<FlashTip[]>([]);

  // slijede varijable zapamcenih stanja pojedinih stranica
  const [odabraniVozacIDVozaci, setOdabraniVozacIDVozaci] = React.useState("-");
  const [checkSwVozaci, setCheckSwVozaci] = React.useState(false);
  const [checkSw1Vozaci, setCheckSw1Vozaci] = React.useState(false);
  const [searchStrVozaci, setSearchStrVozaci] = React.useState("");
  const [checkNickSwVozaci, setCheckNickSwVozaci] = React.useState(true);
  const [checkImeSwVozaci, setCheckImeSwVozaci] = React.useState(true);
  const [checkPrezimeSwVozaci, setCheckPrezimeSwVozaci] = React.useState(true);

  const [odabranoVoziloIDVozila, setOdabranoVoziloIDVozila] = React.useState("-");
  const [searchStrVozila, setSearchStrVozila] = React.useState("");

  const [odabraniVozacIDDoprinosi, setOdabraniVozacIDDoprinosi] = React.useState("-");
  const [odabraniDoprinosIDDoprinosi, setOdabraniDoprinosIDDoprinosi] = React.useState("-");
  const [searchStrDoprinosi, setSearchStrDoprinosi] = React.useState("");
  const [checkNickSwDoprinosi, setCheckNickSwDoprinosi] = React.useState(true);
  const [checkImeSwDoprinosi, setCheckImeSwDoprinosi] = React.useState(true);
  const [checkPrezimeSwDoprinosi, setCheckPrezimeSwDoprinosi] = React.useState(true);

  const [odabraniVozacIDPrometi, setOdabraniVozacIDPrometi] = React.useState("-");
  const [odabraniPrometIDPrometi, setOdabraniPrometIDPrometi] = React.useState("-");
  const [searchStrPrometi, setSearchStrPrometi] = React.useState("");
  const [checkNickSwPrometi, setCheckNickSwPrometi] = React.useState(true);
  const [checkImeSwPrometi, setCheckImeSwPrometi] = React.useState(true);
  const [checkPrezimeSwPrometi, setCheckPrezimeSwPrometi] = React.useState(true);

  const [odabraniVozacIDObracuni, setOdabraniVozacIDObracuni] = React.useState("-");
  const [searchStrObracuni, setSearchStrObracuni] = React.useState("");
  const [checkNickSwObracuni, setCheckNickSwObracuni] = React.useState(true);
  const [checkImeSwObracuni, setCheckImeSwObracuni] = React.useState(true);
  const [checkPrezimeSwObracuni, setCheckPrezimeSwObracuni] = React.useState(true);

  const [odabraniVozacIDTransakcije, setOdabraniVozacIDTransakcije] = React.useState("-");
  const [searchStrTransakcije, setSearchStrTransakcije] = React.useState("");
  const [searchStr1Transakcije, setSearchStr1Transakcije] = React.useState("");
  const [checkNickSwTransakcije, setCheckNickSwTransakcije] = React.useState(true);
  const [checkImeSwTransakcije, setCheckImeSwTransakcije] = React.useState(true);
  const [checkPrezimeSwTransakcije, setCheckPrezimeSwTransakcije] = React.useState(true);
  const [checkBankovniRacunSwTransakcije, setCheckBankovniRacunSwTransakcije] = React.useState(false);
  const [odabranaTransakcijaIDTransakcije, setOdabranaTransakcijaIDTransakcije] = React.useState("");
  const [checkTransakcijeSwTransakcije, setCheckTransakcijeSwTransakcije] = React.useState(false);
  const [selektorIDTransakcije, setSelektorIDTransakcije] = React.useState("-");

  const [email, setEmail] = React.useState<null | string>("slobodan@mail.com");
  const [activationSw, setActivationSw] = React.useState<null | boolean>(null);
  const [verificationSw, setVerificationSw] = React.useState<null | boolean>(null);
  const [verificationType, setVerificationType] = React.useState<null | "sms" | "email" | "whatsApp">(null);
  const [tipProvizije, setTipProvizije] = React.useState<null | "fiksni" | "provizija" | "postotak">(null);
  const [provizija, setProvizija] = React.useState<null | string>(null);
  const [mjesecniDoprinos, setMjesecniDoprinos] = React.useState("256");
  const [flashMessageTime, setFlashMessageTime] = React.useState<null | string>(null);
  const [flashMessageSw, setFlashMessageSw] = React.useState<null | boolean>(null);


  const r = React.useRef<number | null>(null);
  const r1 = React.useRef<number | null>(null);

  React.useEffect(()=>{
    //localStorage.setItem("kljuc", "tczh7VK0lqNH5U2G518lecrRFwpfkgjJoZueUIN");
    let k;
    if (localStorageSw) {
      k = localStorage.getItem("kljuc");
    } else {
      k = sessionStorage.getItem("kljuc");
    }
    if (k === null || k === "-") {
      setKljuc("");
    } else {
      setKljuc(k);
    }
    console.log("kljuc je " + kljuc);
    if (localStorageSw) {
      k = localStorage.getItem("refreshKljuc");
    } else {
      k = sessionStorage.getItem("refreshKljuc");
    }
    if (k === null || k === "-") {
      setRefreshKljuc("");
    } else {
      setRefreshKljuc(k);
    }

  }, []);

  React.useEffect(()=>{
    if (kljuc !== "-") {
    console.log("novi kljuc je " + kljuc);
    console.log("novi refresh kljuc je " + refreshKljuc);
    if (localStorageSw) {
      localStorage.setItem("kljuc", kljuc);
      localStorage.setItem("refreshKljuc", refreshKljuc);
    } else {
      sessionStorage.setItem("kljuc", kljuc);
      sessionStorage.setItem("refreshKljuc", refreshKljuc);
    }
    setBr((prev)=>{return prev+1});
    }

  }, [kljuc]);

  const [loading, error, value] = useFetch1(ADRESA1 + "/api/settings", 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": "ucitaj"
      }),
       headers: {
         'Content-type': 'application/json'
       }
    }, [br]);
  
    React.useEffect(()=>{  
      if (br !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA1");
          console.log(value.errorCode);
          setEmail(null);
          setActivationSw(null);
          setVerificationSw(null);
          setVerificationType(null);
          setTipProvizije(null);
          setProvizija(null);
          setMjesecniDoprinos("");
          setFlashMessageTime(null);
          setFlashMessageSw(null);
        } else if (!loading && !value.error){
          console.log("cini se da je sve ucitano");
          console.log(value.value);
          
          setEmail(value.value.podaci.email);
          setActivationSw(value.value.podaci.activationSw);
          setVerificationSw(value.value.podaci.verificationSw);
          setVerificationType(value.value.podaci.verification_type);
          setTipProvizije(value.value.podaci.tip_provizije)
          setProvizija(value.value.podaci.provizija);
          setMjesecniDoprinos(value.value.podaci.mjesecni_doprinos);
          setFlashMessageTime(value.value.podaci.flash_message_time);
          setFlashMessageSw(value.value.podaci.flash_messageSw);
        
        }
      }
    }, [loading]);
  
    const [loading1, error1, value1] = useFetch1(ADRESA1 + "/api/refresh_token", 
    {
      method: 'POST',
      body: JSON.stringify({
        "refreshToken": refreshKljuc
      }),
       headers: {
         'Content-type': 'application/json'
       }
    }, [br1]);
  
    React.useEffect(()=>{  
      if (br1 !== 0 && value1 !== undefined && typeof value1 !== "boolean") {
        if (value1.error) {
          console.log("GRESKA1a");
          console.log(value1.errorCode);
          console.log("refreshtoken nevazeci je " + refreshKljuc);

        } else if (!loading1 && !value1.error){
          console.log("cini se da je sve ucitano");
          console.log(value1.value);
          setKljuc(value1.value.token);
          setRefreshKljuc(value1.value.refreshToken);
          console.log("REFRESHALI smo sa " + value1.value.token);
          console.log("REFRESHALI1 smo sa " + value1.value.refreshToken);
          console.log("br1 je na " + br1);
        }
      }
    }, [loading1]);

  React.useEffect(()=>{
    loadFun();
    setBr((prev)=>{return prev+1});
    setBr1((prev)=>{return prev+1});
    r1.current = window.setInterval(()=>{
      console.log("Upravo REFRESHAMO kljuceve " + Math.random());
      setBr1((prev)=>{return prev+1});}, 60*1000*30);


    return ()=>{
      window.removeEventListener("keydown", pritisakGumba);
      if (r1.current !== null) window.clearInterval(r1.current);
    };
  },[]); 

  function loadFun() {
    console.log("Cijela stranica je potpuno ucitana... " + Math.random());
    //setLoadSw(true);

    // helper kod za dizajn stranice
    window.addEventListener("keydown", pritisakGumba);
    
  }

  function pritisakGumba(e: KeyboardEvent) {
    e.stopPropagation();
    let ell = document.querySelector("body");
    if (e.code === "KeyS") {
      console.log("sirina/visina prozora su " + ell?.clientWidth + " / " + ell?.clientHeight + "    -  " + Math.random());
      console.log(Math.random());
    } else if (false && e.code === "KeyR") {
      setBr1((prev)=>{return prev+1});
    }
  }

  function postaviFlashPoruku(poruka: string, tip: "danger" | "success", kod: null | string) {
    //function pp({poruka="neka poruka", tip="danger", kod=null}:postaviFlashPorukuProps) {    
      if (r.current) clearTimeout(r.current);
      //console.log("pozvao sam postaviFlashporuku " + Math.random());
      r.current = window.setTimeout(()=>{
        setFlashPoruke((prev)=>{
          let msec = (new Date()).getTime();
          if (prev.length == 0 || prev[0].id !== msec) {
            prev.unshift({id:msec, poruka:poruka, tip:tip, kod:kod}); 
          }
          return [...prev];});
      }, 100);
    }

  return (
    <Kontekst.Provider value={{lang, setLang, kljuc, setKljuc, refreshKljuc, setRefreshKljuc}}>
      <FlashKontekst.Provider value={{flashPoruke, setFlashPoruke, postaviFlashPoruku}}>
        <StateKontekst.Provider value={{odabraniVozacIDVozaci, setOdabraniVozacIDVozaci, checkSwVozaci, setCheckSwVozaci, 
          checkSw1Vozaci, setCheckSw1Vozaci, searchStrVozaci, setSearchStrVozaci, checkNickSwVozaci, setCheckNickSwVozaci,
          checkImeSwVozaci, setCheckImeSwVozaci, checkPrezimeSwVozaci, setCheckPrezimeSwVozaci, odabranoVoziloIDVozila,
          setOdabranoVoziloIDVozila, searchStrVozila, setSearchStrVozila, odabraniVozacIDDoprinosi, setOdabraniVozacIDDoprinosi,
          searchStrDoprinosi, setSearchStrDoprinosi, checkNickSwDoprinosi, setCheckNickSwDoprinosi, checkImeSwDoprinosi, setCheckImeSwDoprinosi,
          checkPrezimeSwDoprinosi, setCheckPrezimeSwDoprinosi, odabraniDoprinosIDDoprinosi, setOdabraniDoprinosIDDoprinosi,
          odabraniVozacIDPrometi, setOdabraniVozacIDPrometi, odabraniPrometIDPrometi, setOdabraniPrometIDPrometi, checkNickSwPrometi, 
          setCheckNickSwPrometi, checkImeSwPrometi, setCheckImeSwPrometi, checkPrezimeSwPrometi, setCheckPrezimeSwPrometi,  
          searchStrPrometi, setSearchStrPrometi, odabraniVozacIDObracuni, setOdabraniVozacIDObracuni, checkNickSwObracuni, 
          setCheckNickSwObracuni, checkImeSwObracuni, setCheckImeSwObracuni, checkPrezimeSwObracuni, setCheckPrezimeSwObracuni,  
          searchStrObracuni, setSearchStrObracuni, odabraniVozacIDTransakcije, setOdabraniVozacIDTransakcije, searchStrTransakcije, setSearchStrTransakcije, 
          searchStr1Transakcije, setSearchStr1Transakcije, checkNickSwTransakcije, setCheckNickSwTransakcije, checkImeSwTransakcije,
          setCheckImeSwTransakcije, checkPrezimeSwTransakcije, setCheckPrezimeSwTransakcije, checkBankovniRacunSwTransakcije, 
          setCheckBankovniRacunSwTransakcije, checkTransakcijeSwTransakcije, setCheckTransakcijeSwTransakcije, odabranaTransakcijaIDTransakcije,
          setOdabranaTransakcijaIDTransakcije, selektorIDTransakcije, setSelektorIDTransakcije}}>
          <SettingsKontekst.Provider value={{email, setEmail, activationSw, setActivationSw, verificationSw, setVerificationSw, 
            verificationType, setVerificationType, tipProvizije, setTipProvizije, provizija, setProvizija, mjesecniDoprinos, setMjesecniDoprinos, flashMessageTime, setFlashMessageTime, 
            flashMessageSw, setFlashMessageSw}}>
        <Router>
          <Routes>
             
            <Route path={ADRESA+"/"} element={<Vozac1 br={1}/>} />    
            <Route path={ADRESA+"/obracun"} element={<Obracun br={2}/>} />    
            <Route path={ADRESA+"/admin/"} element={<AdminRedirect/>} />        
            <Route path={ADRESA+"/admin/vozaci"} element={<VozaciIzbornik/>} />    
            <Route path={ADRESA+"/admin/vozila"} element={<VozilaIzbornik/>} /> 
            <Route path={ADRESA+"/admin/doprinosi"} element={<DoprinosiIzbornik/>} /> 
            <Route path={ADRESA+"/admin/prometi"} element={<PrometiIzbornik/>} />
            <Route path={ADRESA+"/admin/obracun"} element={<ObracunIzbornik/>} />
            <Route path={ADRESA+"/admin/transakcije"} element={<TransakcijeIzbornik/>} />
            <Route path={ADRESA+"/admin/postavke"} element={<Settings/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Router>
          </SettingsKontekst.Provider>
        </StateKontekst.Provider>
      </FlashKontekst.Provider>
    </Kontekst.Provider>
  );
}


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


