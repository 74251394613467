import React from "react";
import ReactDOM from 'react-dom/client';
import {
    Link
} from "react-router-dom";
import { ADRESA, Kontekst} from "./index";
import { Gumb } from "./login";
import "./notFound.scss";

type ConfirmChoiceProps = {
  tekst?: string;
  setConfirmAction?: React.Dispatch<React.SetStateAction<string | null>>;
}

export function ConfirmChoice({tekst = "neki tekst", setConfirmAction = ()=>{}}: ConfirmChoiceProps) {

  return (
    <div className="popup-plast-confirm-choice" onClick={(e)=>{e.stopPropagation()}}>
      <div className="popup-confirm-choice">
        <div className="naslov">{tekst}</div>
          <div className="gumbi">
            <Gumb fun={()=>{setConfirmAction("no")}} tekst="No" height="30px" width="60px" />
            <Gumb fun={()=>{setConfirmAction("yes")}} tekst="Yes" height="30px" width="60px" />
          </div>
      </div>
    </div>
  )
}

export function NotFound() {
  return (
    <div className="not-found">
      <p className="p-el1">Ooops...</p>
      <p className="p-el2">It seems you left our site. Nobody wants to do that.</p>
      <p className="p-el3">Did you really mean it?</p>
      <Link className="link" to={ADRESA+"/"}>No, we did not think so. Welcome back!</Link>
    </div>
  )
}

export function Loading({sw=false}) {
  const [br, setBr] = React.useState(0);

  const r = React.useRef<NodeJS.Timer | null>(null);
  const r1 = React.useRef<HTMLDivElement>(null);
  const r2 = React.useRef<HTMLDivElement>(null);
  const r3 = React.useRef<HTMLDivElement>(null);
  const r4 = React.useRef<HTMLDivElement>(null);
  const r5 = React.useRef<HTMLDivElement>(null);

  const p = React.useRef<HTMLDivElement>(null);

  React.useEffect(()=>{
    r.current = setInterval(()=>{setBr((prev)=>{return (prev+1)%5})}, 600);

    return ()=>{if (r.current) clearInterval(r.current);};
  }, []);

  React.useEffect(()=>{
    if (sw) {
        if (p.current) p.current.style.display = "flex";
    } else {
        if (p.current) p.current.style.display = "none";
    }
  }, [sw]);

  React.useEffect(()=>{
    let defaul = "#999";
    let boja = "black";

    //console.log("broj je " + br);
    if (r1.current) r1.current.style.backgroundColor = defaul;
    if (r2.current) r2.current.style.backgroundColor = defaul;
    if (r3.current) r3.current.style.backgroundColor = defaul;
    if (r4.current) r4.current.style.backgroundColor = defaul;
    if (r5.current) r5.current.style.backgroundColor = defaul;

    switch (br) {
      case 0:
        if (r1.current) r1.current.style.backgroundColor = boja;
        break;
      case 1:
        if (r2.current) r2.current.style.backgroundColor = boja;
        break;
      case 2:
        if (r3.current) r3.current.style.backgroundColor = boja;
        break;
      case 3: 
        if (r4.current) r4.current.style.backgroundColor = boja;
        break;
      case 4:
        if (r5.current) r5.current.style.backgroundColor = boja;
    }
  }, [br]);

  return (
    <div ref={p} className="loading">
      <p className="p-el">Loading
        <span className="p-span">
          <div ref={r1} className="el el1"></div>
          <div ref={r2} className="el el2"></div>
          <div ref={r3} className="el el3"></div>
          <div ref={r4} className="el el4"></div>
          <div ref={r5} className="el el5"></div>
        </span>
      </p>  
    </div>
  )
}

export function filterStr(str: string, space=true, dot=false): boolean {
  for (let i = 0; i < str.length; i++) {
    let c = str[i];
    switch (c) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        break;
      case ".":
        if (!dot)  return false;
        break;
      case " ":
        if (!space)  return false;
        break;
      default:
        return false;
    }
  }

  return true;
}

export function filter(e: React.KeyboardEvent<HTMLInputElement>, space=true, backspace=true, dot=false) {
  //console.log("kod je ");
  //console.log(e.code);
  switch (e.code) {
    case "Digit0":
    case "Digit1":
    case "Digit2":
    case "Digit3":
    case "Digit4":
    case "Digit5":
    case "Digit6":
    case "Digit7":
    case "Digit8":
    case "Digit9":
    case "Delete":
    case "ArrowLeft":
    case "ArrowRight":
      break;
    case "Period":
      if (!dot)  e.preventDefault();
      break;
    case "Space":
      if (!space)  e.preventDefault();
      break;
    case "Backspace":
      if (!backspace)  e.preventDefault();
      break;
    default:
      e.preventDefault();
  }

}

export function ispisImena(username: string | null, ime: string | null, prezime: string | null,
  sw1: boolean, sw2: boolean, sw3: boolean): string {

  let rez = "";
  if (username !== null && sw1) {
    rez += username;
    if ((sw2 && ime !== null) || (sw3 && prezime !== null))  rez += " - ";
  }
  if (ime !== null && sw2) {
    rez += ime;
    if (prezime !== null && sw3) {
      rez += " " + prezime;
    }
  } else {
    if (prezime !== null && sw3) {
      rez += prezime;
    }
  }
  return rez;
} 

export function ispisImena1(username: string | null, ime: string | null, prezime: string | null, bankovniRacun: string | null, 
  sw1: boolean, sw2: boolean, sw3: boolean, sw4: boolean): string {

  let rez = "";
  if (username !== null && sw1) {
    rez += username;
    if ((sw2 && ime !== null) || (sw3 && prezime !== null) || (sw4 && bankovniRacun !== null))  rez += " - ";
  }
  if (ime !== null && sw2) {
    rez += ime;
    if (prezime !== null && sw3) {
      rez += " " + prezime;
    }
    if (bankovniRacun !== null && sw4) {
      rez += " - " + bankovniRacun;
    }
  } else {
    if (prezime !== null && sw3) {
      rez += prezime;
      if (bankovniRacun !== null && sw4) {
        rez += " - " + bankovniRacun;
      }
    } else {
      if (bankovniRacun !== null && sw4) {
        rez += bankovniRacun;
      }
    }
  }
  return rez;
} 

export function ispis1(inp: string | number | null | undefined): string {
  if (inp === null || inp === undefined) {
    return "0";
  } else {
    return inp.toString();
  }
}

export function ispis(inp: boolean | string | number | null | undefined): string {
  //const maxLineLength = 10;
  if (inp === null || inp === undefined) {
      return "-";
  } else if (typeof inp == "boolean") {
    if (inp) {
      return "YES";
    } else {
      return "NO";
    }
  } else {
      return inp.toString();
  }
}

export function ispisSaDecimalama(inp: string | number | null | undefined, brDec:number = 2): string {
  //const maxLineLength = 10;
  if (inp === null || inp === undefined) {
      return "-";
  } else if (typeof inp === "number") {
      return inp.toFixed(brDec);
  } else {
      if (inp === "inf") return inp;
      return parseFloat(inp).toFixed(brDec);
  }
}

export function ispisId(inp: string | number | null): string {
  if (inp === null) {
    return "";
  } else {
    return inp.toString();
  }
}

export function novoPolje<T>(vrijednost: T, vel:number): T[] {
  let polje: T[] = new Array(vel);
  for (let i = 0; i < vel; i++) {
    polje[i] = vrijednost;
  }
  return polje;
}

export function ukloniNull(str: string | null) : string {
  if (str === null) return "";
  return str;
}

export function nullToDoDaljnjeg(str: string | null) {
  if (str === null) {
    return "DO DALJNJEG";
  } else {
    return str;
  }
}

export function booleanToString(str: boolean | null) : "YES" | "NO" | null {

  if (str === null) return null;

  if (str) {
    return "YES";
  } else {
    return "NO";
  }
}

export function unosBooleana(str: string | null) : (boolean | null) {
  if (str === null) return null;
  if (str === "YES") return true;
  return false;
}

export function emptyIsNull(str: string | null) : string | null {
  if (str === null) return null;
  if (str.length === 0) return null;
  return str;
}

export function ukloniPrazneZnakoveNull(str: string | null, rubniSw?: boolean, razmaciSw?: boolean): string | null {
  if (typeof rubniSw === "undefined") rubniSw = true; 
  if (typeof razmaciSw === "undefined") razmaciSw = false;

  if (str === null)  return null;

  let len = str.length;
  if (rubniSw && razmaciSw) {
    let rez = "";
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        rez += c;
      }
    }
    return rez;
  } else if (!rubniSw && !razmaciSw) {
    return str;
  } else if (rubniSw && !razmaciSw) {
    let p1 = -1;
    let p2 = len-1;
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        p1 = i;
        break;
      }
    }
    if (p1 === -1) return "";

    for (let i = len-1; i > -1; i--) {
      let c = str[i];
      if (c !== " ") {
        p2 = i;
        break;
      }
    }
    return str.substring(p1, p2+1);
  } else {  // rubne ne uklanjamo, razmake uklanjamo
    let p1 = -1;
    let p2 = len-1;
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        p1 = i;
        break;
      }
    }
    if (p1 === -1) return "";
    
    for (let i = len-1; i > -1; i--) {
      let c = str[i];
      if (c !== " ") {
        p2 = i;
        break;
      }
    }
    let rez = "";
    for (let i = p1; i < p2+1; i++) {
      let c = str[i];
      if (c !== " ")  rez += c;
    }
    return str.substring(0,p1) + rez + str.substring(p2+1);
  }

}

export function ukloniPrazneZnakove(str: string, rubniSw?: boolean, razmaciSw?: boolean): string {
  if (typeof rubniSw === "undefined") rubniSw = true; 
  if (typeof razmaciSw === "undefined") razmaciSw = false;

  let len = str.length;
  if (rubniSw && razmaciSw) {
    let rez = "";
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        rez += c;
      }
    }
    return rez;
  } else if (!rubniSw && !razmaciSw) {
    return str;
  } else if (rubniSw && !razmaciSw) {
    let p1 = -1;
    let p2 = len-1;
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        p1 = i;
        break;
      }
    }
    if (p1 === -1) return "";

    for (let i = len-1; i > -1; i--) {
      let c = str[i];
      if (c !== " ") {
        p2 = i;
        break;
      }
    }
    return str.substring(p1, p2+1);
  } else {  // rubne ne uklanjamo, razmake uklanjamo
    let p1 = -1;
    let p2 = len-1;
    for (let i = 0; i < len; i++) {
      let c = str[i];
      if (c !== " ") {
        p1 = i;
        break;
      }
    }
    if (p1 === -1) return "";
    
    for (let i = len-1; i > -1; i--) {
      let c = str[i];
      if (c !== " ") {
        p2 = i;
        break;
      }
    }
    let rez = "";
    for (let i = p1; i < p2+1; i++) {
      let c = str[i];
      if (c !== " ")  rez += c;
    }
    return str.substring(0,p1) + rez + str.substring(p2+1);
  }

}

export function tipProvizijeKod(provizija: "provizija" | "fiksni" | "postotak" | null) {
  if (provizija === "fiksni") {
    return 2;
  }
  return 0;
}