import React from 'react';
import ReactDOM from 'react-dom/client';
import './admin.scss';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { ADRESA1, FlashKontekst, Kontekst, StateKontekst } from "./index";
import { useFetch1 } from "./useFetch";
import { Signin, Signout, Gumb, Spinner } from "./login";
import { Loading, ukloniNull, ispisImena } from "./notFound";
import { FlashTip, Flash } from "./flash";
import { Tablica, TablicaVozila } from "./tablica";

type ConfirmProps = {
  setStanje?: React.Dispatch<React.SetStateAction<string>>;
  br?: number;
  tekst?: string;
  objasnjenje?: string;
  payload?: {[index:string]: any};
  callbackFun?: (arg:{[index:string]: any}) => void;
  path?: string
}

export function selSw(id1:string, id2:string) : boolean {
  if (id1 == id2) {
    return true;
  }
  return false;
}

function Confirm({setStanje=()=>{}, br=1, tekst="neko pitanje...", 
  objasnjenje="neko objasnjenje akcije...", payload={"id":""},
  callbackFun=()=>{}, path="/api/admin/glavna"}: ConfirmProps) {

  const [sw, setSw] = React.useState(false);
  const [akcija, setAkcija] = React.useState("");
  const [id, setId] = React.useState("");
  const {kljuc} = React.useContext(Kontekst);
  const [br1, setBr1] = React.useState(0);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": akcija,
      "id": id
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br1]);

  React.useEffect(()=>{
    console.log("loading6: " + loading);
    console.log("error6: " + error);
    console.log("value6: ");
    console.log("br: " + br1);
    console.log(value);

    if (br1 !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        postaviFlashPoruku(value.errorCode, "danger", "confirm");
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setStanje("");
        callbackFun({"pod":value.value.podaci});
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (br === 1) {
      setAkcija("delete");
      if (payload.id !== undefined)  setId(payload.id);
    } else if (br === 2) {
      setAkcija("activate");
      if (payload.id !== undefined)  setId(payload.id);
    } else if (br === 3) {
      setAkcija("new");
    }
  }, []);



  function fun(str: string) {
    if (str === "no") {
      setStanje("");
    } else if (br === 1) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 2) {
      setBr1((prev)=>{return prev+1});
    }
  }

  return (
    <div className="popup-plast-confirm">
      <div className="popup-confirm">
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="confirm" kod2={el.kod} />})}
        </div>
        {loading ? <>
          <div className="naslov">{objasnjenje}</div>
          <Spinner/>
        </> :
           <>
             <div className="naslov">{tekst}</div>
             <div className="gumbi">
               <Gumb fun={()=>{fun("no")}} tekst="No" height="30px" width="60px" />
               <Gumb fun={()=>{fun("yes")}} tekst="Yes" height="30px" width="60px" />
             </div>
           </>}
      </div>
    </div>
  )
}

export function VozaciIzbornik() {
  const [searchStr, setSearchStr] = React.useState("");
  const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
  const [activatedSw, setActivatedSw] = React.useState(true);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [action, setAction] = React.useState("");
  const [status, setStatus] = React.useState("loading");
  const [podaci, setPodaci] = React.useState<{[index:string]: any}[]>([]);
  const [podaciVozila, setPodaciVozila] = React.useState<{[index:string]: any}[]>([]);
  const [aliases, setAliases] = React.useState<{[index:string]: any}[]>([]);
  const [signoutSw, setSignoutSw] = React.useState(false);
  const [checkSw, setCheckSw] = React.useState(false);
  const [checkSw1, setCheckSw1] = React.useState(false);

  const [checkImeSw, setCheckImeSw] = React.useState(true);
  const [checkPrezimeSw, setCheckPrezimeSw] = React.useState(true);
  const [checkNickSw, setCheckNickSw] = React.useState(true);

  const [confirmSw, setConfirmSw] = React.useState("");
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {kljuc, setKljuc} = React.useContext(Kontekst);
  const {odabraniVozacIDVozaci, setOdabraniVozacIDVozaci, checkSwVozaci, setCheckSwVozaci, checkSw1Vozaci, setCheckSw1Vozaci,
         searchStrVozaci, setSearchStrVozaci, checkNickSwVozaci, setCheckNickSwVozaci, checkImeSwVozaci, setCheckImeSwVozaci,
         checkPrezimeSwVozaci, setCheckPrezimeSwVozaci} = React.useContext(StateKontekst);
  const [pomak, setPomak] = React.useState(0);

  const r = React.useRef<HTMLDivElement | null>(null);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/admin/glavna', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": action
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading5: " + loading);
    console.log("error5: " + error);
    console.log("value5: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        setStatus("sign-in");
        setPodaci([]);
        setPodaciVozila([]);
        setAliases([]);
        
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setStatus("logged-in");
        setPodaci(value.value.podaci);
        setPodaciVozila(value.value.vozila);
        setAliases(value.value.aliases);
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (action !== "") {
      setBr((prev)=>{return prev+1});
    }
  }, [action, br1]);

  React.useEffect(()=>{
    setAction("ucitaj");
  }, []);

  React.useEffect(()=>{
    console.log("pokrenuo " + br1 + " " + Math.random());
    if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
  }, [kljuc]);

  React.useEffect(()=>{
    setOdabraniVozacID("-");
    setActivatedSw(true);
    if (checkSw && checkSw1) {
      setCheckSw1(false);
      console.log("postavljam checksw1 na false " + Math.random());
    }
  },[checkSw]);

  React.useEffect(()=>{
    setOdabraniVozacID("-");
    setActivatedSw(true);
    if (checkSw && checkSw1) {
      setCheckSw(false);
      console.log("postavljam checksw na false " + Math.random());
    }
  },[checkSw1]);

  React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
    if (br === 0) {
      setOdabraniVozacID(odabraniVozacIDVozaci);
      setCheckSw(checkSwVozaci);
      setCheckSw1(checkSw1Vozaci);
      setSearchStr(searchStrVozaci);
      setCheckNickSw(checkNickSwVozaci);
      setCheckImeSw(checkImeSwVozaci);
      setCheckPrezimeSw(checkPrezimeSwVozaci);
    } else {
      setOdabraniVozacIDVozaci(odabraniVozacID);
      setCheckSwVozaci(checkSw);
      setCheckSw1Vozaci(checkSw1);
      setSearchStrVozaci(searchStr);
      setCheckNickSwVozaci(checkNickSw);
      setCheckImeSwVozaci(checkImeSw);
      setCheckPrezimeSwVozaci(checkPrezimeSw);
    }
  }, [odabraniVozacID, checkSw, checkSw1, searchStr, checkNickSw, checkImeSw, checkPrezimeSw]);

  React.useEffect(()=>{
    if (status !== "logged-in") {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setPomak(scrollTop);
      if (r.current !== null)  r.current.style.position = "fixed";
      if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
    } else {
      if (r.current !== null)  r.current.style.top = "0px";
      if (r.current !== null)  r.current.style.position = "relative";
      window.scrollTo(0, pomak);
    }
  }, [status]);

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    let str1 = str.toLocaleLowerCase();
    setSearchStr(str1);
    
    let sw = false;
    let sw1 = true;
    for (let i = 0; i < podaci.length; i++) {
      let el = podaci[i];
      
      if (ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw).toLowerCase().includes(str1)) {
        if (el.id.toString() === odabraniVozacID) {
          if (el.activated === "NO")  sw1 = false;
          sw = true;
          break;
        }
      }
    }
    if (!sw) {
      setOdabraniVozacID("-");
      setActivatedSw(true);
    } else {
      if (sw1) {
        setActivatedSw(true);
      } else {
        setActivatedSw(false);
      }
    }
  }

  function odabirFun(e: React.MouseEvent<HTMLOptionElement> | React.ChangeEvent<HTMLSelectElement>) {
    console.log("Kliknuo si na " + e.currentTarget.value);
    setOdabraniVozacID(e.currentTarget.value);

    let sw1 = true;
    for (let i = 0; i < podaci.length; i++) {
      let el = podaci[i];      
      if (el.id.toString() === e.currentTarget.value) {
        if (el.activated === "NO") {
          sw1 = false;    
          break;
        }
      }
      
    }
      if (sw1) {
        setActivatedSw(true);
      } else {
        setActivatedSw(false);
      }
    
      
  }

  function refreshajListu(arg:{[index:string]: any}) {
    console.log("podaci za refresh: ");
    console.log(arg.pod);
    setPodaci(arg.pod);
    setOdabraniVozacID("-");
    setActivatedSw(true);
  }

  function setCheck(br: number) {
    if (br === 1) {
      if (checkNickSw) {
        if (provjeriChecks())  setCheckNickSw(false);
      } else {
        setCheckNickSw(true);
      }
    } else if (br === 2) {
      if (checkImeSw) {
        if (provjeriChecks())  setCheckImeSw(false);
      } else {
        setCheckImeSw(true);
      }
    } else {
      if (checkPrezimeSw) {
        if (provjeriChecks())  setCheckPrezimeSw(false);
      } else {
        setCheckPrezimeSw(true);
      }
    }

    function provjeriChecks() {
      let br = 0;
      if (checkNickSw) br += 1;
      if (checkImeSw) br += 1;
      if (checkPrezimeSw) br += 1;
      if (br === 1) return false;
      return true;
    }

  }

  return (
    
    <div className="holder" ref={r}>
    {status === "loading" ? <Loading sw={true}/> : <>
    <div className="vozaciIzbornik">
      {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
      <Navbar current={0} status={status} signout={()=>{setSignoutSw(true)}}/> 
      {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
      <div className="vozaci-izbornik-lijeva">
        <div className="el">
          <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
            <div className="input-div">
              <div className="input-div1"><label htmlFor="str">Upisi ime:</label></div>
              <input onChange={inputFun} value={searchStr} className="input-el" type="text"
                  id="str" name="str" placeholder="upisi substring"/>
            </div> 

            <div>
              <input type="checkbox" checked={checkSw} onChange={()=>{setCheckSw((prev)=>{return !prev})}} 
                 className="checkbox" id="checkbox"/>
              <label className="checkbox-label" htmlFor="checkbox">Samo neaktivni korisnici</label>
            </div>
            <div>
              <input type="checkbox" checked={checkSw1} onChange={()=>{setCheckSw1((prev)=>{return !prev})}} 
                 className="checkbox" id="checkbox1"/>
              <label className="checkbox-label" htmlFor="checkbox1">Samo aktivni korisnici</label>
            </div>
          </form>
        </div>
        <div className="el1">
          <div className="checkbox-opcije">
            <div className="checkbox-div">
              <input type="checkbox" checked={checkNickSw} onChange={()=>{setCheck(1)}} 
                 className="checkbox-el" id="checkbox-nick"/>
              <label className="checkbox-label-el" htmlFor="checkbox-Nick">Username</label>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" checked={checkImeSw} onChange={()=>{setCheck(2)}}
                 className="checkbox-el" id="checkbox-ime"/>
              <label className="checkbox-label-el" htmlFor="checkbox-ime">Ime</label>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" checked={checkPrezimeSw} onChange={()=>{setCheck(3)}}
                 className="checkbox-el" id="checkbox-prezime"/>
              <label className="checkbox-label-el" htmlFor="checkbox-prezime">Prezime</label>
            </div>
          </div>
          <select className="izbornik" size={5} value={odabraniVozacID} onClick={(e)=>{e.stopPropagation()}} 
             onChange={odabirFun} >
            <option value="-">bez odabira</option>
            {podaci.filter((el)=>{let ii = ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw); return ii !== "" && ii.toLowerCase().includes(searchStr)})
              .filter((el)=>{if (checkSw && (el.activated == "NO" || el.phone_verified == "NO")) return true; 
                          if (checkSw1 && el.activated == "YES" && el.phone_verified == "YES") return true;
                          if (!checkSw && !checkSw1) return true;
                          return false})
              .sort((el1,el2)=>{let is1 = ispisImena(el1.name,el1.ime,el1.prezime,checkNickSw,checkImeSw,checkPrezimeSw); let is2 = ispisImena(el2.name,el2.ime,el2.prezime,checkNickSw,checkImeSw,checkPrezimeSw);
                    if (is1 < is2) return -1; if (is1 > is2) return 1; return 0;})
              .map((el)=>{return <option onClick={odabirFun} value={el.id} key={el.id}>{ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw)}</option>})}
          </select>
        </div>
        <div className="el2">
          {!activatedSw ? <div onClick={()=>{setConfirmSw("activate")}} className="div-button">Activate</div> : null}
          {odabraniVozacID !== "-" ? <div onClick={()=>{setConfirmSw("erase")}} className="div-button">Erase</div> : null}
        </div>
      </div> 
      {confirmSw === "erase" ? <Confirm callbackFun={refreshajListu} br={1} setStanje={setConfirmSw} tekst="Upravo cete obrisati vozaca. Da li ste sigurni?" 
       payload={{"id": odabraniVozacID}} objasnjenje="BRISEM vozaca. Pricekajte..."/> : null}
      {confirmSw === "activate" ? <Confirm callbackFun={refreshajListu} br={2} setStanje={setConfirmSw} 
      tekst="Upravo cete aktivirati vozaca. Da li ste sigurni?" 
      payload={{"id": odabraniVozacID}} objasnjenje="AKTIVIRAM vozaca. Pricekajte..."/> : null}

      <div className="vozaci-izbornik-desna">
        <div className="buffer"></div>
        <Tablica odabraniVozacID={odabraniVozacID}  podaci={podaci} setPodaci={setPodaci} 
             podaciVozila={podaciVozila} setPodaciVozila={setPodaciVozila} aliases={aliases}
             setAliases={setAliases} prekidaci={[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true]}/>
      </div>   
    </div>
    <Footer />
    </>}
    </div> 
  )
}

export function VozilaIzbornik() {
  const [searchStr, setSearchStr] = React.useState("");
  const [odabranoVoziloID, setOdabranoVoziloID] = React.useState("-");
  const [action, setAction] = React.useState("");
  const [novoVoziloSw, setNovoVoziloSw] = React.useState(false);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [status, setStatus] = React.useState("loading");
  const [podaci, setPodaci] = React.useState<{[index:string]: any}[]>([]);
  const [signoutSw, setSignoutSw] = React.useState(false);
  const {kljuc, setKljuc} = React.useContext(Kontekst);
  const [confirmSw, setConfirmSw] = React.useState("");
  const {postaviFlashPoruku} = React.useContext(FlashKontekst);
  const [pomak, setPomak] = React.useState(0);
  const {odabranoVoziloIDVozila, setOdabranoVoziloIDVozila, searchStrVozila, setSearchStrVozila} = React.useContext(StateKontekst);

  const r = React.useRef<HTMLDivElement | null>(null);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/admin/vozila', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": action
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading7: " + loading);
    console.log("error7: " + error);
    console.log("value7: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        setStatus("sign-in");
        
        
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setStatus("logged-in");
        setPodaci(value.value.podaci);
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (action !== "") {
      setBr((prev)=>{return prev+1});
    }
  }, [action, br1]);

  React.useEffect(()=>{
    setAction("ucitaj");
  }, []);

  React.useEffect(()=>{
    if (status !== "logged-in") {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setPomak(scrollTop);
      if (r.current !== null)  r.current.style.position = "fixed";
      if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
    } else {
      if (r.current !== null)  r.current.style.top = "0px";
      if (r.current !== null)  r.current.style.position = "relative";
      window.scrollTo(0, pomak);
    }
  }, [status]);

  React.useEffect(()=>{
    console.log("pokrenuo " + br1 + " " + Math.random());
    if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
  }, [kljuc]);

  React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
    if (br === 0) {
      setOdabranoVoziloID(odabranoVoziloIDVozila);
      setSearchStr(searchStrVozila);
    } else {
      setOdabranoVoziloIDVozila(odabranoVoziloID);
      setSearchStrVozila(searchStr);
    }
  }, [odabranoVoziloID, searchStr]);

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    str = str.toLocaleLowerCase();
    setSearchStr(str);
    if (novoVoziloSw) {
      setNovoVoziloSw(false);
      postaviFlashPoruku("Iskljucen 'Add' mod.", "success", "tablica-vozila");
    }
  }

  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
    setOdabranoVoziloID(e.currentTarget.value);
    if (novoVoziloSw) {
      setNovoVoziloSw(false);
      postaviFlashPoruku("Iskljucen 'Add' mod.", "success", "tablica-vozila");
    }
  }

  function refreshajListu(arg:{[index:string]: any}) {
    console.log("podaci za refresh: ");
    console.log(arg.pod);
    setPodaci(arg.pod);
    setOdabranoVoziloID("-");
  }

  function novoVoziloFun() {
    setOdabranoVoziloID("-");
    setNovoVoziloSw(true);
    postaviFlashPoruku("Ukljucen 'Add' mod. Unesi podatke o novom vozilu u tablicu", "success", "tablica-vozila");
  }

  return (
    <div className="holder" ref={r}>
    {status === "loading" ? <Loading sw={true}/> : <>
    <div className="vozilaIzbornik">
      {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
      <Navbar current={2} status={status} signout={()=>{setSignoutSw(true)}}/> 
      {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
      <div className="vozila-izbornik-lijeva">
        <div className="el">
          <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
            <div className="input-div">
              <div className="input-div1"><label htmlFor="str">Upisi search substring:</label></div>
              <input onChange={inputFun} value={searchStr} className="input-el" type="text" id="str" name="str" placeholder="upisi substring"/>
            </div> 
          </form>
        </div>
        <div className="el1">
          <select className="izbornik" size={5} value={odabranoVoziloID} onClick={(e)=>{e.stopPropagation()}} 
             onChange={odabirFun} >
            <option value="-">bez odabira</option>
            {podaci.filter((el)=>{return (el.registracija + " " + ukloniNull(el.proizvodac) + " " + ukloniNull(el.model)).toLowerCase().includes(searchStr.toLowerCase())})
             .map((el)=>{return <option selected={selSw(el.id, odabranoVoziloID)} value={el.id} key={el.id}><span className="span-el">{el.registracija}</span>{" " + ukloniNull(el.proizvodac) + " " + ukloniNull(el.model)}</option>})}
          </select>
        </div>

        <div className="el2">
          <div onClick={novoVoziloFun} className="div-button">New</div>
          {odabranoVoziloID !== "-" ? <div onClick={()=>{setConfirmSw("erase")}} className="div-button">Erase</div> : null}
        </div>
      </div>
      {confirmSw === "erase" ? <Confirm callbackFun={refreshajListu} br={1} setStanje={setConfirmSw} tekst="Upravo cete obrisati vozilo. Da li ste sigurni?" 
       payload={{"id": odabranoVoziloID}} path="/api/admin/vozila" objasnjenje="BRISEM vozilo. Pricekajte..."/> : null}
      {confirmSw === "activate" ? <Confirm callbackFun={refreshajListu} br={2} setStanje={setConfirmSw} 
      tekst="Upravo cete aktivirati vozaca. Da li ste sigurni?" 
      payload={{"id": odabranoVoziloID}} path="/api/admin/vozila" objasnjenje="AKTIVIRAM vozaca. Pricekajte..."/> : null}

      <div className="vozila-izbornik-desna">
        <div className="buffer"></div>
        <TablicaVozila odabranoVoziloID={odabranoVoziloID} setOdabranoVoziloID={setOdabranoVoziloID} podaci={podaci} setPodaci={setPodaci} 
          novoVoziloSw={novoVoziloSw} setNovoVoziloSw={setNovoVoziloSw} path="/api/admin/vozila"/>
      </div>    
    </div>
    <Footer />
    </>}
    </div>
  )
}