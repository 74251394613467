import React from "react";
import ReactDOM from 'react-dom/client';
import "./navbar.scss";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate
} from "react-router-dom";
import { ADRESA, Kontekst} from "./index";

export function Navbar({current=0, status="not logged in", signout=()=>{}, vozacSw=false}) {
    const [expand, setExpand] = React.useState(false);
    const {lang, setLang} = React.useContext(Kontekst);
    
    const r = React.useRef<HTMLDivElement>(null);
  
    const r1 = React.useRef<HTMLAnchorElement>(null);
    const r2 = React.useRef<HTMLAnchorElement>(null);
    const r3 = React.useRef<HTMLAnchorElement>(null);
    const r31 = React.useRef<HTMLAnchorElement>(null);
    const r32 = React.useRef<HTMLAnchorElement>(null);
    const r6 = React.useRef<HTMLDivElement>(null);
    const r1a = React.useRef<HTMLAnchorElement>(null);
    const r2a = React.useRef<HTMLAnchorElement>(null);
    const r3a = React.useRef<HTMLAnchorElement>(null);
    const r3a1 = React.useRef<HTMLAnchorElement>(null);
    const r3a2 = React.useRef<HTMLAnchorElement>(null);
    const r6a = React.useRef<HTMLDivElement>(null);
    const r11 = React.useRef<HTMLAnchorElement>(null);
    const r11a = React.useRef<HTMLAnchorElement>(null);
    const r7 = React.useRef<HTMLAnchorElement>(null);
    const r7a = React.useRef<HTMLAnchorElement>(null);
    const r8 = React.useRef<HTMLAnchorElement>(null);
    const r8a = React.useRef<HTMLAnchorElement>(null);
    const r9 = React.useRef<HTMLAnchorElement>(null);
    const r9a = React.useRef<HTMLAnchorElement>(null);

    const r4a = React.useRef<HTMLDivElement>(null);
    const r4b = React.useRef<HTMLDivElement>(null);

    const r5a = React.useRef<HTMLImageElement>(null);
    const r5b = React.useRef<HTMLImageElement>(null);
  
    React.useEffect(()=>{
      if (r1.current) r1.current.style.color = "white";
      if (r1a.current) r1a.current.style.color = "white";
      if (r11.current) r11.current.style.color = "white";
      if (r11a.current) r11a.current.style.color = "white";
      if (r2.current) r2.current.style.color = "white";
      if (r2a.current) r2a.current.style.color = "white";
      if (r3.current) r3.current.style.color = "white";
      if (r3a.current) r3a.current.style.color = "white";
      if (r31.current) r31.current.style.color = "white";
      if (r3a1.current) r3a1.current.style.color = "white";
      if (r32.current) r32.current.style.color = "white";
      if (r3a2.current) r3a2.current.style.color = "white";
      if (r6.current) r6.current.style.color = "white";
      if (r6a.current) r6a.current.style.color = "white";
      if (r7.current) r7.current.style.color = "white";
      if (r7a.current) r7a.current.style.color = "white";
      if (r8.current) r8.current.style.color = "white";
      if (r8a.current) r8a.current.style.color = "white";
      if (r9.current) r9.current.style.color = "white";
      if (r9a.current) r9a.current.style.color = "white";

      if (current === 0) {
        if (r8.current) r8.current.style.color = "#2070fa";
        if (r8a.current) r8a.current.style.color = "#2070fa";
      } else if (current === 1) {
        if (r1.current) r1.current.style.color = "#2070fa";
        if (r1a.current) r1a.current.style.color = "#2070fa";
      } else if (current === 2) {
        if (r2.current) r2.current.style.color = "#2070fa";
        if (r2a.current) r2a.current.style.color = "#2070fa";
      } else if (current === 3) {
        if (r3.current) r3.current.style.color = "#2070fa";
        if (r3a.current) r3a.current.style.color = "#2070fa";
      } else if (current === 4) {
        if (r31.current) r31.current.style.color = "#2070fa";
        if (r3a1.current) r3a1.current.style.color = "#2070fa";
      } else if (current === 5) {
        if (r11.current) r11.current.style.color = "#2070fa";
        if (r11a.current) r11a.current.style.color = "#2070fa";
      } else if (current === 6) {
        if (r32.current) r32.current.style.color = "#2070fa";
        if (r3a2.current) r3a2.current.style.color = "#2070fa";
      } else if (current === 7) {
        if (r7.current) r7.current.style.color = "#2070fa";
        if (r7a.current) r7a.current.style.color = "#2070fa";
      } else if (current === 8) {
        if (r9.current) r9.current.style.color = "#2070fa";
        if (r9a.current) r9a.current.style.color = "#2070fa";
      }
    }, [current]);

    React.useEffect(()=>{
      if (lang === "hr") {
        if (r5a.current) r5a.current.classList.add("odabrana");
        if (r5b.current) r5b.current.classList.remove("odabrana");
      } else {
        if (r5a.current) r5a.current.classList.remove("odabrana");
        if (r5b.current) r5b.current.classList.add("odabrana");
      }
    }, [lang]);
  
    React.useEffect(()=>{
      console.log(expand);
      if (expand) {
        if (r.current) {
          if (status === "logged-in") {
            if (current === 1 || current === 5) {
              r.current.style.height = "240px";
            } else {
              r.current.style.height = "385px";  /* 275px */
            }
          } else {
            if (current === 1 || current === 5) {
              r.current.style.height = "215px";
            } else {
              r.current.style.height = "360px";  /* 250px */
            }
          }
        }
      } else {
        if (r.current) r.current.style.height = "150px";
      }
    }, [expand]);

    function hover(str:string) {
      if (str === "hr") {
        if (r4a.current) r4a.current.style.display = "flex";
      } else {
        if (r4b.current) r4b.current.style.display = "flex";
      }
    }

    function unhover(str:string) {
      if (str === "hr") {
        if (r4a.current) r4a.current.style.display = "none";
      } else {
        if (r4b.current) r4b.current.style.display = "none";
      }
    }
  
    return (
      <nav ref={r} className="navbar">
        <img src="/slike/logoic.png" className="logo" alt="logo slika" />
        <div className="zastavice">
         <img ref={r5a} src="/slike/croatia.png" className="zastava croatia" alt="hrvatska zastava za hrvatski"
            onClick={()=>{setLang("hr")}} onMouseEnter={()=>{hover("hr")}} onMouseLeave={()=>{unhover("hr")}}/>
         <img ref={r5b} src="/slike/britain.png" className="zastava britain" alt="britanska zastava za engleski"
            onClick={()=>{setLang("en")}} onMouseEnter={()=>{hover("en")}} onMouseLeave={()=>{unhover("en")}}/>
         <div ref={r4a} className="natpis hrvatski">Hrvatski</div>
         <div ref={r4b} className="natpis engleski">English</div>
        </div>
        {!expand ?
        <div className="gumb" onClick={()=>{setExpand((prev)=>{return !prev})}}>
          <div className="crtica"></div>
          <div className="crtica"></div>
          <div className="crtica"></div>
        </div> : 
        <div className="krizic" onClick={()=>{setExpand((prev)=>{return !prev})}}>
          <div className="el1"></div>
          <div className="el2"></div>
        </div>
        }
        {vozacSw ? <>
          <div className="link-traka">
            <Link ref={r1} className="link" to={ADRESA+"/"}>{lang === "hr" ? "Podaci" : "Information"}</Link>
            <Link ref={r11} className="link" to={ADRESA+"/obracun"}>{lang === "hr" ? "Obracun" : "Payoff"}</Link>
            {status === "logged-in" ? <div onClick={signout} ref={r6} className="link link-div">{lang === "hr" ? "Odjava" : "Sign out"}</div> : null}
          </div>
          <div className="link-traka1">
            <Link ref={r1a} className="link" to={ADRESA+"/"}>{lang === "hr" ? "Podaci" : "Information"}</Link>
            <Link ref={r11a} className="link" to={ADRESA+"/obracun"}>{lang === "hr" ? "Obracun" : "Payoff"}</Link>
            {status === "logged-in" ? <div onClick={signout} ref={r6a} className="link link-div">{lang === "hr" ? "Odjava" : "Sign out"}</div> : null}
          </div>
        </> : 
        <>
          <div className="link-traka">
            <Link ref={r8} className="link" to={ADRESA+"/admin/vozaci"}>{lang === "hr" ? "Vozaci" : "Drivers"}</Link>
            <Link ref={r2} className="link" to={ADRESA+"/admin/vozila"}>{lang === "hr" ? "Vozila" : "Vehicles"}</Link>
            <Link ref={r3} className="link" to={ADRESA+"/admin/doprinosi"}>{lang === "hr" ? "Doprinosi" : "Fees"}</Link>
            <Link ref={r31} className="link" to={ADRESA+"/admin/prometi"}>{lang === "hr" ? "Prometi" : "Traffics"}</Link>
            <Link ref={r32} className="link" to={ADRESA+"/admin/obracun"}>{lang === "hr" ? "Obracun" : "Payoff"}</Link>
            <Link ref={r7} className="link" to={ADRESA+"/admin/transakcije"}>{lang === "hr" ? "Transakcije" : "Transactions"}</Link>
            <Link ref={r9} className="link" to={ADRESA+"/admin/postavke"}>{lang === "hr" ? "Postavke" : "Settings"}</Link>
            {status === "logged-in" ? <div onClick={signout} ref={r6} className="link link-div">{lang === "hr" ? "Odjava" : "Sign out"}</div> : null}
          </div>
          <div className="link-traka1">
            <Link ref={r8a} className="link" to={ADRESA+"/admin/vozaci"}>{lang === "hr" ? "Vozaci" : "Drivers"}</Link>
            <Link ref={r2a} className="link" to={ADRESA+"/admin/vozila"}>{lang === "hr" ? "Vozila" : "Vehicles"}</Link>
            <Link ref={r3a} className="link" to={ADRESA+"/admin/doprinosi"}>{lang === "hr" ? "Doprinosi" : "Fees"}</Link>
            <Link ref={r3a1} className="link" to={ADRESA+"/admin/prometi"}>{lang === "hr" ? "Prometi" : "Traffics"}</Link>
            <Link ref={r3a2} className="link" to={ADRESA+"/admin/obracun"}>{lang === "hr" ? "Obracun" : "Payoff"}</Link>
            <Link ref={r7a} className="link" to={ADRESA+"/admin/transakcije"}>{lang === "hr" ? "Transakcije" : "Transactions"}</Link>
            <Link ref={r9a} className="link" to={ADRESA+"/admin/postavke"}>{lang === "hr" ? "Postavke" : "Settings"}</Link>
            {status === "logged-in" ? <div onClick={signout} ref={r6a} className="link link-div">{lang === "hr" ? "Odjava" : "Sign out"}</div> : null}
          </div>
        </>}
      </nav>
    )
  }