import React from "react";
import ReactDOM from 'react-dom/client';
import "./tablica.scss";
import { FlashTip, Flash } from "./flash";
import { ADRESA, ADRESA1, FlashKontekst, Kontekst } from "./index";
import { Spinner } from "./login";
import { PodaciTip, useFetch1 } from "./useFetch";
import { ukloniPrazneZnakove, ukloniPrazneZnakoveNull, unosBooleana, booleanToString, emptyIsNull, ukloniNull, 
        ConfirmChoice, novoPolje, nullToDoDaljnjeg, ispis, ispisId, filterStr, tipProvizijeKod } from "./notFound";
import { Kalendar, brDanaOd2000, brDanaOdPocetkaGodine, otvoriKalendar } from "./kalendar";
import { selSw } from "./admin"; 
import { AliasManager } from "./alias";
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";


type TablicaProps = {
    clickSw?: boolean;
    showSw?: boolean[];
    prekidaci?: boolean[]; // 16 ili 17 prekidaca
    odabraniVozacID?: string;
    podaci?: {[index:string]: any}[];
    setPodaci?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
    podaciVozila?: {[index:string]: any}[];
    setPodaciVozila?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
    aliases?: {[index:string]: any}[];
    setAliases?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
    path?: string;
    adminSw?: boolean;
}

type PodaciVoziloTip = {
  id_veze: number;
  registracija: string;
  proizvodac: string | null;
  model: string | null;
  pocetak: string;
  kraj: string | null;
  tjedna_cijena: number | null;
}

type IntervalTip1 = {
  id_veze:number, 
  ime:string, 
  prezime: string, 
  pocetak: string, 
  kraj: string | null
}

type PodaciVozilaTip = {
  id: number;
  registracija: string;
  proizvodac: string | null;
  model: string | null;
  tjednaCijena: number | null;
  intervali: IntervalTip1[];
}

type PridruziVoziloPopUpProps = {
  odabraniVozacID?: string;
  dodjeljenaVozila?: PodaciVoziloTip[];
  vozilaDodjele?: PodaciVozilaTip[];
  akcija?: "dodjeli_vozilo" | "modificiraj_dodjelu";
  odabraniID?: string;
  path?: string;
  setPodaci?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
  setPodaciVozila?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
}

type IntervalTip = {
  idVeze: number;
  pocetak: number;
  kraj: number | null;
}

function PridruziVoziloPopUp({odabraniVozacID="-", dodjeljenaVozila=[], akcija="dodjeli_vozilo", 
        vozilaDodjele=[], path="/api/admin/vozila1", odabraniID="-", setPodaci=()=>{},
        setPodaciVozila=()=>{}}: PridruziVoziloPopUpProps) {
  const [datumDodjele, setDatumDodjele] = React.useState<null | string>(null);
  const [datumPovrata, setDatumPovrata] = React.useState<null | string>(null);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [stanje, setStanje] = React.useState(0);
  const [intervali, setIntervali] = React.useState<IntervalTip[]>([]);
  const [intervaliVozila, setIntervaliVozila] = React.useState<IntervalTip[]>([]);
  const [dateStr, setDateStr] = React.useState<string | null>(null);

  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {kljuc, setKljuc} = React.useContext(Kontekst);

  const danasnjiDan = (new Date()).getDay() + 1;
  const danasnjiMjesec = (new Date()).getMonth() + 1;
  const danasnjaGodina = (new Date()).getFullYear();

  const [searchStr, setSearchStr] = React.useState("");
  const [odabranoVoziloID, setOdabranoVoziloID] = React.useState("-");
  const [tjednaCijena, setTjednaCijena] = React.useState<number | null>(null);
  const [stopPropSw, setStopPropSw] = React.useState(true);

  const r = React.useRef<HTMLDivElement>(null);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "id": odabraniID, 
        "id_vozila": odabranoVoziloID,
        "id_vozaca": odabraniVozacID,
        "pocetak": datumDodjele,
        "kraj": datumPovrata,
        "tjedna_cijena": tjednaCijena
      }),
      headers: {
        'Content-type': 'application/json'
      }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading7: " + loading);
    console.log("error7: " + error);
    console.log("value7: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
      } else if (!loading && !value.error){
        
        setPodaci(value.value.podaci);
        setPodaciVozila(value.value.vozila);
        zatvoriPopup();
        if (akcija === "dodjeli_vozilo") {
          postaviFlashPoruku("Upravo si dodao dodjelu vozila vozacu", "success", "tablica");
        } else {
          postaviFlashPoruku("Upravo si modificirao dodjelu vozila vozacu", "success", "tablica");
        }
        
      }
    }
  }, [loading]);
  
  React.useEffect(()=>{
    console.log("dateStr " + Math.random());
    console.log(dateStr);
  
    if (stanje === 0) {
      if (dateStr !== null) {
        if (odabranoVoziloID === "-"  &&  akcija === "dodjeli_vozilo") {
          postaviFlashPoruku("Prije odabira datuma morate prvo odabrati vozilo", "danger", "dodjela-vozila");
          setDateStr(null);
        } else {
          if (akcija === "dodjeli_vozilo") {
            if (intervaliVozila.find((el)=>{if (el.kraj === null) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novu dodjelu jer je vozilo vec dodjeljeno", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }
            if (intervali.find((el)=>{if (el.kraj === null) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novu dodjelu vozacu koji ima trenutno dodjeljeno vozilo", "danger", "dodjela-vozila");
              setDateStr(null);
              return
            }
            let p = dateStr.split(".");
            let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));

            if (intervali.find((el)=>{if (el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj)) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, neko vozilo je vec dodjeljeno vozacu taj dan", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }

            if (intervaliVozila.find((el)=>{if (el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj)) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, ovo vozilo je vec dodjeljeno nekom vozacu na taj dan", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }
            setDatumDodjele(dateStr);
          } else {
            
            if (intervaliVozila.find((el)=>{if (el.kraj === null  &&  el.idVeze.toString() !== odabraniID) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novu dodjelu jer je vozilo vec dodjeljeno", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }
            
            if (intervali.find((el)=>{if (el.kraj === null  &&  el.idVeze.toString() !== odabraniID) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novu dodjelu vozacu koji ima trenutno dodjeljeno vozilo", "danger", "dodjela-vozila");
              setDateStr(datumDodjele);
              return
            }
            let p = dateStr.split(".");
            let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));
            
            if (intervali.find((el)=>{if ((el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj))  &&  el.idVeze.toString() !== odabraniID) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, neko vozilo je vec dodjeljeno vozacu taj dan", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }

            if (intervaliVozila.find((el)=>{if ((el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj))  &&  el.idVeze.toString() !== odabraniID) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, ovo vozilo je vec dodjeljeno nekom vozacu na taj dan", "danger", "dodjela-vozila");
              setDateStr(null);
              return;
            }
            setDatumDodjele(dateStr);
          }
        }
      }
    } else if (stanje === 1) {
      if (dateStr !== null && datumDodjele !== null) {
        let p = dateStr.split(".");
        let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));

        let p1 = datumDodjele.split(".");
        let brojD1 = brDanaOd2000(parseInt(p1[2])) + brDanaOdPocetkaGodine(parseInt(p1[2]),parseInt(p1[1]),parseInt(p1[0]));

        if (brojD1 >= brojD) {
          postaviFlashPoruku("Datum odjave ne moze biti jednak ili manji od datuma dodjele", "danger", "dodjela-vozila");
          setDateStr(null);
          return;
        }

        if (akcija === "dodjeli_vozilo"  &&  intervali.find((el)=>{if (el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak)) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom vozaca", "danger", "dodjela-vozila");
          setDateStr(null);
          return;
        }
        if (akcija === "modificiraj_dodjelu"  &&  intervali.find((el)=>{if ((el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak))  &&  el.idVeze.toString() !== odabraniID) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom doprinosa", "danger", "dodjela-vozila");
          setDateStr(null);
          return;
        } 

        if (akcija === "dodjeli_vozilo"  &&  intervaliVozila.find((el)=>{if (el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak)) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom ovog vozila", "danger", "dodjela-vozila");
          setDateStr(null);
          return;
        }
        if (akcija === "modificiraj_dodjelu"  &&  intervaliVozila.find((el)=>{if ((el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak))  &&  el.idVeze.toString() !== odabraniID) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom ovog vozila", "danger", "dodjela-vozila");
          setDateStr(null);
          return;
        }
        console.log("postavljam datum povrata na " + Math.random());
        console.log(dateStr);
        setDatumPovrata(dateStr);
      }
    }
  
  }, [dateStr]);

  React.useEffect(()=>{
    let rez : IntervalTip[] = [];
    for (let i = 0; i < dodjeljenaVozila.length; i++) {
      let pocetak = dodjeljenaVozila[i].pocetak;
      let kraj = dodjeljenaVozila[i].kraj;
      let idVeze = dodjeljenaVozila[i].id_veze;
      let p = pocetak.split("-");
      let br1 = brDanaOd2000(parseInt(p[0])) + brDanaOdPocetkaGodine(parseInt(p[0]),parseInt(p[1]),parseInt(p[2]));
      let br2 = null;
      if (kraj !== null) {
        p = kraj.split("-");
        br2 = brDanaOd2000(parseInt(p[0])) + brDanaOdPocetkaGodine(parseInt(p[0]),parseInt(p[1]),parseInt(p[2]));
      }
      rez.push({"idVeze": idVeze, "pocetak": br1, "kraj": br2});
    }
    setIntervali(rez);

    if (akcija === "dodjeli_vozilo") {
      if (dodjeljenaVozila.length === 0) {
        setStanje(0);
        setDatumDodjele(null);
        setDatumPovrata(null);
        if (odabraniVozacID === "-") {
          postaviFlashPoruku("Vozac nije odabran. Zatvorite prozor, odaberite vozaca, i onda pokusajte ponovo", "danger", "dodjela-vozila");
        } else {
          postaviFlashPoruku("Odaberite datum za dodjelu vozila vozacu.", "success", "dodjela-vozila");
        }
      }
    } else {
      let el = dodjeljenaVozila.find((el)=>{return el.id_veze.toString() === odabraniID});
      console.log("odabrani doprinos ID ");
      console.log(odabraniID);
      if (el !== undefined) {
        let elp = el.pocetak.split("-");
        setDatumDodjele(elp[2] + "." + elp[1] + "." + elp[0]);
        if (el.kraj === null) {
          setDatumPovrata(null);
        } else {
          elp = el.kraj.split("-");
          setDatumPovrata(elp[2] + "." + elp[1] + "." + elp[0]);
        }
        setStanje(1);
        postaviFlashPoruku("Ovdje modificirate dodjelu vozila. Odaberite datum za predaju vozila.", "success", "dodjela-vozila");
      } else {
        postaviFlashPoruku("Dogodila se GRESKA(1).", "danger", "dodjela-vozila");
      }
    }

    console.log('raspolozivi podaci');
    console.log(dodjeljenaVozila);
    console.log(vozilaDodjele);
  },[]);

  React.useEffect(()=>{
    if (odabranoVoziloID === "-") {
      setIntervaliVozila([]);
      setTjednaCijena(null);
    } else {
      let p = vozilaDodjele.find((el)=>{if ((el.id).toString() === odabranoVoziloID) return true; return false});
      if (p === undefined) {
        // ova grana ne bi trebala biti nikada ispunjena
        setIntervaliVozila([]);
        setTjednaCijena(null);
      } else {
        let polj: IntervalTip[] = [];
        let pp = p.intervali.sort((el1, el2)=>{if (el1.pocetak < el2.pocetak) return -1; if (el1.pocetak === el2.pocetak) return 0; return 1});
        for (let i = 0; i < pp.length; i++) {
          let el = pp[i];
          let poc = (el.pocetak).split("-");
          let br1 = brDanaOd2000(parseInt(poc[0])) + brDanaOdPocetkaGodine(parseInt(poc[0]),parseInt(poc[1]),parseInt(poc[2]));

          let br2 = null;
          if (el.kraj !== null) {
            let kra = (el.kraj).split("-");
            br2 = brDanaOd2000(parseInt(kra[0])) + brDanaOdPocetkaGodine(parseInt(kra[0]),parseInt(kra[1]),parseInt(kra[2]));
          }
          let idVeze = el.id_veze;
          polj.push({idVeze: idVeze, pocetak: br1, kraj: br2});
        }
        setIntervaliVozila(polj);
        setTjednaCijena(p.tjednaCijena);
      }
    }
  }, [odabranoVoziloID]);

  React.useEffect(()=>{
    let p = dodjeljenaVozila.find((el)=>{if ((el.id_veze).toString() === odabraniID) return true; return false});
    if (p === undefined) {
      setTjednaCijena(null);
    } else {
      setTjednaCijena(p.tjedna_cijena);
    }
  }, [odabraniID])

  React.useEffect(()=>{
    console.log("intervali su ");
    console.log(intervali);
  }, [intervali]);

  React.useEffect(()=>{
    console.log("intervali VOZILA su ");
    console.log(intervaliVozila);
  }, [intervaliVozila]);

  React.useEffect(()=>{
    if (!stopPropSw) {
      if (r.current !== null) {
        r.current.click();
      }
    }
  }, [stopPropSw]);

  React.useEffect(()=>{
    if (br1 !== 0) {
      setBr((prev)=>{return prev+1});
    }
  }, [br1])
  
  function zatvoriPopup() {
    setStopPropSw(false);
    
    //setSwPolje((prev)=>{let prev1 = [...prev].map((el,ind)=>{if (ind === 15) return 0; return el;}); return prev1;})
  }

  function unesiInt(e: React.ChangeEvent<HTMLInputElement>, setInt: React.Dispatch<React.SetStateAction<number | null>>, 
    maxInt: number = 16777215, filterFun: (str:string)=>boolean = ()=>{return true}) {
     let str = e.currentTarget.value;
     if (!filterFun(str)) return;
     if (str === "") {
       setInt(null);
     } else {
       let br = parseInt(str);
       if (br < maxInt) {
         setInt(br);
       } else {
         postaviFlashPoruku("Za ovaj field maksimalna vrijednost je " + (maxInt-1), "danger", "dodjela-vozila");
       }
     }
   }

  function gumbKlik(e:React.MouseEvent<HTMLDivElement>) {
    let el = e.currentTarget;
    el.classList.add("klik-1");
    setTimeout(()=>{el.classList.remove("klik-1")}, 150);

    if (el.id === "gumb-ponisti") {
      setDatumDodjele(null);
      setDateStr(null);
      setOdabranoVoziloID("-");
      postaviFlashPoruku("Upravo ste se vratili na pocetak", "success", "dodjela-vozila");
    } else if (el.id === "gumb-sljedece") {
      setDateStr(null);
      setStanje(1);
      postaviFlashPoruku("Unesite datum odjave vozila ili kliknite na gumb 'Do daljnjeg'", "success", "dodjela-vozila");
    } else if (el.id === "gumb-natrag") {
      setDateStr(datumDodjele);
      setDatumPovrata(null);
      setStanje(0);
      postaviFlashPoruku("Vratili ste se na datum dodjele.", "success", "dodjela-vozila");
    } else if (el.id === "gumb-daljnjeg") {
      setDatumPovrata(null);
      
      if (datumDodjele !== null) {
        let p1 = datumDodjele.split(".");
        let brojD1 = brDanaOd2000(parseInt(p1[2])) + brDanaOdPocetkaGodine(parseInt(p1[2]),parseInt(p1[1]),parseInt(p1[0]));
      
        if (intervali.find((el)=>{if (el.kraj === null || brojD1 < el.pocetak) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Ne mozete odabrati do daljnjeg zato sto u buducnosti vozaca imate zadan interval", "danger", "dodjela-vozila");
          return;
        }
        if (intervaliVozila.find((el)=>{if (el.kraj === null || brojD1 < el.pocetak) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Ne mozete odabrati do daljnjeg zato sto u buducnosti vozila imate zadan interval", "danger", "dodjela-vozila");
          return;
        }
        setBr1((prev)=>{return prev+1});
        postaviFlashPoruku("Upravo snimam ovu trenutnu dodjelu vozila.", "success", "dodjela-vozila");
      }


    } else if (el.id === "gumb-odaberi") {
      setBr1((prev)=>{return prev+1});
    }
    
    //if (odabraniVozacID !== "-") setBr1((prev)=>{return prev+1});
  }

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    let str1 = str.toLocaleLowerCase();
    setSearchStr(str1);

    let sw = false;
    for (let i = 0; i < vozilaDodjele.length; i++) {
      let el = vozilaDodjele[i];
      let name = el.registracija + " " + ukloniNull(el.proizvodac) + " " + ukloniNull(el.model);
      if (name.toLowerCase().includes(str1)) {
        if (el.id.toString() === odabranoVoziloID) {
          sw = true;
          break;
        }
      }
    }
    if (!sw) {
      setOdabranoVoziloID("-");
    }
  }

  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
    //console.log("Kliknuo si na " + e.currentTarget.value);
    e.stopPropagation();
    if (odabraniVozacID !== "-") {
      if (datumDodjele === null) {
        setOdabranoVoziloID(e.currentTarget.value);
      } else {
        postaviFlashPoruku("Datum dodjele je vec odreden pa ne mozete promjeniti vozilo. Prvo ponistite dodjelu.", "danger", "dodjela-vozila");
      }
    } 
  }

  function stopirajPropagaciju(e: React.MouseEvent<HTMLDivElement>, sw:boolean = true) {
    if (sw) e.stopPropagation();
  }

  return (
    <div ref={r} className="pridruzi-vozilo-popup" onClick={(e)=>{stopirajPropagaciju(e, stopPropSw)}}>
      <div className="okvir-menu">
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="dodjela-vozila" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        <table className="tablica"><tbody>
          <tr id="r0" className="row">
            <td className="row-el1">Datum dodjele:</td>
            <td className="row-el2">{ispis(datumDodjele)}</td>
          </tr>
          <tr id="r0" className="row">
            <td className="row-el1">Datum povrata:</td>
            <td className="row-el2">{ispis(datumPovrata)}</td>
          </tr>
        </tbody></table>
        <div className="kalendar-cont">
          
          
          <div className="lijeva-strana">
            <div className="el-a">
              <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
                <div className="input-div-a">
                  <div className="input-div1-a"><label htmlFor="str">Upisi search substring:</label></div>
                  <input onChange={inputFun} value={searchStr} className="input-el-a" type="text" id="str" name="str" placeholder="upisi substring"/>
                </div> 
              </form>
            </div>
            <div className="el-a">
              <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
                <div className="input-div-a">
                  <div className="input-div1-a"><label htmlFor="str">tjedna cijena:</label></div>
                  <input onChange={(e)=>{unesiInt(e,setTjednaCijena,10000,(s:string)=>{return filterStr(s,false)})}} value={ispisId(tjednaCijena)} className="input-el-a" type="text" id="str1" name="str1" placeholder="upisi tjednu cijenu"/>
                </div> 
              </form>
            </div>
            <div className="el1-a">
              {akcija === "dodjeli_vozilo" ?
                <select className="izbornik-a" size={5} value={odabranoVoziloID} onClick={(e)=>{e.stopPropagation()}} 
                   onChange={odabirFun} >
                  <option value="-">bez odabira</option>
                  {vozilaDodjele.filter((el)=>{return (el.registracija + " " + ukloniNull(el.proizvodac) + " " + ukloniNull(el.model)).toLowerCase().includes(searchStr.toLowerCase())})
                     .map((el)=>{return <option selected={selSw((el.id).toString(), odabranoVoziloID)} value={el.id} key={el.id}><span className="span-el-a">{el.registracija}</span>{" " + ukloniNull(el.proizvodac) + " " + ukloniNull(el.model)}</option>})}
                </select> :
                <select className="izbornik-a no-click" size={5} onClick={(e)=>{e.stopPropagation()}}>
                  <option value="-">dodjele:</option>
               {dodjeljenaVozila.filter((el)=>{return (el.registracija + " - " + ukloniNull(el.pocetak) + " " + nullToDoDaljnjeg(el.kraj)).toLowerCase().includes(searchStr.toLowerCase())})
                  .map((el)=>{return <option selected={selSw((el.id_veze).toString(), odabraniID)} value={el.id_veze} key={el.id_veze}><span className="span-el-a">{el.registracija}</span>{" - " + ukloniNull(el.pocetak) + " " + nullToDoDaljnjeg(el.kraj)}</option>})}
                </select>
              }
            </div>


          </div>

          <Kalendar width={200} mjesecOpen={danasnjiMjesec} godinaOpen={danasnjaGodina} 
              dateStr={dateStr} setDateStr={setDateStr}/>
        </div>
       
        <div className="gumbi">
          {stanje === 0 && datumDodjele !== null ? <div onClick={gumbKlik} id="gumb-ponisti" className="div-button-1">Ponisti</div> : null}
          {stanje === 0 && datumDodjele !== null ? <div onClick={gumbKlik} id="gumb-sljedece" className="div-button-1">Sljedece</div> : null}
          {stanje === 1 ? <div onClick={gumbKlik} className="div-button-2" id="gumb-natrag">Natrag</div> : null}
          {stanje === 1 ? <div onClick={gumbKlik} className="div-button-2" id="gumb-daljnjeg">Do daljnjeg</div> : null}
          {stanje === 1 && datumPovrata !== null ? <div onClick={gumbKlik} className="div-button-2" id="gumb-odaberi">Odaberi</div> : null}
        </div>
        <div className="krizic" onClick={zatvoriPopup}>
          <div className="krizic-el" onClick={()=>{}}>
            <div className="el1a"></div>
            <div className="el2a"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

type PovijestProps = {
  odabraniVozacID?: string;
  odabranoVoziloID?: string;
  dodjeljenaVozila?: PodaciVoziloTip[];
  /*vozilaDodjele?: IntervalTip1[];*/
  path?: string;
  setPodaci?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
  setPodaciVozila?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
  sw?: boolean;
  vozilaDodjele?: IntervalTip1[];
  vozilaDodjele1?: PodaciVozilaTip[];
}

function Povijest({odabraniVozacID="-", odabranoVoziloID="-", dodjeljenaVozila=[], 
      path="/api/admin/vozila1", setPodaci=()=>{},
      setPodaciVozila=()=>{}, sw=false, vozilaDodjele=[], vozilaDodjele1=[]}: PovijestProps) {
  
  const [odabraniID, setOdabraniID] = React.useState("-");
  const [confirmBr, setConfirmBr] = React.useState(0);
  const [confirmAction, setConfirmAction] = React.useState<string | null>(null);
  const [akcija, setAkcija] = React.useState("obrisi_dodjelu ili obrisi_dodjelu1")
  const { kljuc } = React.useContext(Kontekst);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const { postaviFlashPoruku } = React.useContext(FlashKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "id_veze": odabraniID,
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);
  
    React.useEffect(()=>{
      console.log("loading6: " + loading);
      console.log("error6: " + error);
      console.log("value6: ");
      console.log("br: " + br);
      console.log(value);
  
      if (br !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA");
          console.log(value.errorCode);  
        } else if (!loading && !value.error){
          setPodaci(value.value.podaci);
          //setPodaciVozila(value.value.vozila);
          postaviFlashPoruku("Upravo si obrisao interval.", "success", "tablica");
        }
      }
    }, [loading]);
  
  React.useEffect(()=>{
    console.log("trenutni podaci1:");
    console.log(odabraniVozacID);
    console.log(dodjeljenaVozila);
    console.log(vozilaDodjele);
    console.log(vozilaDodjele1);
    setOdabraniID("-");
  }, [odabraniVozacID, odabranoVoziloID]);

  React.useEffect(()=>{
    console.log("odabrano vozilo:");
    console.log(odabraniID);

  }, [odabraniID]);

  React.useEffect(()=>{
    if (sw) {
      setAkcija("obrisi_dodjelu1");
    } else {
      setAkcija("obrisi_dodjelu");
    }
  }, [sw])

  React.useEffect(()=>{
    if (br1 !== 0) {
      setBr((prev)=>{return prev+1});
      console.log("saljem nalog sa " + odabraniVozacID);
    }
  }, [br1])

  React.useEffect(()=>{
    if (confirmAction !== null) {
      if (confirmAction === "yes") {
        console.log("Upravo si potvrdio akciju " + Math.random());
        setBr1((prev)=>{return prev+1});
        setConfirmBr(0);
      } else {
        console.log("Upravo si ponistio akciju akciju " + Math.random());
        setConfirmBr(0);
      }
      setConfirmAction(null);
    }
  }, [confirmAction])

  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
    setOdabraniID(e.currentTarget.value);
  }

  function gumbKlik(e:React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    let el = e.currentTarget;
    el.classList.add("klik-1");
    setTimeout(()=>{el.classList.remove("klik-1")}, 150);
    if (el.id === "gumb-obrisi") {
      setConfirmBr(1);
    } else {
      setConfirmBr(2);
    }
  }

  return (
    <div className="povijest-vozila">
      <div className="el1-a1">
        <select className="izbornik-a1" size={5} value={odabraniID} onClick={(e)=>{e.stopPropagation()}} 
            onChange={odabirFun} >
          <option value="-">bez odabira</option>
          {sw ? vozilaDodjele.sort((a:IntervalTip1,b:IntervalTip1)=>{if (a.pocetak > b.pocetak) return 1; if (a.pocetak == b.pocetak) return 0; return -1;}).map((el)=>{return <option selected={selSw((el.id_veze).toString(), odabraniID)} value={el.id_veze} key={el.id_veze}>{el.pocetak + " - " + nullToDoDaljnjeg(el.kraj) + " " +
                ukloniNull(el.ime) + " " + ukloniNull(el.prezime)}</option>})
          :
          dodjeljenaVozila.sort((a:PodaciVoziloTip,b:PodaciVoziloTip)=>{if (a.pocetak > b.pocetak) return 1; if (a.pocetak == b.pocetak) return 0; return -1;}).map((el)=>{return <option selected={selSw((el.id_veze).toString(), odabraniID)} value={el.id_veze} key={el.id_veze}><span className="span-el-a1">{el.registracija}</span>{" " + ukloniNull(el.pocetak) + " - " + nullToDoDaljnjeg(el.kraj) + " " +
            ukloniNull(el.proizvodac) + " " + ukloniNull(el.model)}</option>})
          }
        </select>
        <div className="povijest-gumbi">
          {odabraniID !== "-" ? <div onClick={gumbKlik} className="div-button-1" id="gumb-obrisi">Obrisi</div> : null}
          {odabraniID !== "-" && !sw  ? <div onClick={gumbKlik} className="div-button-1" id="gumb-modificiraj">Modificiraj</div> : null}
        </div>
      </div>
      {confirmBr === 1 ? <ConfirmChoice setConfirmAction={setConfirmAction} 
         tekst="Upravo cete obrisati odabrani interval. Da li ste sigurni?"/> : null}
      {confirmBr === 2 ? <PridruziVoziloPopUp odabraniVozacID={odabraniVozacID} dodjeljenaVozila={dodjeljenaVozila}
                vozilaDodjele={vozilaDodjele1} path={"/api/admin/vozila1"} akcija="modificiraj_dodjelu"
                setPodaciVozila={setPodaciVozila} setPodaci={setPodaci} odabraniID={odabraniID}/> : null} 
    </div>
  )
}

type LockProps = {
  poljeLocks?: boolean[] | null;
  setPoljeLocks?: React.Dispatch<React.SetStateAction<boolean[] | null>>;
  br?: number;
  adminSw?: boolean;
}

function Lock({poljeLocks=[], setPoljeLocks=()=>{}, br=0, adminSw=true}: LockProps) {

  const r = React.useRef<HTMLDivElement>(null);

  React.useEffect(()=>{
    
    if (adminSw) {
      if (r.current !== null) r.current.style.display = "flex";
    } else {
      if (r.current !== null) r.current.style.display = "none";
    }
  }, [adminSw]);

  function fun(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (poljeLocks !== null && poljeLocks.length > br) {
      console.log("kliknuo si za promjenu brave  " + Math.random());
      let pp = [...poljeLocks];
      pp[br] = !pp[br];
      console.log("novo stanje je " + pp[br]);
      setPoljeLocks([...pp]);
    }
  }

  return (
    <div ref={r} className="lockk" onClick={fun}>
      {poljeLocks !== null && (poljeLocks.length <= br || !poljeLocks[br]) ? <BsFillUnlockFill className="lock-ikona"/> : 
        <BsFillLockFill className="unlock-ikona"/>}
    </div>
  )
}

type VoziloPodTip = {
  id: number;
  registracija: string;
  proizvodac: string;
  model: string;
  interval: {pocetak: number, kraj: number}[];
}

export function Tablica({clickSw=false, odabraniVozacID="-", podaci=[], setPodaci=()=>{}, 
       path="/api/admin/glavna", podaciVozila=[], setPodaciVozila=()=>{}, aliases=[], adminSw=true, 
       setAliases=()=>{}, showSw=novoPolje(true,20), prekidaci=novoPolje(true, 20)}: TablicaProps) {
  const [id, setId] = React.useState<null | number>(null);
  const [userName, setUserName] = React.useState<null | string>("Sumar");
  const [ime, setIme] = React.useState<null | string>("Slobodan");
  const [prezime, setPrezime] = React.useState<null | string>(null);
  const [email, setEmail] = React.useState<null | string>("savic.slobodan@mail.com");
  const [phoneNum, setPhoneNum] = React.useState<null | string>("0916543442");
  const [whatsAppSw, setWhatsAppSw] = React.useState<null | boolean>(true);
  const [adresa, setAdresa] = React.useState<null | string>(null);
  const [grad, setGrad] = React.useState<null | string>(null);
  const [uberSw, setUberSw] = React.useState<null | boolean>(null);
  const [boltSw, setBoltSw] = React.useState<null | boolean>(true);
  const [tipProvizije, setTipProvizije] = React.useState<null | "provizija" | "postotak" | "fiksni">(null);
  const [provizija, setProvizija] = React.useState<null | string>(null);
  const [blokiranSw, setBlokiranSw] = React.useState<null | boolean>(false);
  const [straniRadnikSw, setStraniRadnikSw] = React.useState<null | boolean>(false);
  const [bankovniRacun, setBankovniRacun] = React.useState<null | string>(null);
  const [dodjeljenaVozila, setDodjeljenaVozila] = React.useState<PodaciVoziloTip[]>([]);
  const [vozilaDodjele, setVozilaDodjele] = React.useState<PodaciVozilaTip[]>([]);
  const [OIB, setOIB] = React.useState<null | string>("36209752099");
  const [swPolje, setSwPolje] = React.useState(novoPolje(0,20));
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {kljuc, setKljuc} = React.useContext(Kontekst);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [lozinka, setLozinka] = React.useState<null | string>(null);
  const [lozinkaConfirm, setLozinkaConfirm] = React.useState<null | string>(null);
  const [poljeLocks, setPoljeLocks] = React.useState<boolean[] | null>(novoPolje(false,13)); // lock status za sljedece retke:
  const [lock, setLock] = React.useState(true);
  // ime 0, prezime 1, nova lozinka 2, email 3, broj mobitela 4, ima whatsup 5, adresa 6, grad 7, prijavljen na uber 8, bolt 9, bankovno blokiran 10, OIB 11, bankovni racun 12

  const [prek, setPrek] = React.useState(prekidaci);
  const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": "modify",
      "id": odabraniVozacID,
      "ime": ukloniPrazneZnakoveNull(ime),
      "prezime": ukloniPrazneZnakoveNull(prezime),
      "email": ukloniPrazneZnakoveNull(email), 
      "phone_number": ukloniPrazneZnakoveNull(phoneNum),
      "whatsAppSw": whatsAppSw,
      "adresa": ukloniPrazneZnakoveNull(adresa),
      "grad": ukloniPrazneZnakoveNull(grad),
      "uberSw": uberSw,
      "boltSw": boltSw,
      "tip_provizije": tipProvizije,
      "provizija": provizija,
      "blokiranSw": blokiranSw,
      "strani_radnikSw": straniRadnikSw,
      "bankovni_racun": emptyIsNull(ukloniPrazneZnakoveNull(bankovniRacun)),
      "OIB": emptyIsNull(ukloniPrazneZnakoveNull(OIB)),
      "lozinka": lozinka,
      "poljeLocks": poljeLocks  // ovdje si stao, nastavljas na rails backendu
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    if (false) {
      setPoljeLocks(null);
    }
  }, []);

  React.useEffect(()=>{
    console.log("novo polje lockova je ");
    console.log(poljeLocks);
  }, [poljeLocks])
  React.useEffect(()=>{
    if (br !== 0) {
      console.log("polje lockova koje smo poslali ");
      console.log(poljeLocks);
    }
  }, [br])

  React.useEffect(()=>{
    if (br1 !== 0) {
      let regex = /\A[\w+\-.]+@[a-z\d\-.]+\.[a-z]+\z/i
      regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
      let sw = true;
      if (email !== null && !regex.test(email)) {
        postaviFlashPoruku("Niste upisali dobar email. Probajte ponovo", "danger", "tablica");
        sw = false;
      } 

      if (lozinka !== null) {
        if (lozinka.length < 6) {
          postaviFlashPoruku("Lozinka mora imati barem 6 znakova", "danger", "tablica");
          setLozinka(null);
          setLozinkaConfirm(null);
          return;
        } else if (lozinka !== lozinkaConfirm) {
          postaviFlashPoruku("Lozinka i njezina potvrda se ne podudaraju.", "danger", "tablica");
          setLozinka(null);
          setLozinkaConfirm(null);
          return;
        } else {
          let swBroj = false;
          let swSlovo = false;
          let swBlank = false;
          for (let i = 0; i < lozinka.length; i++) {
            let znak = lozinka[i];
            if (znak == " ") {
              swBlank = true;
              break;
            } 
            switch (znak) {
              case "0":
              case "1":
              case "2":
              case "3":
              case "4":
              case "5":
              case "6":
              case "7":
              case "8":
              case "9":
                swBroj = true;
            }
            if (znak >= "a" && znak <= "z")  swSlovo = true;
            if (znak >= "A" && znak <= "Z")  swSlovo = true;
          }

          if (swBlank) {
            postaviFlashPoruku("Lozinka ne smije imati prazan znak", "danger", "tablica");
            setLozinka(null);
            setLozinkaConfirm(null);
            return;
          }
          if (!swSlovo) {
            postaviFlashPoruku("Lozinka mora imati bar jedno slovo", "danger", "tablica");
            setLozinka(null);
            setLozinkaConfirm(null);
            return;
          }
          if (!swBroj) {
            postaviFlashPoruku("Lozinka mora imati bar jedan broj", "danger", "tablica");
            setLozinka(null);
            setLozinkaConfirm(null);
            return;
          }
        }
      }

      if (phoneNum === null) {
        postaviFlashPoruku("Morate osigurati broj mobitela.", "danger", "tablica");
        sw = false;
      } else if (ukloniPrazneZnakove(phoneNum,true,true).length < 7 || ukloniPrazneZnakove(phoneNum,true,true).length > 15) {
        postaviFlashPoruku("Broj mobitela mora imati vise od 6 a manje od 16 znamenki.", "danger", "tablica");
        sw = false;
      }
      if (bankovniRacun !== null && bankovniRacun.length !== 0 && bankovniRacun.length !== 21) {
        postaviFlashPoruku("Bankovni racun je pogresan, mora biti duljine 21. Unos mozes ostaviti i prazan.", "danger", "tablica");
        sw = false;
      }

      if (OIB !== null && OIB.length !== 0 && OIB.length !== 11) {
        postaviFlashPoruku("OIB je pogresan, mora biti duljine 11. Unos mozes ostaviti i prazan.", "danger", "tablica");
        sw = false;
      }

      if (sw) {
        console.log("saljemo request " + Math.random());
        setBr((prev)=>{return prev+1});
      }

    }
  }, [br1])

  React.useEffect(()=>{
    console.log("loading6: " + loading);
    console.log("error6: " + error);
    console.log("value6: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        if (value.errorCode === "Vec postoji korisnik sa ovim bankovnim racunom. Morate upisati neki drugi") {
          postaviFlashPoruku("Vec postoji korisnik sa ovim bankovnim racunom. Morate upisati neki drugi, ili polje ostavite prazno", "danger", "tablica");
        } else if (value.errorCode === "Vec postoji korisnik sa ovim OIB-om. Morate upisati neki drugi") {
          postaviFlashPoruku("Vec postoji korisnik sa ovim OIB-om. Morate upisati neki drugi ili ostavite polje prazno", "danger", "tablica");
        } else if (value.errorCode === "Vec postoji korisnik sa ovim emailom. Morate upisati neki drugi") {
          postaviFlashPoruku("Vec postoji korisnik sa ovim emailom. Morate upisati neki drugi ili ostavite polje prazno", "danger", "tablica");
        } else if (value.errorCode === "Vec postoji korisnik sa ovim telefonskim brojem. Morate upisati neki drugi") {
          postaviFlashPoruku("Vec postoji korisnik sa ovim telefonskim brojem. Morate upisati neki drugi", "danger", "tablica");
        }

      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setPodaci(value.value.podaci);
        setPodaciVozila(value.value.vozila);
        setAliases(value.value.aliases);
        postaviFlashPoruku("Upravo si modificirao vozaca", "success", "tablica");
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    let pod = podaci.find((el)=>{if (el.id.toString() === odabraniVozacID.toString()) return true; return false});

    console.log("podaci:");
    console.log(podaci);
    console.log("odabraniVozacID:");
    console.log(odabraniVozacID);
    console.log("odabrani pod je ");
    console.log(pod);
    console.log("podaci o vozilima");
    console.log(podaciVozila);
    if (podaciVozila.length === 0) {
      setVozilaDodjele([]);
    } else {
      setVozilaDodjele(podaciVozila as PodaciVozilaTip[]);
    }
    if (pod === undefined) {
      setId(null);
      setUserName(null);
      setIme(null);
      setPrezime(null);
      setEmail(null);
      setPhoneNum(null);
      setWhatsAppSw(null);
      setAdresa(null);
      setGrad(null);
      setUberSw(null);
      setBoltSw(null);
      setTipProvizije(null);
      setProvizija(null);
      setBlokiranSw(null);
      setStraniRadnikSw(null);
      setBankovniRacun(null);
      setOIB(null);
      setDodjeljenaVozila([]);
      setPoljeLocks(null);
    } else {
      setId(pod.id);
      setUserName(pod.name);
      setIme(pod.ime);
      setPrezime(pod.prezime);
      setEmail(pod.email);
      setPhoneNum(pod.phone_number);
      setWhatsAppSw(unosBooleana(pod.whatsAppSw));
      setAdresa(pod.adresa);
      setGrad(pod.grad);
      setUberSw(unosBooleana(pod.uberSw));
      setBoltSw(unosBooleana(pod.boltSw));
      setTipProvizije(pod.tip_provizije);
      setProvizija(pod.provizija);
      setBlokiranSw(unosBooleana(pod.blokiranSw));
      setStraniRadnikSw(unosBooleana(pod.strani_radnikSw));
      setBankovniRacun(pod.bankovni_racun);
      setOIB(pod.OIB);
      setPoljeLocks(pod.locks);
      setLock(false);
      
      //potencijalni bug je samo ovdje
      if (pod.vozila === undefined) {
        setDodjeljenaVozila([]);
      } else {
        setDodjeljenaVozila(pod.vozila.sort((a:PodaciVoziloTip,b:PodaciVoziloTip)=>{if (a.pocetak > b.pocetak) return 1; if (a.pocetak == b.pocetak) return 0; return -1;}));
      }
    }
  }, [odabraniVozacID, podaci]);

  React.useEffect(()=>{
    console.log("dodjeljena vozila su11 ");
    console.log(dodjeljenaVozila);

    //console.log("sortiramo");
    //console.log(["2023-04-01", "2023-02-22", "2019-07-31", "2024-12-31"].sort((a,b)=>{if (a < b) return 1; if (a == b) return 0; return -1;}))

  }, [dodjeljenaVozila]);

  React.useEffect(()=>{
    if (rKlik.current !== null && rKlik.current !== undefined) (rKlik.current.lastElementChild?.firstElementChild as HTMLElement)?.focus();
  }, [swPolje]);

  function prikazBankovnogRacuna(inp: string | null) {
    if (inp === null) {
        return "-";
    }
    return inp;
  }

  function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
    let id = e.currentTarget.id;
    let br = parseInt(id.substring(1));
    //console.log("event " + id + "  -  " + Math.random());
    //console.log("prek[" + br + "] je " + prek[br]);
    if (prek[br]) {
      if (lock) return;
      if (!adminSw && br === 2 && poljeLocks !== null && poljeLocks[0])  return;
      if (!adminSw && br === 3 && poljeLocks !== null && poljeLocks[1])  return;
      if (!adminSw && br === 18 && poljeLocks !== null && poljeLocks[2]) return;

      if (!adminSw && br === 4 && poljeLocks !== null && poljeLocks[3])  return;
      if (!adminSw && br === 5 && poljeLocks !== null && poljeLocks[4])  return;
      if (!adminSw && br === 6 && poljeLocks !== null && poljeLocks[5])  return;
      if (!adminSw && br === 7 && poljeLocks !== null && poljeLocks[6])  return;
      if (!adminSw && br === 8 && poljeLocks !== null && poljeLocks[7])  return;
      if (!adminSw && br === 9 && poljeLocks !== null && poljeLocks[8])  return;
      if (!adminSw && br === 10 && poljeLocks !== null && poljeLocks[9])  return;
      if (!adminSw && br === 11 && poljeLocks !== null && poljeLocks[10])  return;
      
      if (!adminSw && br === 13 && poljeLocks !== null && poljeLocks[11])  return;
      if (!adminSw && br === 14 && poljeLocks !== null && poljeLocks[12])  return;

      if (!adminSw && br === 19)  return;

      if (e.type === "mouseenter") {
        e.currentTarget.classList.add("hover");
      } else if (e.type === "mouseleave") {
        e.currentTarget.classList.remove("hover");
      } else {
        if (br === 15  && ispisDodjeljenogVozila(dodjeljenaVozila) !== "-") {
          postaviFlashPoruku("Ne mozete dodjeliti novo vozilo vozacu dok staro nije vraceno.", "danger", "tablica");
          return; 
        }
        rKlik.current = e.currentTarget;
        let b = (swPolje[br] + 1) % 2;
        setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})

        console.log("kliknuo " + b);
      }
    }
    
  }

  function postaviIznos(e: React.ChangeEvent<HTMLInputElement>, setCallback: React.Dispatch<React.SetStateAction<string | null>>,
      kod: number = 0, filterFun: (str: string) => boolean = () => { return true }) {
      let str = e.target.value;
      if (!filterFun(str)) return;
      let sw = false;

      if (str.length === 2 && str[0] === "-") {

      } else if (str.length === 0) {
          setCallback(null);
          return;
      } else {
          let c = str[str.length - 1];
          switch (c) {
              case "0":
              case "1":
              case "2":
              case "3":
              case "4":
              case "5":
              case "6":
              case "7":
              case "8":
              case "9":
              case ".":
                  sw = true;
              //setProvizija(str);
          }
      }

      if (sw) {
          let br = 0;
          let decSw = false;
          let brDec = 0;
          for (let i = 0; i < str.length; i++) {
              if (decSw) {
                  brDec += 1;
              }
              if (str[i] === ".") {
                  br += 1;
                  decSw = true;
              }
          }
          if (kod === 0) {
              if (br > 1) {
                  postaviFlashPoruku("Postotak moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica");
              } else if (parseFloat(str) < 0 || parseFloat(str) > 100) {
                  postaviFlashPoruku("Iznos provizije mora biti u postotcima (izmedu 0 i 100)", "danger", "tablica");
              } else {
                  setCallback(maxS(str, 7));
              }
          } else if (kod === 1) {
              if (br > 1) {
                  postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica");
              } else if (brDec > 2) {
                  postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica");
              } else if (parseFloat(str) < 0 || parseFloat(str) > 100000) {
                  postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 100000", "danger", "tablica");
              } else {
                  setCallback(maxS(str, 8));
              }
          } else if (kod === 2) {
            if (br > 1) {
                postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica");
            } else if (brDec > 2) {
                postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica");
            } else if (parseFloat(str) < 0 || parseFloat(str) > 999.99) {
                postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 1000", "danger", "tablica");
            } else {
                setCallback(maxS(str, 8));
            }
          }
      }
  }

  function postaviPhoneNum(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    if (!filterStr(str)) return false;

    if (str.length === 2 && str[0] === "-") {

    } else if (str.length === 0) {
      setPhoneNum(null);
    } else {
      let c = str[str.length-1];
      switch (c) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case " ":
          setPhoneNum(maxS(str,20));
      }
    }
  }

  function postaviId(e: React.ChangeEvent<HTMLInputElement>) {
    console.log(e.target.value);
    console.log(Math.random());

    let str = e.target.value;

    if (str.length === 2 && str[0] === "-") {

    } else if (str.length === 0) {
      setId(null);
    } else {
      let c = str[str.length-1];
      switch (c) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          setId(parseInt(str));
      }
    }

  }

  type filterProps = {
    e: React.KeyboardEvent<HTMLInputElement>;
    space?: boolean;
    backspace: boolean;
  }

  function postaviWhatsup(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (whatsAppSw === null || !whatsAppSw) {
      setWhatsAppSw(true);
    } else {
      setWhatsAppSw(false);
    }
  }

  function postaviBankovniRacun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value.toUpperCase();
    let len = str.length;
    if (len > 21) {
      postaviFlashPoruku("Ukupna duljina ne smije prijeci 21 znak.", "danger", "tablica");
      return;
    }

    if (len >= 1 && (str.charCodeAt(0) < 65 || str.charCodeAt(0) > 90)) {
      postaviFlashPoruku("Prva dva znaka moraju biti slova (za Hrvatsku HR)", "danger", "tablica");
      return;
    }

    if (len >= 2 && (str.charCodeAt(1) < 65 || str.charCodeAt(1) > 90)) {
      postaviFlashPoruku("Prva dva znaka moraju biti slova (za Hrvatsku HR)", "danger", "tablica");
      return;
    }

    for (let i = 2; i < len; i++) {
      if (str.charCodeAt(i) < 48 || str.charCodeAt(i) > 57) {
        postaviFlashPoruku("Znakovi poslije prva dva moraju biti brojke", "danger", "tablica");
        return;
      }
    }
    setBankovniRacun(str);

  }

  function postaviOIB(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    if (!filterStr(str, false, false)) return false;
    if (str.length > 11) {
      postaviFlashPoruku("OIB se sastoji od 11 znamenki.", "danger", "tablica");
      return;
    }
    setOIB(str);
  }

  function maxS(str: string, len: number): string {
    // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
    if (str.length > len) {
      postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica");
      return str.substring(0,len);
    }
    return str;
  }

  function modifyKlik(e:React.MouseEvent<HTMLDivElement>) {
    let el = e.currentTarget;
    el.classList.add("klik");
    setTimeout(()=>{el.classList.remove("klik")}, 150);
    
    if (odabraniVozacID !== "-") setBr1((prev)=>{return prev+1});
  }

  function klikFun(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation(); 
    let id = e.currentTarget.id;

    if (tipProvizije === "fiksni" && provizija !== null && parseFloat(provizija) > 100) {
      setProvizija("10");
    }
    setTipProvizije((prev)=>{
      if (prev === "provizija") {
        return "fiksni";
      } else if (prev === "fiksni") {
        return "postotak";
      } else {
        return "provizija";
      }
    })
  }

  function ispisDodjeljenogVozila(vozila: PodaciVoziloTip[]) : string {
    if (vozila.length === 0) return "-";
    let el = vozila[vozila.length-1];
    if (el.kraj === null) {
      return el.registracija + " " + el.proizvodac + " " + el.model;
    } else {
      return "-";
    }
  }

  return (
    <div className="tablica-cont">
      <table className="tablica"><tbody>
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        {showSw[0] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r0" className="row">
          <td className="row-el1">ID:</td>
          {swPolje[0] === 0 ?
            <td className="row-el2">{ispis(id)}</td> : 
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={postaviId} id="input0" name="input0" value={ispisId(id)}/>
            </td>
          }
        </tr> : null}
        {showSw[1] ?
        <tr id="r1" className="row">
          <td className="row-el1">Username:</td>
            <td className="row-el2">{ispis(userName)}</td> 
        </tr> : null}
        {showSw[2] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r2" className="row">
          <td className="row-el1">Ime:</td>
          {swPolje[2] === 0 ?
            <td className="row-el2">{ispis(ime)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={0}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setIme(maxS(e.target.value,32))}} id="input2" name="input2" value={ispisId(ime)}/>
            </td>
          }
        </tr> : null}
        {showSw[3] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r3" className="row">
          <td className="row-el1">Prezime:</td>
          {swPolje[3] === 0 ?
            <td className="row-el2">{ispis(prezime)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={1}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setPrezime(maxS(e.target.value,32))}} id="input3" name="input3" value={ispisId(prezime)}/>
            </td>
          }
        </tr> : null}

        {showSw[18] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r18" className="row">
          <td className="row-el1">Nova lozinka:</td>
          {swPolje[18] === 0 ?
            <td className="row-el2">klikni za promjenu lozinke<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={2}/></td> :
            <td className="row-el2">
              <label htmlFor="input18" className="lozinka-label">Nova lozinka:</label>
              <input type="password" onClick={(e)=>{e.stopPropagation()}} className="lozinka" placeholder="upisite novu lozinku"
                 onChange={(e)=>{setLozinka(maxS(e.target.value,32))}} id="input18" name="input18" value={ispisId(lozinka)}/>
              <label htmlFor="input18a" className="lozinka-label">Potvrdi:</label>
              <input type="password" onClick={(e)=>{e.stopPropagation()}} className="lozinka" placeholder="potvrdite novu lozinku"
                 onChange={(e)=>{setLozinkaConfirm(maxS(e.target.value,32))}} id="input18a" name="input18a" value={ispisId(lozinkaConfirm)}/>
            </td>
          }
        </tr> : null}

        {showSw[15] ?
        <tr onClick={redakEventFun} id="r15" className={prek[15] ? "row hover-klasa" : "row"}>
          <td className="row-el1">Dodjeljeno vozilo:</td>
          {swPolje[15] === 0 ?
            <td className="row-el2">{ispisDodjeljenogVozila(dodjeljenaVozila)}</td> :
            <td className="row-el2">
              <PridruziVoziloPopUp odabraniVozacID={odabraniVozacID} dodjeljenaVozila={dodjeljenaVozila}
                vozilaDodjele={vozilaDodjele} path={"/api/admin/vozila1"}
                setPodaciVozila={setPodaciVozila} setPodaci={setPodaci}/>
            </td>
          }
        </tr> : null}
        {showSw[16] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r16" className="row">
          <td className="row-el1">Povijest dodjela:</td>
          {swPolje[16] === 0 ?
            <td className="row-el2">Klikni za pregled</td> :
            <td className="row-el2">
              <Povijest odabraniVozacID={odabraniVozacID} dodjeljenaVozila={dodjeljenaVozila}
                setPodaci={setPodaci} setPodaciVozila={setPodaciVozila} vozilaDodjele1={vozilaDodjele}/>
            </td>
          }
        </tr> : null}
        {showSw[17] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r17" className="row">
          <td className="row-el1">Aliases:</td>
          {swPolje[17] === 0 ?
            <td className="row-el2">Klikni za pregled</td> :
            <td className="row-el2">
              <AliasManager odabraniVozacID={odabraniVozacID} aliases={aliases} setAliases={setAliases}/>
            </td>
          }
        </tr> : null}
        {showSw[4] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r4" className="row">
          <td className="row-el1">Email:</td>
          {swPolje[4] === 0 ?
            <td className="row-el2">{ispis(email)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={3}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
                 onChange={(e)=>{setEmail(maxS(e.target.value,128))}} id="input4" name="input4" value={ispisId(email)}/>
            </td>
          }
        </tr> : null}
        {showSw[5] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r5" className="row">
          <td className="row-el1">Broj mobitela:</td>
          {swPolje[5] === 0 ?
            <td className="row-el2">{ispis(phoneNum)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={4}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={postaviPhoneNum} id="input5" name="input5" value={ispisId(phoneNum)}/>
            </td>
          }
        </tr> : null}
        {showSw[6] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r6" className="row">
          <td className="row-el1">Ima whatsup:</td>
          {swPolje[6] === 0 ?
            <td className="row-el2">{ispis(whatsAppSw)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={5}/></td> :
            <td className="row-el2">
              <div onClick={postaviWhatsup} className="div-button">{ispis(whatsAppSw)}</div>
            </td>
          }
        </tr> : null}
        {showSw[7] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r7" className="row">
          <td className="row-el1">Adresa:</td>
          {swPolje[7] === 0 ?
            <td className="row-el2">{ispis(adresa)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={6}/></td> : 
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setAdresa(maxS(e.target.value,128))}} id="input7" name="input7" value={ispisId(adresa)}/>
            </td>
          }
        </tr> : null}
        {showSw[8] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r8" className="row">
          <td className="row-el1">Grad:</td>
          {swPolje[8] === 0 ?
            <td className="row-el2">{ispis(grad)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={7}/></td> : 
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setGrad(maxS(e.target.value,32))}} id="input8" name="input8" value={ispisId(grad)}/>
            </td>
          }
        </tr> : null}
        {showSw[9] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r9" className="row">
          <td className="row-el1">Prijavljen na Uber:</td>
          {swPolje[9] === 0 ?
            <td className="row-el2">{ispis(uberSw)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={8}/></td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); setUberSw((prev)=>{if (prev === null) return true; return !prev;})}} className="div-button">{ispis(uberSw)}</div>
            </td>
          }
        </tr> : null}
        {showSw[10] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r10" className="row">
          <td className="row-el1">Prijavljen na Bolt:</td>
          {swPolje[10] === 0 ?
            <td className="row-el2">{ispis(boltSw)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={9}/></td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); setBoltSw((prev)=>{if (prev === null) return true; return !prev;})}} className="div-button">{ispis(boltSw)}</div>
            </td>
          }
        </tr> : null}


        {showSw[19] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r19" className="row">
          <td className="row-el1">Tip obracuna:</td>
          {swPolje[19] === 0 ?
            <td className="row-el2">{tipProvizije} - {provizija}{tipProvizije === "fiksni" ? "eu" : "%"}</td> :
            <td className="row-el3">
              <div onClick={klikFun} className="div-button-3" id="div19">{tipProvizije}</div>
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input19a" name="input19a" value={ispisId(provizija)}
                   onChange={(e)=>{postaviIznos(e,setProvizija,tipProvizijeKod(tipProvizije), (str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr> : null}



        {showSw[11] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r11" className="row">
          <td className="row-el1">Bankovno blokiran:</td>
          {swPolje[11] === 0 ?
            <td className="row-el2">{ispis(blokiranSw)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={10}/></td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); setBlokiranSw((prev)=>{if (prev === null) return true; return !prev;})}} className="div-button">{ispis(blokiranSw)}</div>
            </td>
          }
        </tr> : null}
        {showSw[12] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r12" className="row">
          <td className="row-el1">Strani radnik:</td>
          {swPolje[12] === 0 ?
            <td className="row-el2">{ispis(straniRadnikSw)}</td> : 
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); setStraniRadnikSw((prev)=>{if (prev === null) return true; return !prev;})}} className="div-button">{ispis(straniRadnikSw)}</div>
            </td>
          }
        </tr> : null}
        {showSw[13] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r13" className="row">
          <td className="row-el1">Bankovni račun:</td>
          {swPolje[13] === 0 ?
            <td className="row-el2">{prikazBankovnogRacuna(bankovniRacun)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={11}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={postaviBankovniRacun} id="input13" name="input13" value={ispisId(bankovniRacun)}/>
            </td>
          }
        </tr> : null}
        {showSw[14] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r14" className="row">
          <td className="row-el1">OIB:</td>
          {swPolje[14] === 0 ?
            <td className="row-el2">{ispis(OIB)}<Lock poljeLocks={poljeLocks} adminSw={adminSw} setPoljeLocks={setPoljeLocks} br={12}/></td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}}
                 onChange={postaviOIB} id="input14" name="input14" value={ispisId(OIB)}/>
            </td>
          }
        </tr> : null}

        <tr className="row"> 
          <td className="row-el2" colSpan={2} >
            <div onClick={modifyKlik} className="div-button">Modificiraj</div>
          </td>  
        </tr>
      </tbody></table>
    </div>
  )
}

type TablicaVozilaProps = {
  clickSw?: boolean;
  prekidaci?: boolean[]; // 16 ili 17 prekidaca
  odabranoVoziloID?: string;
  setOdabranoVoziloID?: React.Dispatch<React.SetStateAction<string>>;
  podaci?: {[index:string]: any}[];
  setPodaci?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
  setPodaciVozila?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
  path?: string;
  novoVoziloSw?: boolean;
  setNovoVoziloSw?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TablicaVozila({clickSw=false, odabranoVoziloID="-", setOdabranoVoziloID=()=>{},
   podaci=[], setPodaci=()=>{}, setPodaciVozila=()=>{}, path="/api/admin/glavna", novoVoziloSw=false, setNovoVoziloSw=()=>{}, 
   prekidaci=novoPolje(true,17)}: TablicaVozilaProps) {
  
  const [akcija, setAkcija] = React.useState("modify");
  const [id, setId] = React.useState<null | number>(null);
  const [registracija, setRegistracija] = React.useState<null | string>(null);
  const [proizvodac, setProizvodac] = React.useState<null | string>(null);
  const [model, setModel] = React.useState<null | string>(null);
  const [tipMotora, setTipMotora] = React.useState<null | string>(null);
  const [godina, setGodina] = React.useState<null | string>(null);
  const [bojaVozila, setBojaVozila] = React.useState<null | string>(null);
  const [datumIstekaOsiguranja, setDatumIstekaOsiguranja] = React.useState<null | string>(null);
  const [vlasnik, setVlasnik] = React.useState<null | string>(null);
  const [adresa, setAdresa] = React.useState<null | string>(null);
  const [rentSw, setRentSw] = React.useState<null | boolean>(null);
  const [tjednaCijena, setTjednaCijena] = React.useState<null | number>(null);
  const [kilometraza, setKilometraza] = React.useState<null | number>(null);
  const [sljedeciServis, setSljedeciServis] = React.useState<null | number>(null);
  const [tipGuma, setTipGuma] = React.useState<null | string>(null);
  const [biljeska, setBiljeska] = React.useState<null | string>(null);
  const [prek, setPrek] = React.useState(prekidaci);
  const [swPolje, setSwPolje] = React.useState(novoPolje(0,17));
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {kljuc, setKljuc} = React.useContext(Kontekst);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo
  const [vozilaDodjele, setVozilaDodjele] = React.useState<IntervalTip1[]>([]);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "id": id,
        "registracija": registracija,
        "proizvodac": ukloniPrazneZnakoveNull(proizvodac),
        "model": ukloniPrazneZnakoveNull(model),
        "tip_motora": ukloniPrazneZnakoveNull(tipMotora),
        "godina": ukloniPrazneZnakoveNull(godina),
        "boja_vozila": ukloniPrazneZnakoveNull(bojaVozila),
        "datum_isteka_osiguranja": datumIstekaOsiguranja,
        "vlasnik": ukloniPrazneZnakoveNull(vlasnik),
        "adresa": ukloniPrazneZnakoveNull(adresa),
        "rentSw": rentSw,
        "tjedna_cijena": tjednaCijena,
        "kilometraza": kilometraza,
        "sljedeci_servis": sljedeciServis,
        "tip_guma": tipGuma,
        "biljeska": biljeska
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);

  React.useEffect(()=>{
    if (novoVoziloSw) {
      setAkcija("add");
    } else {
      setAkcija("modify");
    }
  }, [novoVoziloSw]);

  React.useEffect(()=>{
    console.log("podaci1AAAA " + Math.random());
    console.log(podaci);
  }, [podaci]);

  React.useEffect(()=>{
    console.log("vozila dodjeleAAAA " + Math.random());
    console.log(vozilaDodjele);
  }, [vozilaDodjele]);

  React.useEffect(()=>{
    console.log("Tjedna cijena " + Math.random());
    console.log(tjednaCijena);
  }, [tjednaCijena])

  React.useEffect(()=>{
    if (br1 !== 0) {
      let sw = true;
      if (registracija === null) {
        sw = false;
        postaviFlashPoruku("Morate osigurati jedinstvenu registraciju.", "danger", "tablica-vozila");
      } else {
        let str = ukloniPrazneZnakove(registracija, true, true);
        if (str.length < 3 || str.length > 12) {
          sw = false;
          postaviFlashPoruku("Morate osigurati registraciju pravilne duljine (razmaci se ignoriraju)", "danger", "tablica-vozila");
        } else {
          setRegistracija(str);
        }
      }
      if (godina !== null && godina.length < 4) {
        sw = false;
        postaviFlashPoruku("Godina mora imati cetiri znamenke", "danger", "tablica-vozila");
      }

      if (sw) {
        console.log("saljemo request " + Math.random());
        setBr((prev)=>{return prev+1});
      }
    }
  }, [br1]);

  React.useEffect(()=>{
    console.log("loading6: " + loading);
    console.log("error6: " + error);
    console.log("value6: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);                  
        if (value.errorCode === "Vec postoji vozilo sa ovom registracijom. Morate upisati neku drugu") {
          postaviFlashPoruku("Vec postoji vozilo sa ovom registracijom. Morate upisati neku drugu", "danger", "tablica-vozila");
        } 
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setPodaci(value.value.podaci);
        if (akcija === "modify") {
          postaviFlashPoruku("Upravo si modificirao vozilo", "success", "tablica-vozila");
        } else {
          postaviFlashPoruku("Upravo si dodao novo vozilo", "success", "tablica-vozila");
          setOdabranoVoziloID(value.value.vozacID);
          setNovoVoziloSw(false);
        }
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    let pod = podaci.find((el)=>{if (el.id.toString() === odabranoVoziloID.toString()) return true; return false});

    console.log("podaci:");
    console.log(podaci);
    console.log("odabraniVozacID:");
    console.log(odabranoVoziloID);
    console.log("odabrani pod je ");
    console.log(pod);
    if (pod === undefined) {
      setId(null);
      setRegistracija(null);
      setProizvodac(null);
      setModel(null);
      setTipMotora(null);
      setGodina(null);
      setBojaVozila(null);
      setDatumIstekaOsiguranja(null);
      setVlasnik(null);
      setAdresa(null);
      setRentSw(null);
      setTjednaCijena(null);
      setKilometraza(null);
      setSljedeciServis(null);
      setTipGuma(null);
      setBiljeska(null);
      setVozilaDodjele([]);
    } else {
      setId(pod.id);
      setRegistracija(pod.registracija);
      setProizvodac(pod.proizvodac);
      setModel(pod.model);
      setTipMotora(pod.tip_motora);
      setGodina(pod.godina);
      setBojaVozila(pod.boja_vozila);
      let dat = pod.datum_isteka_osiguranja;
      if (dat === null) {
        setDatumIstekaOsiguranja(dat);
      } else {
        let el = dat.split("-");
        dat = el[2] + "." + el[1] + "." + el[0];
        setDatumIstekaOsiguranja(dat);
      }
      setVlasnik(pod.vlasnik);
      setAdresa(pod.adresa);
      setRentSw(unosBooleana(pod.rentSw));
      setTjednaCijena(pod.tjedna_cijena);
      setKilometraza(pod.kilometraza);
      setSljedeciServis(pod.sljedeci_servis);
      setTipGuma(pod.tip_guma);
      setBiljeska(pod.biljeska);
      setVozilaDodjele(pod.intervali);
    }
  }, [odabranoVoziloID, podaci]);

  React.useEffect(()=>{
    if (rKlik.current !== null && rKlik.current !== undefined) (rKlik.current.lastElementChild?.firstElementChild as HTMLElement)?.focus();
  }, [swPolje]);

  
  function maxS(str: string, len: number): string {
    // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
    if (str.length > len) {
      postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica-vozila");
      return str.substring(0,len);
    }
    return str;
  }

  function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
    let id = e.currentTarget.id;
    let br = parseInt(id.substring(2));
    if (prek[br]) {
      if (e.type === "mouseenter") {
        e.currentTarget.classList.add("hover");
      } else if (e.type === "mouseleave") {
        e.currentTarget.classList.remove("hover");
      } else {
        console.log("kliknuo si na " + br + "  -  " + Math.random());
        rKlik.current = e.currentTarget;
        let b = (swPolje[br] + 1) % 2;
        setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})

        console.log("kliknuo " + b);
      }
    }
    
  }

  function unesiInt(e: React.ChangeEvent<HTMLInputElement>, setInt: React.Dispatch<React.SetStateAction<number | null>>, 
   maxInt: number = 16777215, filterFun: (str:string)=>boolean = ()=>{return true}) {
    let str = e.currentTarget.value;
    if (!filterFun(str)) return;
    if (str === "") {
      setInt(null);
    } else {
      let br = parseInt(str);
      if (br < maxInt) {
        setInt(br);
      } else {
        postaviFlashPoruku("Za ovaj field maksimalna vrijednost je " + (maxInt-1), "danger", "tablica-vozila");
      }
    }
  }

  function unesiStr(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, 
        setStr: React.Dispatch<React.SetStateAction<string | null>>, maxStr: number = 1024, 
        upperCase: boolean = false, filterFun: (str:string)=>boolean = ()=>{return true}) : void {
    let str = e.currentTarget.value;
    
    if (!filterFun(str)) return;
    if (upperCase) str = str.toUpperCase();

    if (str === null || str === "") {
      setStr(null);
    } else {
      if (str.length <= maxStr) {
        setStr(str);
      } else {
        postaviFlashPoruku("Za ovaj field maksimalna duljina je " + maxStr, "danger", "tablica-vozila");
      }
    }
  }

  function gumeChange() {
     setTipGuma((prev)=>{
       if (prev === null) {
         return "ljetne";
       } else if (prev === "ljetne") {
         return "zimske"; 
       } else if (prev === "zimske") {
         return "intermediate"
       } else {
         return null;
       }
     }) ;
  }

  function motorChange() {
    setTipMotora((prev)=>{
      if (prev === null) {
        return "benzin";
      } else if (prev === "benzin") {
        return "dizel";
      } else if (prev === "dizel") {
        return "LPG plin";
      } else if (prev === "LPG plin") {
        return "hibridni";
      } else if (prev === "hibridni") {
        return "električni"
      } else {
        return null;
      }

    });
  }

  function modifyKlik(e:React.MouseEvent<HTMLDivElement>) {
    let el = e.currentTarget;
    el.classList.add("klik");
    setTimeout(()=>{el.classList.remove("klik")}, 150);
    
    if (akcija === "modify" && odabranoVoziloID !== "-") setBr1((prev)=>{return prev+1});
    if (akcija === "add") setBr1((prev)=>{return prev+1});
  }

  return (
    <div className="tablica-cont-vozila">
      <table className="tablica"><tbody>
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica-vozila" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv0" className="row">
          <td className="row-el1">ID:</td>
          <td className="row-el2">{ispis(id)}</td>
        </tr>   
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv1" className="row">
          <td className="row-el1">Registracija:</td>
          {swPolje[1] === 0 ?
            <td className="row-el2">{ispis(registracija)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setRegistracija,16,true)}} id="inputv1" name="inputv1" value={ispisId(registracija)}/>
            </td>
          }
        </tr>     
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv2" className="row">
          <td className="row-el1">Proizvodač:</td>
          {swPolje[2] === 0 ?
            <td className="row-el2">{ispis(proizvodac)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setProizvodac,16)}} id="inputv2" name="inputv2" value={ispisId(proizvodac)}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv3" className="row">
          <td className="row-el1">Model:</td>
          {swPolje[3] === 0 ?
            <td className="row-el2">{ispis(model)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setModel,32)}} id="inputv3" name="inputv3" value={ispisId(model)}/>
            </td>
          }
        </tr>    
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv4" className="row">
          <td className="row-el1">Tip motora:</td>
          {swPolje[4] === 0 ?
            <td className="row-el2">{ispis(tipMotora)}</td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); motorChange()}} className="div-button div-button1">{ispis(tipMotora)}</div>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv16" className="row">
          <td className="row-el1">Povijest dodjela:</td>
          {swPolje[16] === 0 ?
            <td className="row-el2">Klikni za pregled</td> :
            <td className="row-el2">
              <Povijest odabranoVoziloID={odabranoVoziloID} vozilaDodjele={vozilaDodjele} 
                      setPodaci={setPodaci} sw={true}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv5" className="row">
          <td className="row-el1">Godina:</td>
          {swPolje[5] === 0 ?
            <td className="row-el2">{ispis(godina)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="inputv5" name="inputv5" value={ispisId(godina)}
                 onChange={(e)=>{unesiStr(e,setGodina,4,false,(s:string)=>{return filterStr(s,false)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv6" className="row">
          <td className="row-el1">Boja vozila:</td>
          {swPolje[6] === 0 ?
            <td className="row-el2">{ispis(bojaVozila)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
                 onChange={(e)=>{unesiStr(e,setBojaVozila,16)}} id="inputv6" name="inputv6" value={ispisId(bojaVozila)}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv7" className="row">
          <td className="row-el1">Datum isteka:</td>
          {swPolje[7] === 0 ?
            <td className="row-el2">{ispis(datumIstekaOsiguranja)}</td> :
            <td className="row-el2">
              <div className="kalendar-cont">
                <Kalendar width={200} dateStr={datumIstekaOsiguranja} 
                 setDateStr={setDatumIstekaOsiguranja} {...otvoriKalendar(datumIstekaOsiguranja)}/>
              </div>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv8" className="row">
          <td className="row-el1">Vlasnik:</td>
          {swPolje[8] === 0 ?
            <td className="row-el2">{ispis(vlasnik)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setVlasnik,32)}} id="inputv8" name="inputv8" value={ispisId(vlasnik)}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv9" className="row">
          <td className="row-el1">Adresa:</td>
          {swPolje[9] === 0 ?
            <td className="row-el2">{ispis(adresa)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setAdresa,32)}} id="inputv9" name="inputv9" value={ispisId(adresa)}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv10" className="row">
          <td className="row-el1">Iznajmljeno vozilo:</td>
          {swPolje[10] === 0 ?
            <td className="row-el2">{ispis(rentSw)}</td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); setRentSw((prev)=>{if (prev === null) return true; return !prev;})}} className="div-button">{ispis(rentSw)}</div>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv11" className="row">
          <td className="row-el1">Tjedna cijena:</td>
          {swPolje[11] === 0 ?
            <td className="row-el2">{ispis(tjednaCijena)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="inputv11" name="inputv11" value={ispisId(tjednaCijena)}
                 onChange={(e)=>{unesiInt(e,setTjednaCijena,10000,(s:string)=>{return filterStr(s,false)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv12" className="row">
          <td className="row-el1">Kilometraža:</td>
          {swPolje[12] === 0 ?
            <td className="row-el2">{ispis(kilometraza)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="inputv12" name="inputv12" 
                 onChange={(e)=>{unesiInt(e, setKilometraza, 3000000,(s:string)=>{return filterStr(s,false)})}} value={ispisId(kilometraza)}/>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv13" className="row">
          <td className="row-el1">Sljedeći servis km:</td>
          {swPolje[13] === 0 ?
            <td className="row-el2">{ispis(sljedeciServis)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="inputv13" name="inputv13" 
                 onChange={(e)=>{unesiInt(e, setSljedeciServis, 3000000, (s:string)=>{return filterStr(s,false)})}} value={ispisId(sljedeciServis)}/>
            </td>
          }
        </tr>


        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv14" className="row">
          <td className="row-el1">Tip guma:</td>
          {swPolje[14] === 0 ?
            <td className="row-el2">{ispis(tipGuma)}</td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); gumeChange()}} className="div-button div-button1">{ispis(tipGuma)}</div>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="rv15" className="row">
          <td className="row-el1">Bilješke:</td>
          {swPolje[15] === 0 ?
            <td className="row-el2">{ispis(biljeska)}</td> :
            <td className="row-el2">
              <textarea rows={8} cols={30} onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setBiljeska,1024)}} id="inputv15" name="inputv15" value={ispisId(biljeska)}/>
            </td>
          }
        </tr>

        <tr className="row neparni"> 
          <td className="row-el2" colSpan={2} >
            {akcija === "modify" ? <div onClick={modifyKlik} className="div-button">Modificiraj</div> :
                    <div onClick={modifyKlik} className="div-button">Dodaj</div>}
          </td>  
        </tr>
      </tbody></table>
    </div>
  )
}
