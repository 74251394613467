import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import './transakcije.scss';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Loading, ispisImena1, novoPolje, ispis, ispisId, filterStr, ukloniPrazneZnakove, ispisSaDecimalama } from "./notFound";
import { ADRESA1, FlashKontekst, Kontekst, StateKontekst } from "./index";
import { useFetch1 } from "./useFetch";
import { Signin, Signout, Gumb, Spinner } from "./login";
import { brDanaOdDatuma, Kalendar, dodajNulu, tranfsormDateStr, otvoriKalendar } from "./kalendar";
import { FlashTip, Flash } from "./flash";
import { Tablica } from './tablica';

type Promet = {
    id: number;
    datum: string;
    provizija: number;
    uber_promet: number | null;
    uber_cash: number | null;
    bolt_promet: number | null;
    bolt_cash: number | null;
}

type TransakcijaTip = {
    id_transakcije: number;
    datum: string;
    tip_transakcije: string;
    iznos: number;
    biljeska: string | null;
}
  
type PodaciTip = {
    id: number;
    name: string;
    ime: string | null;
    prezime: string | null;
    adresa: string | null;
    grad: string | null;
    blokiranSw: "YES" | "NO" | null;
    bankovni_racun: string | null;
    OIB: string | null;
    transakcije: TransakcijaTip[];
}
  
type Tip = "ADD" | "MODIFY" | "SHOW";


export function TransakcijeIzbornik() {
    const [searchStr, setSearchStr] = React.useState("");
    const [searchStr1, setSearchStr1] = React.useState("");
    const [status, setStatus] = React.useState("loading");
    const [signoutSw, setSignoutSw] = React.useState(false);
    const [checkImeSw, setCheckImeSw] = React.useState(true);
    const [checkPrezimeSw, setCheckPrezimeSw] = React.useState(true);
    const [checkBankovniRacunSw, setCheckBankovniRacunSw] = React.useState(false);
    const [checkNickSw, setCheckNickSw] = React.useState(true);
    const [checkTransakcijeSw, setCheckTransakcijeSw] = React.useState(false);
    const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
    const [odabranaTransakcijaID, setOdabranaTransakcijaID] = React.useState("-");
    const [selektorID, setSelektorID] = React.useState("-");
    const [podaci, setPodaci] = React.useState<PodaciTip[]>([]);
    const [br, setBr] = React.useState(0);
    const [br1, setBr1] = React.useState(0);
    const [brRefresh, setBrRefresh] = React.useState(0);
    const [action, setAction] = React.useState("");
    const { kljuc } = React.useContext(Kontekst);
    const [confirmSw, setConfirmSw] = React.useState("");
    const [tip, setTip] = React.useState<Tip>("SHOW");
    const { flashPoruke, setFlashPoruke, postaviFlashPoruku } = React.useContext(FlashKontekst);
    const [pomak, setPomak] = React.useState(0);
    const { odabraniVozacIDTransakcije, setOdabraniVozacIDTransakcije, searchStrTransakcije, setSearchStrTransakcije, 
      searchStr1Transakcije, setSearchStr1Transakcije, checkNickSwTransakcije, setCheckNickSwTransakcije, checkImeSwTransakcije,
      setCheckImeSwTransakcije, checkPrezimeSwTransakcije, setCheckPrezimeSwTransakcije, checkBankovniRacunSwTransakcije, 
      setCheckBankovniRacunSwTransakcije, checkTransakcijeSwTransakcije, setCheckTransakcijeSwTransakcije, odabranaTransakcijaIDTransakcije,
      setOdabranaTransakcijaIDTransakcije, selektorIDTransakcije, setSelektorIDTransakcije } = React.useContext(StateKontekst);
  
    const r = React.useRef<HTMLDivElement | null>(null);
    const r1 = React.useRef<HTMLDivElement | null>(null);
  
    const [loading, error, value] = useFetch1(ADRESA1 + '/api/admin/transakcije', 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": action
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);
  
    React.useEffect(()=>{
      console.log("loading5: " + loading);
      console.log("error5: " + error);
      console.log("value5: ");
      console.log("br: " + br);
      console.log(value);
  
      if (br !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA");
          console.log(value.errorCode);
          setStatus("sign-in");
          setPodaci([]);
        } else if (!loading && !value.error){
          console.log("cini se da je sve ucitano1 " + Math.random());
          console.log(value.value);
          setStatus("logged-in");
          setPodaci(value.value.podaci);
          setBrRefresh((prev)=>{return prev+1;});
        }
      }
    }, [loading]);
  
    React.useEffect(()=>{
      if (action !== "") {
        setBr((prev)=>{return prev+1});
      }
    }, [action, br1]);
  
    React.useEffect(()=>{
      if (!checkTransakcijeSw) {
        setOdabranaTransakcijaID("-");
        setSelektorID("-");
        console.log("transakcija je postavljena na nulu...");
      }
    }, [odabraniVozacID]);

    React.useEffect(()=>{
      console.log("nova odabranaTransakcijaID je " + odabranaTransakcijaID);
    }, [odabranaTransakcijaID]);
  
    React.useEffect(()=>{
      if (kljuc !== "-") setBr1((prev)=>{return prev+1});
    }, [kljuc]);
  
    React.useEffect(()=>{
      setAction("ucitaj");
    }, []);
  
    React.useEffect(()=>{
      if (tip === "SHOW") {
        if (r.current !== null) r.current.style.display = "none";
      } else {
        if (r.current !== null) r.current.style.display = "block";
      }
    }, [tip]);

    React.useEffect(()=>{
      if (status !== "logged-in") {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setPomak(scrollTop);
        if (r1.current !== null)  r1.current.style.position = "fixed";
        if (r1.current !== null)  r1.current.style.top = -1*scrollTop + "px";
      } else {
        if (r1.current !== null)  r1.current.style.top = "0px";
        if (r1.current !== null)  r1.current.style.position = "relative";
        window.scrollTo(0, pomak);
      }
    }, [status]);

    React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
      if (br === 0) {
        setOdabraniVozacID(odabraniVozacIDTransakcije);
        setOdabranaTransakcijaID(odabranaTransakcijaIDTransakcije);
        setSearchStr(searchStrTransakcije);
        setSearchStr1(searchStr1Transakcije);
        setCheckNickSw(checkNickSwTransakcije);
        setCheckImeSw(checkImeSwTransakcije);
        setCheckPrezimeSw(checkPrezimeSwTransakcije);
        setCheckBankovniRacunSw(checkBankovniRacunSwTransakcije);
        setCheckTransakcijeSw(checkTransakcijeSwTransakcije);
        setSelektorID(selektorIDTransakcije);
      } else {
        setOdabraniVozacIDTransakcije(odabraniVozacID);
        setOdabranaTransakcijaIDTransakcije(odabranaTransakcijaID);
        setSearchStrTransakcije(searchStr);
        setSearchStr1Transakcije(searchStr1);
        setCheckNickSwTransakcije(checkNickSw);
        setCheckImeSwTransakcije(checkImeSw);
        setCheckPrezimeSwTransakcije(checkPrezimeSw);
        setCheckBankovniRacunSwTransakcije(checkBankovniRacunSwTransakcije);
        setCheckTransakcijeSwTransakcije(checkTransakcijeSw);
        setSelektorIDTransakcije(selektorID);
      }
    }, [odabraniVozacID, odabranaTransakcijaID, searchStr, searchStr1, checkNickSw, checkImeSw, 
        checkPrezimeSw, checkBankovniRacunSw, checkTransakcijeSw, selektorID]);
  
    function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
      
        let str = e.target.value;
        let str1 = str.toLocaleLowerCase();
        setSearchStr(str1);
      
        let sw = false;
        for (let i = 0; i < podaci.length; i++) {
          let el = podaci[i];
        
          if (ispisImena1(el.name,el.ime,el.prezime,el.bankovni_racun,checkNickSw,checkImeSw,checkPrezimeSw,checkBankovniRacunSw).toLowerCase().includes(str1)) {
            if (el.id.toString() === odabraniVozacID) {
              sw = true;
              break;
            }
          }
        }
        if (!sw) {
          setOdabraniVozacID("-");
        }
      
    }

    function inputFun1(e: React.ChangeEvent<HTMLInputElement>) {
      
      let str = e.target.value;
      let str1 = str.toLocaleLowerCase();
      setSearchStr1(str1);
    
      let sw = false;
      for (let i = 0; i < podaci.length; i++) {
        let el = podaci[i].transakcije;
        for (let j = 0; j < el.length; j++) {
          if ( (tranfsormDateStr(el[j].datum) + " - " + ispisSaDecimalama(el[j].iznos)).toLowerCase().includes(str1) ) {
            sw = true;
            break;
          }
        }
        if (sw) break;  
      }
      if (!sw) {
        setOdabranaTransakcijaID("-");
        setSelektorID("-");
      }
    }
  
    function setCheck(br: number) {
      if (br === 1) {
        if (checkNickSw) {
          if (provjeriChecks())  setCheckNickSw(false);
        } else {
          setCheckNickSw(true);
          setCheckTransakcijeSw(false);
        }
      } else if (br === 2) {
        if (checkImeSw) {
          if (provjeriChecks())  setCheckImeSw(false);
        } else {
          setCheckImeSw(true);
          setCheckTransakcijeSw(false);
        }
      } else if (br === 3) {
        if (checkPrezimeSw) {
          if (provjeriChecks())  setCheckPrezimeSw(false);
        } else {
          setCheckPrezimeSw(true);
          setCheckTransakcijeSw(false);
        }
      } else if (br === 4) {
        if (checkBankovniRacunSw) {
          if (provjeriChecks())  setCheckBankovniRacunSw(false);
        } else {
          setCheckBankovniRacunSw(true);
          setCheckTransakcijeSw(false);
        }
      } else {
        if (checkTransakcijeSw) {
          setCheckTransakcijeSw(false);
          setCheckNickSw(true);
          setCheckImeSw(true);
          setCheckPrezimeSw(true);
          setCheckBankovniRacunSw(false);
          setOdabranaTransakcijaID("-");
          setSelektorID("-");
        } else {
          setCheckTransakcijeSw(true);
          setCheckNickSw(false);
          setCheckImeSw(false);
          setCheckPrezimeSw(false);
          setCheckBankovniRacunSw(false);
          setOdabranaTransakcijaID("-");
          setSelektorID("-");
        }
        
      }
    
      function provjeriChecks() {
        let br = 0;
        if (checkNickSw) br += 1;
        if (checkImeSw) br += 1;
        if (checkPrezimeSw) br += 1;
        if (checkBankovniRacunSw) br += 1;
        if (br === 1) return false;
        return true;
      }
    }
  
    function odabirFun(e: React.ChangeEvent<HTMLSelectElement>, br: number) {
      
        if (br === 0) {
          setOdabraniVozacID(e.currentTarget.value);
        } else {
          if (checkTransakcijeSw) {
            let [val1, val2] = e.currentTarget.value.split("-");
            setOdabraniVozacID(val1);
            setOdabranaTransakcijaID(val2);
            console.log("val1, val2: ");
            console.log(val1);
            console.log(val2);
          } else {
            setOdabranaTransakcijaID(e.currentTarget.value);
          }
          setSelektorID(e.currentTarget.value);
        }
    }
  
    function klik(e:React.MouseEvent<HTMLDivElement>, akcija:string = "") {
      let el = e.currentTarget;
      el.classList.add("klik");
      setTimeout(()=>{el.classList.remove("klik")}, 150);
  
      if (el.id === "gumb-erase") {
        setConfirmSw("delete");
      } else if (el.id === "gumb-add") {
        setTip("ADD");
        setOdabranaTransakcijaID("-");
        setSelektorID("-");
        postaviFlashPoruku("Unesite novu transakciju u tablicu", "success", "tablica-transakcije");
      } else if (el.id === "gumb-modify") {
        setTip("MODIFY");
        postaviFlashPoruku("Unesite modifikacije odabrane transakcije u tablicu", "success", "tablica-transakcije");
      } 
    }

    function vratiPopis(podaci: PodaciTip[], searchString: string = "") {

      if (checkTransakcijeSw) {
        let polje: ReactElement[] = [];
        for (let i = 0; i < podaci.length; i++) {
          let el = podaci[i].transakcije;
          let idVozaca = podaci[i].id;
          for (let j = 0; j < el.length; j++) {
            let ispis = tranfsormDateStr(el[j].datum) + " - " + ispisSaDecimalama(el[j].iznos);
            if (ispis.toLocaleLowerCase().includes(searchString))  polje.push(<option value={idVozaca + "-" + el[j].id_transakcije} key={el[j].id_transakcije}>{ispis}</option>)
          }
        }
        return polje.sort((el1, el2)=>{if (el1.props < el2.props) return -1; if (el1.props > el2.props) return 1; return 0;});
          
      } else {
        return (
          <>
          {podaci.find((el)=>{return el.id.toString() === odabraniVozacID})?.transakcije.sort((el1, el2)=>{if (el1.datum < el2.datum) return 1; if (el1.datum > el2.datum) return -1; return 0;})
                .map((el)=>{return <option value={el.id_transakcije} key={el.id_transakcije}>{tranfsormDateStr(el.datum) + " - " + ispisSaDecimalama(el.iznos)}</option>})}
          </>        
        )
      }
    }
  
    return (
      <div className="holder" ref={r1}>
      {status === "loading" ? <Loading sw={true}/> : <>
        <div className="transakcije-izbornik">
          {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
          <Navbar current={7} status={status} signout={()=>{setSignoutSw(true)}}/> 
          {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
          <div className="prometi-izbornik-lijeva">
            <div className="el">
              <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
                <div className="input-div">
                  <div className="input-div1"><label htmlFor="str">Upisi ime:</label></div>
                  <input onChange={inputFun} value={searchStr} className="input-el" type="text" id="str" name="str" placeholder="upisi substring"/>
                </div> 
              </form>
            </div>
            <div className="el1">
              <div className="checkbox-opcije">
               <div className="checkbox-redak">
                <div className="checkbox-div">
                  <input type="checkbox" checked={checkNickSw} onChange={()=>{setCheck(1)}} 
                     className="checkbox-el" id="checkbox-nick"/>
                  <label className="checkbox-label-el" htmlFor="checkbox-Nick">Username</label>
                </div>
                <div className="checkbox-div">
                  <input type="checkbox" checked={checkImeSw} onChange={()=>{setCheck(2)}}
                     className="checkbox-el" id="checkbox-ime"/>
                  <label className="checkbox-label-el" htmlFor="checkbox-ime">Ime</label>
                </div>
               </div>  
               <div className="checkbox-redak">
                <div className="checkbox-div">
                  <input type="checkbox" checked={checkPrezimeSw} onChange={()=>{setCheck(3)}}
                     className="checkbox-el" id="checkbox-prezime"/>
                  <label className="checkbox-label-el" htmlFor="checkbox-prezime">Prezime</label>
                </div>
                <div className="checkbox-div">
                  <input type="checkbox" checked={checkBankovniRacunSw} onChange={()=>{setCheck(4)}}
                     className="checkbox-el" id="checkbox-bankovni-racun"/>
                  <label className="checkbox-label-el" htmlFor="checkbox-bankovni-racun">Bankovni racun</label>
                </div>
               </div> 
              </div>
              <select className="izbornik" size={5} value={odabraniVozacID} onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{odabirFun(e,0)}} >
                <option value="-">bez odabira</option>
                {podaci.filter((el)=>{let ii = ispisImena1(el.name,el.ime,el.prezime,el.bankovni_racun,checkNickSw,checkImeSw,checkPrezimeSw,checkBankovniRacunSw); return ii !== "" && ii.toLowerCase().includes(searchStr)})
                  .sort((el1,el2)=>{let is1 = ispisImena1(el1.name,el1.ime,el1.prezime,el1.bankovni_racun,checkNickSw,checkImeSw,checkPrezimeSw,checkBankovniRacunSw); let is2 = ispisImena1(el2.name,el2.ime,el2.prezime,el2.bankovni_racun,checkNickSw,checkImeSw,checkPrezimeSw,checkBankovniRacunSw);
                    if (is1 < is2) return -1; if (is1 > is2) return 1; return 0;})
                  .map((el)=>{return <option value={el.id} key={el.id}>{ispisImena1(el.name,el.ime,el.prezime,el.bankovni_racun,checkNickSw,checkImeSw,checkPrezimeSw,checkBankovniRacunSw)}</option>})}
              </select>
            </div>
  
            <div className="el3">
              <div className="checkbox-opcije1">
               <div className="checkbox-redak1">
                <div className="checkbox-div1">
                  <input type="checkbox" checked={checkTransakcijeSw} onChange={()=>{setCheck(5)}} 
                     className="checkbox-el" id="checkbox-transakcije"/>
                  <label className="checkbox-label-el" htmlFor="checkbox-Nick">Pregled transakcija:</label>
                </div>
               </div>  
              </div>
              {checkTransakcijeSw ?
              <div className="el">
                <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
                  <div className="input-div-a">
                    <div className="input-div1-a"><label htmlFor="str">Upisi datum:</label></div>
                    <input onChange={inputFun1} value={searchStr1} className="input-el-a" type="text" id="str" name="str" placeholder="upisi substring"/>
                  </div> 
                </form>
              </div> : null}

              <select className="izbornik" size={5} value={selektorID} onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{odabirFun(e,1)}} >
                <option value="-">bez odabira</option>
                {vratiPopis(podaci, searchStr1)}
              </select>
  
              <div className="el3-gumbi">
                {odabraniVozacID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-add" className="div-button">Add</div> : null}
                {odabranaTransakcijaID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-modify" className="div-button">Modify</div> : null}
                {odabranaTransakcijaID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-erase" className="div-button">Erase</div> : null}
              </div>
            </div>
            <div ref={r} className="antiklik-pokrivac"></div>
          </div>
          <div className="prometi-izbornik-desna">
            <div className="buffer"></div>
            <TablicaTransakcije odabraniVozacID={odabraniVozacID} odabranaTransakcijaID={odabranaTransakcijaID}
              setOdabranaTransakcijaID={setOdabranaTransakcijaID} podaci={podaci} setPodaci={setPodaci} tip={tip} setTip={setTip} />
          </div> 
          {confirmSw === "delete" ? <ConfirmTransakcije br={1} setConfirmSw={setConfirmSw} tekst="Upravo cete OBRISATI odabranu transakciju. Da li ste sigurni?" 
           setTip={setTip} payload={{id_transakcije: odabranaTransakcijaID}} setOdabranaTransakcijaID={setOdabranaTransakcijaID} setPodaci={setPodaci} objasnjenje="DODAJEM transakciju. Pricekajte..."/> : null}

        </div>
        <Footer />
        </>}
      </div>
    )
  }


  type TablicaTransakcijeProps = {
    odabraniVozacID?: string;
    odabranaTransakcijaID?: string;
    setOdabranaTransakcijaID?: React.Dispatch<React.SetStateAction<string>>;
    path?: string;
    podaci?: PodaciTip[];
    setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
    tip?: Tip;
    setTip?: React.Dispatch<React.SetStateAction<Tip>>;
  }
  
  function TablicaTransakcije({odabraniVozacID="-", odabranaTransakcijaID="-", setOdabranaTransakcijaID=()=>{}, podaci=[], 
    setPodaci=()=>{}, path="/api/admin/transakcije", tip="SHOW", setTip=()=>{}}: TablicaTransakcijeProps) {
  
    const [username, setUsername] = React.useState<null | string>(null);
    const [userID, setUserID] = React.useState<null | string>(null);
    const [adresaIGrad, setAdresaIGrad] = React.useState<null | string>(null);
    const [imeIPrezimeVozaca, setImeIPrezimeVozaca] = React.useState<null | string>(null);
    const [bankovniRacun, setBankovniRacun] = React.useState<null | string>(null);
    const [OIB, setOIB] = React.useState<null | string>(null);
    const [datumTransakcije, setDatumTransakcije] = React.useState<null | string>(null);
    const [tipTransakcije, setTipTransakcije] = React.useState<null | string>(null);
    const [iznosTransakcije, setIznosTransakcije] = React.useState<null | string>(null);
    const [biljeska, setBiljeska] = React.useState<null | string>(null);

    const [dateStr, setDateStr] = React.useState<null | string>(null);
    const [brRefresh1, setBrRefresh1] = React.useState(0);
    const [swPolje, setSwPolje] = React.useState(novoPolje(0,13));
    const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
    const [confirmSw, setConfirmSw] = React.useState("");
  
    const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo

    const todayYear = (new Date()).getFullYear();
    const todayMonth = (new Date()).getMonth() + 1;
    const todayDay = (new Date()).getDate();
    const danas = dodajNulu(todayDay) + "." + dodajNulu(todayMonth) + "." + todayYear + ".";
        
    React.useEffect(()=>{
      let el = podaci.find((el)=>{return el.id.toString() === odabraniVozacID});
      if (el === undefined) {
        setUserID(null);
        setUsername(null);
        setAdresaIGrad(null);
        setOIB(null);
        setBankovniRacun(null);
        setImeIPrezimeVozaca(null);
        setDatumTransakcije(null);
        setTipTransakcije(null);
        setIznosTransakcije(null);
        setBiljeska(null);
      } else {
        setUserID(el.id.toString());
        setUsername(el.name);
        setAdresaIGrad(el.adresa);
        setOIB(el.OIB);
        setBankovniRacun(el.bankovni_racun);
        setImeIPrezimeVozaca(spojiStr(el.ime, el.prezime));

        let pp1 = el.transakcije.find((el1)=>{return el1.id_transakcije.toString() === odabranaTransakcijaID});
        if (pp1 !== undefined) {
          setDatumTransakcije(tranfsormDateStr(pp1.datum));
          setTipTransakcije(pp1.tip_transakcije);
          setIznosTransakcije(ispisSaDecimalama(pp1.iznos));
          setBiljeska(pp1.biljeska);
        } else {
          setDatumTransakcije(null);
          setTipTransakcije(null)
          setIznosTransakcije(null);
          setBiljeska(null);
        }           
      }

      if (tip === "ADD") {
        let datString = dodajNulu(todayDay) + "." + dodajNulu(todayMonth) + "." + dodajNulu(todayYear) + ".";
        setDatumTransakcije(datString);
        setDateStr(datString);
      }
      
    }, [odabraniVozacID, odabranaTransakcijaID, brRefresh1]);

    React.useEffect(()=>{
      setBrRefresh1((prev)=>{return prev+1;});
    }, [tip]);

    React.useEffect(()=>{
      if (dateStr !== null) {
        let pp = dateStr.split(".");
        setDatumTransakcije(dodajNulu(parseInt(pp[0])) + "." + dodajNulu(parseInt(pp[1])) + "." + pp[2] + ".");
      }
    }, [dateStr]);

    function spojiStr(str1: string | null, str2: string | null): string | null {
        if (str1 === null) {
          if (str2 === null)  return null;
          return str2;
        } else {
          if (str2 === null) {
            return str1;
          } else {
            return str1 + " " + str2;
          }
        }
    }
  
    function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
      let id = e.currentTarget.id;
      let br = parseInt(id.substring(1));
      if (tip !== "SHOW") {
        if (e.type === "mouseenter") {
          e.currentTarget.classList.add("hover");
        } else if (e.type === "mouseleave") {
          e.currentTarget.classList.remove("hover");
        } else {
          
          rKlik.current = e.currentTarget;
          let b = (swPolje[br] + 1) % 2;
          setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})
  
        }
      }
    }
  
    function format0(br: string): string {
      if (br.length === 1) br = "0" + br;
      return br;
    }
  
    function ispisiImeVozaca(el: PodaciTip | undefined) {
      if (el === undefined)  return "-";
      return ispis(el.ime) + " " + ispis(el.prezime);
    }
  
    function maxS(str: string, len: number): string {
      // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
      if (str.length > len) {
        postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica-prometi");
        return str.substring(0,len);
      }
      return str;
    }
  
    function postaviIznos(e: React.ChangeEvent<HTMLInputElement>, setCallback:React.Dispatch<React.SetStateAction<string | null>>, 
                          kod:number = 0, filterFun: (str:string)=>boolean = ()=>{return true}) {
      let str = e.target.value;
      if (!filterFun(str)) return;
      let sw = false;
  
      if (str.length === 2 && str[0] === "-") {
  
      } else if (str.length === 0) {
        setCallback(null);
        return;
      } else {
        let c = str[str.length-1];
        switch (c) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
          case ".":
            sw = true;
            //setProvizija(str);
        }
      }
  
      if (sw) {
        let br = 0;
        let decSw = false;
        let brDec = 0;
        for (let i = 0; i < str.length; i++) {
          if (decSw) {
            brDec += 1;
          }
          if (str[i] === ".") {
            br += 1;
            decSw = true;
          }
        }
        if (kod === 0) {
          if (br > 1) {
            postaviFlashPoruku("Postotak moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-transakcije");
          } else if (parseFloat(str) < 0 || parseFloat(str) > 100) {
            postaviFlashPoruku("Iznos provizije mora biti u postotcima (izmedu 0 i 100)", "danger", "tablica-transakcije");
          } else {
            setCallback(maxS(str, 7));
          }
        } else if (kod === 1) {
          if (br > 1) {
            postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-transakcije");
          } else if (brDec > 2) {
            postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica-transakcije");  
          } else if (parseFloat(str) < 0 || parseFloat(str) > 100000) {
            postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 100000", "danger", "tablica-transakcije");
          } else {
            setCallback(maxS(str, 8));
          }
        }
      }
    }

    function ispisDatuma(inp: string | null | undefined, danas: string = ""): string {
      //const maxLineLength = 10;
      if (inp === null || inp === undefined) {
          return "-";
      } else {
          if (inp === danas) return "danas(" + inp + ")";
          return inp.toString();
      }
    }
  
    function klikFun(e:React.MouseEvent<HTMLDivElement>) {
      if (e.currentTarget.id === "gumb-back-1") {
        setTip("SHOW");
        setBrRefresh1((prev)=>{return prev+1});
      } else if (e.currentTarget.id === "gumb-modify-1") {
        if (tipTransakcije === "BANK" && bankovniRacun === null) {
          postaviFlashPoruku("Ne mozete provesti bankovnu isplatu bez racuna. Postavite ga prvo na panelu 'Vozaci'.", "danger", "tablica-transakcije");
        } else if (iznosTransakcije === null) {
          postaviFlashPoruku("Niste zadali iznos transakcije.", "danger", "tablica-transakcije");
        } else {
          setConfirmSw("modify");
        }
      } else if (e.currentTarget.id === "gumb-add-1") {
        if (tipTransakcije === null) {
          postaviFlashPoruku("Niste zadali tip transakcije.", "danger", "tablica-transakcije");
        } else if (tipTransakcije === "BANK" && bankovniRacun === null) {
          postaviFlashPoruku("Ne mozete provesti bankovnu isplatu bez racuna. Postavite ga prvo na panelu 'Vozaci'.", "danger", "tablica-transakcije");
        } else if (iznosTransakcije === null) {
          postaviFlashPoruku("Niste zadali iznos transakcije.", "danger", "tablica-transakcije");
        } else {
          setConfirmSw("add");
        }
      }
      setSwPolje((prev)=>{return prev.map((el)=>{return 0})});
    }

    function tipTransakcijeChange() {
      setTipTransakcije((prev)=>{
        if (prev === null) {
          return "BANK";
        } else if (prev === "BANK") {
          return "CASH";
        } else if (prev === "CASH") {
          return "OTHER";
        } else if (prev === "OTHER") {
          return "BANK";
        } else {
          return null;
        }
      });
    }

    function unesiStr(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, 
        setStr: React.Dispatch<React.SetStateAction<string | null>>, maxStr: number = 1024, 
        upperCase: boolean = false) : void {
      
      let str = e.currentTarget.value;
      if (upperCase) str = str.toUpperCase();

      if (str === null || str === "") {
        setStr(null);
      } else {
        if (str.length <= maxStr) {
          setStr(str);
        } else {
          postaviFlashPoruku("Za ovaj field maksimalna duljina je " + maxStr, "danger", "tablica-transakcije");
        }
      }
    }
  
    return (
      <div className="tablica-transakcija-cont">
        <table className="tablica-transakcija"><tbody>
          <div className="flash-container">
            {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica-transakcije" kod2={el.kod} />})}
          </div>
          <tr id="r1" className="row">
            <td className="row-el1">ID transakcije:</td>
            <td className="row-el2">{ispis(odabranaTransakcijaID)}</td> 
          </tr>
          <tr id="r2" className="row">
            <td className="row-el1">Username:</td>
            <td className="row-el2">{username}</td> 
          </tr>
          <tr id="r3" className="row">
            <td className="row-el1">Ime i prezime:</td>
            <td className="row-el2">{imeIPrezimeVozaca}</td> 
          </tr>
          <tr id="r4" className="row">
            <td className="row-el1">Adresa i grad:</td>
            <td className="row-el2">{adresaIGrad}</td> 
          </tr>
          <tr id="r5" className="row">
            <td className="row-el1">BankovniRacun:</td>
            <td className="row-el2">{bankovniRacun}</td> 
          </tr>
          <tr id="r6" className="row">
            <td className="row-el1">OIB:</td>
            <td className="row-el2">{OIB}</td> 
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r7" className="row">
          <td className="row-el1">Datum transakcije:</td>
          {swPolje[7] === 0 ?
            <td className="row-el2">{ispisDatuma(datumTransakcije, danas)}</td> :
            <td className="row-el2">
              <div className="kalendar-cont">
                <Kalendar width={200} dateStr={dateStr} 
                 setDateStr={setDateStr} {...otvoriKalendar(datumTransakcije)}/>
              </div>
            </td>
          }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r8" className="row">
          <td className="row-el1">Tip transakcije:</td>
          {swPolje[8] === 0 ?
            <td className="row-el2">{ispis(tipTransakcije)}</td> :
            <td className="row-el2">
              <div onClick={(e)=>{e.stopPropagation(); tipTransakcijeChange()}} className="div-button div-button1">{ispis(tipTransakcije)}</div>
            </td>
          }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r9" className="row">
          <td className="row-el1">Iznos:</td>
          {swPolje[9] === 0  ?
            <td className="row-el2">{ispis(iznosTransakcije)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input1" name="input1" value={ispisId(iznosTransakcije)}
                 onChange={(e)=>{postaviIznos(e,setIznosTransakcije,1,(str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r10" className="row">
          <td className="row-el1">Bilješke:</td>
          {swPolje[10] === 0 ?
            <td className="row-el2">{ispis(biljeska)}</td> :
            <td className="row-el2">
              <textarea rows={8} cols={30} onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{unesiStr(e,setBiljeska,512)}} id="inputv10" name="inputv10" value={ispisId(biljeska)}/>
            </td>
          }
          </tr>
          
          {tip !== "SHOW" ?
            <tr className="row"> 
              <td className="row-el2" colSpan={2} >
                {tip === "MODIFY" ?
                  <div className="gumbi-1">
                    <div onClick={klikFun} id="gumb-back-1" className="div-button-1">Back</div>
                    <div onClick={klikFun} id="gumb-modify-1" className="div-button-1">Modify</div>
                  </div> : null
                }
                {tip === "ADD" ?
                  <div className="gumbi-1">
                    <div onClick={klikFun} id="gumb-back-1" className="div-button-1">Back</div>
                    <div onClick={klikFun} id="gumb-add-1" className="div-button-1">Add</div>
                  </div> : null
                }
              </td>  
            </tr> : null}
        </tbody></table>
        {confirmSw === "add" ? <ConfirmTransakcije br={3} setConfirmSw={setConfirmSw} tekst="Upravo cete DODATI transakciju odabranom vozacu. Da li ste sigurni?" 
           setTip={setTip} payload={{"id_vozaca":odabraniVozacID, "datum": tranfsormDateStr(datumTransakcije), "tip_transakcije": tipTransakcije, "iznos": iznosTransakcije, 
            "biljeska":biljeska}} setOdabranaTransakcijaID={setOdabranaTransakcijaID} setPodaci={setPodaci} objasnjenje="DODAJEM transakciju. Pricekajte..."/> : null}
        {confirmSw === "modify" ? <ConfirmTransakcije br={2} setConfirmSw={setConfirmSw} tekst="Upravo cete MODIFICIRATI odabranu transakciju. Da li ste sigurni?" 
           setTip={setTip} payload={{"datum": tranfsormDateStr(datumTransakcije), "tip_transakcije": tipTransakcije, "iznos": iznosTransakcije, 
            "biljeska":biljeska,"id_transakcije":odabranaTransakcijaID}} setOdabranaTransakcijaID={setOdabranaTransakcijaID} setPodaci={setPodaci} objasnjenje="DODAJEM transakciju. Pricekajte..."/> : null}
      </div>
    )
  }

  type ConfirmTransakcijeProps = {
    setConfirmSw?: React.Dispatch<React.SetStateAction<string>>;
    br?: number;
    tekst?: string;
    objasnjenje?: string;
    payload?: {[index:string]: any};
    path?: string;
    setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
    setOdabranaTransakcijaID: React.Dispatch<React.SetStateAction<string>>;
    setTip?: React.Dispatch<React.SetStateAction<Tip>>;
  }

function ConfirmTransakcije({setConfirmSw=()=>{}, br=1, tekst="neko pitanje...", 
  objasnjenje="neko objasnjenje akcije...", payload={"id":""}, setPodaci=()=>{}, 
  setOdabranaTransakcijaID=()=>{}, setTip=()=>{}, path="/api/admin/transakcije"}: ConfirmTransakcijeProps) {

  const [sw, setSw] = React.useState(false);
  const [akcija, setAkcija] = React.useState("");
  const [id, setId] = React.useState("");
  const [idVozaca, setIdVozaca] = React.useState<null | string>(null);
  const [idTransakcije, setIdTransakcije] = React.useState<null | string>(null);
  const [datum, setDatum] = React.useState<null | string>(null);
  const [tipTransakcije, setTipTransakcije] = React.useState<null | string>(null);
  const [biljeska, setBiljeska] = React.useState<null | string>(null);
  const [iznos, setIznos] = React.useState<null | string>(null);

  const {kljuc} = React.useContext(Kontekst);
  const [br1, setBr1] = React.useState(0);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": akcija,
      "id_vozaca": idVozaca, 
      "id_transakcije": idTransakcije,
      "datum": datum,
      "iznos": iznos,
      "tip_transakcije": tipTransakcije,
      "biljeska": biljeska
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br1]);

  React.useEffect(()=>{
    console.log("loading6: " + loading);
    console.log("error6: " + error);
    console.log("value6: ");
    console.log("br: " + br1);
    console.log(value);

    if (br1 !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        postaviFlashPoruku(value.errorCode, "danger", "confirm");
        setTip("SHOW");
        setConfirmSw("");
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        console.log("akcija je " + akcija);
        setConfirmSw("");

        if (akcija === "add") {
          setPodaci(value.value.podaci);
          setOdabranaTransakcijaID(value.value.idNovi);
          setTip("SHOW");
          postaviFlashPoruku("Transakcija je uspijesno zapisana.", "success", "tablica-transakcije");
        } else if (akcija === "delete") {
          setPodaci(value.value.podaci);
          setOdabranaTransakcijaID("-");
          setTip("SHOW");
          postaviFlashPoruku("Transakcija je uspijesno obrisana.", "success", "tablica-transakcije");
        } else if (akcija === "modify") {
          setPodaci(value.value.podaci);
          setTip("SHOW");
          postaviFlashPoruku("Transakcija je uspijesno modificirana.", "success", "tablica-transakcije");
        }
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (br === 1) {
      setAkcija("delete");
      if (payload.id_transakcije !== undefined)  setIdTransakcije(payload.id_transakcije);
    } else if (br === 2) {
      setAkcija("modify");
      if (payload.datum !== undefined)  setDatum(payload.datum);
      if (payload.tip_transakcije !== undefined)  setTipTransakcije(payload.tip_transakcije);
      if (payload.iznos !== undefined)  setIznos(payload.iznos);
      if (payload.biljeska !== undefined)  setBiljeska(payload.biljeska);
      if (payload.id_transakcije !== undefined)  setIdTransakcije(payload.id_transakcije);
    } else if (br === 3) {
      setAkcija("add");
      if (payload.datum !== undefined)  setDatum(payload.datum);
      if (payload.id_vozaca !== undefined)  setIdVozaca(payload.id_vozaca);
      if (payload.tip_transakcije !== undefined)  setTipTransakcije(payload.tip_transakcije);
      if (payload.iznos !== undefined)  setIznos(payload.iznos);
      if (payload.biljeska !== undefined)  setBiljeska(payload.biljeska);
    }
  }, []);

  function fun(str: string) {
    if (str === "no") {
      setConfirmSw("");
    } else if (br === 1) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 2) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 3) {
      setBr1((prev)=>{return prev+1});
    }
  }

  return (
    <div className="popup-plast-confirm-transakcije">
      <div className="popup-confirm-transakcije">
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="confirm" kod2={el.kod} />})}
        </div>
        {loading ? <>
          <div className="naslov">{objasnjenje}</div>
          <Spinner/>
        </> :
           <>
             <div className="naslov">{tekst}</div>
             <div className="gumbi">
               <Gumb fun={()=>{fun("no")}} tekst="No" height="30px" width="60px" />
               <Gumb fun={()=>{fun("yes")}} tekst="Yes" height="30px" width="60px" />
             </div>
           </>}
      </div>
    </div>
  )
}