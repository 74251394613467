import React from 'react';
import ReactDOM from 'react-dom/client';
import './alias.scss';
import { useFetch1 } from "./useFetch";
import { ADRESA1, FlashKontekst, Kontekst } from "./index";
import { Gumb, Spinner } from "./login";
import { ukloniPrazneZnakoveNull } from "./notFound";

type AliasManagerProps = {
  odabraniVozacID?: string;
  aliases?: {[index:string]: any}[];
  setAliases?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>;
}

export function AliasManager({odabraniVozacID="-", aliases=[], setAliases=()=>{}}: AliasManagerProps) {
  const [odabraniAliasID, setOdabraniAliasID] = React.useState("-");
  const [aliasStr, setAliasStr] = React.useState("");
  const [checkUberSw, setCheckUberSw] = React.useState(false);
  const [checkBoltSw, setCheckBoltSw] = React.useState(false);
  const [confirmSw, setConfirmSw] = React.useState("");
  const {postaviFlashPoruku} = React.useContext(FlashKontekst);

  React.useEffect(()=>{
    console.log("ZA aliases: odabraniVozacID: " + odabraniVozacID);
    console.log(aliases);
    console.log("kraj " + Math.random());
  }, [odabraniVozacID, aliases]);

  React.useEffect(()=>{
    if (confirmSw === "")  setAliasStr("");
  }, [confirmSw]);
  
  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
    setOdabraniAliasID(e.currentTarget.value);
  }

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    setAliasStr(str);
  }

  function setCheck(br: number) {
    if (br === 1) {
      if (checkUberSw) {
        //if (checkBoltSw)  setCheckUberSw(false);
        setCheckUberSw(false);
      } else {
        setCheckUberSw(true);
      }
    } else {
      if (checkBoltSw) {
        //if (checkUberSw)  setCheckBoltSw(false);
        setCheckBoltSw(false);
      } else {
        setCheckBoltSw(true);
      }
    }
  }

  function uberBoltFilter(el: {[index: string]: any;}, uberSw: boolean, boltSw: boolean): boolean {
    if (el.uberSw !== undefined || el.boltSw !== undefined) {
      if (uberSw && el.uberSw === null)  return false;
      //if (!uberSw && el.uberSw)  return false;
      if (boltSw && el.boltSw === null)  return false;
      //if (!boltSw && el.boltSw)  return false;
      return true;
    }
    return false;
  }

  function klikFun(e:React.MouseEvent<HTMLDivElement>) {
    if (e.currentTarget.id === "gumb-alias-erase") {
      setConfirmSw("erase");
    } else if (e.currentTarget.id === "gumb-alias-add") {
      if (!checkUberSw && !checkBoltSw) {
        postaviFlashPoruku("Morate preko checkboxova specificirati barem jednu platformu za novi alias.", "danger", "tablica");
      } else {
        let st = ukloniPrazneZnakoveNull(aliasStr);
        let pp = aliases.find((el)=>{return (el.alias === st && odabraniVozacID === el.id_vozaca?.toString())});
        if (pp !== undefined) {
          postaviFlashPoruku("Odabrani vozac vec ima ovaj alias na Uberu i/ili Boltu. Prvo ga obrisite, pa zatim ga ponovo unesite na zeljenoj kombinaciji platformi.", "danger", "tablica");
          return;
        }
        pp = aliases.find((el)=>{ return (el.alias === st && odabraniVozacID !== el.id_vozaca?.toString() &&   
          ( (checkUberSw === true && checkUberSw === el.uberSw) || (checkBoltSw === true && checkBoltSw === el.boltSw) )   )});
        if (pp !== undefined) {
          postaviFlashPoruku("Upisani alias je vec zauzet na odabranoj platformi. Odaberite drugi ili obrisite dosadasnjeg.", "danger", "tablica");
          return;
        }
        setConfirmSw("add");
      }
    }
  }

  return (
    <div className="alias-manager">
      <select className="izbornik" size={5} value={odabraniAliasID} onClick={(e)=>{e.stopPropagation()}} 
               onChange={odabirFun} >
        <option value="-">bez odabira</option>
        {aliases.filter((el)=>{return el.id_vozaca.toString() === odabraniVozacID  && uberBoltFilter(el, checkUberSw, checkBoltSw)})
            .map((el)=>{return <option value={el.id} key={el.id}>{el.alias}</option>})}
      </select>
      <div className="alias-el">
        <div className="checkbox-opcije">
          <div className="checkbox-div" onClick={(e)=>{e.stopPropagation()}}>
            <input type="checkbox" checked={checkUberSw} onChange={()=>{setCheck(1)}} 
               className="checkbox-el" id="checkbox-uber"/>
            <label className="checkbox-label-el" htmlFor="checkbox-uber">Uber</label>
          </div>
          <div className="checkbox-div" onClick={(e)=>{e.stopPropagation()}}>
            <input type="checkbox" checked={checkBoltSw} onChange={()=>{setCheck(2)}}
               className="checkbox-el" id="checkbox-bolt"/>
            <label className="checkbox-label-el" htmlFor="checkbox-bolt">Bolt</label>
          </div>
        </div>
        <input onChange={inputFun} value={aliasStr} className="input-alias" type="text" 
           onClick={(e)=>{e.stopPropagation()}} id="alias-str" name="alias-str" placeholder="upisi vozacev novi alias"/>
      </div>
      <div className="alias-gumbi">
        {odabraniAliasID !== "-" ? <div onClick={(e)=>{e.stopPropagation(); klikFun(e)}} id="gumb-alias-erase" className="div-alias-button">Erase</div> : null}
        <div onClick={(e)=>{e.stopPropagation(); klikFun(e)}} id="gumb-alias-add" className="div-alias-button">New</div>
      </div>
      {confirmSw === "erase" ? <ConfirmAlias aliases={aliases} setAliases={setAliases} br={1} setConfirmSw={setConfirmSw} tekst="Upravo cete OBRISATI odabrani alias. Da li ste sigurni?" 
            odabraniAliasID={odabraniAliasID} setOdabraniAliasID={setOdabraniAliasID} 
            objasnjenje="BRISEM odabrani alias. Pricekajte..."/> : null}
      {confirmSw === "add" ? <ConfirmAlias aliases={aliases} setAliases={setAliases} br={2} setConfirmSw={setConfirmSw} tekst="Upravo cete DODATI upisani alias. Da li ste sigurni?" 
            payload={{"idVozaca": odabraniVozacID, "alias": ukloniPrazneZnakoveNull(aliasStr),
                      "uberSw": checkUberSw, "boltSw": checkBoltSw}} odabraniAliasID={odabraniAliasID} setOdabraniAliasID={setOdabraniAliasID} 
            objasnjenje="DODAJEM odabrani alias. Pricekajte..."/> : null}
    </div>
  )
}

type ConfirmAliasProps = {
  setConfirmSw?: React.Dispatch<React.SetStateAction<string>>;
  br?: number;
  tekst?: string;
  objasnjenje?: string;
  path?:string;
  payload?: {[index:string]: any};
  odabraniAliasID?: string;
  setOdabraniAliasID?: React.Dispatch<React.SetStateAction<string>>;
  aliases?: {[index: string]: any;}[];
  setAliases?: React.Dispatch<React.SetStateAction<{[index: string]: any;}[]>>
}

function ConfirmAlias({setConfirmSw=()=>{}, br=1, aliases=[], setAliases=()=>{}, 
       tekst="neko pitanje...", objasnjenje="neko objasnjenje akcije...", 
       odabraniAliasID="-", setOdabraniAliasID=()=>{}, payload={"id":""}, path="/api/admin/alias"}: ConfirmAliasProps) {
  const [br1, setBr1] = React.useState(0);
  //const [id, setId] = React.useState("");
  const [akcija, setAkcija] = React.useState("");
  const {kljuc} = React.useContext(Kontekst);
  const [uberSw, setUberSw] = React.useState<null | true>(null);  // nul ide u bazu podataka umjesto false, zbog multicolumn indexa
  const [boltSw, setBoltSw] = React.useState<null | true>(null);
  const [idVozaca, setIdVozaca] = React.useState("-");
  const [alias, setAlias] = React.useState("");

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "id": odabraniAliasID,
        "id_vozaca": idVozaca,
        "alias": alias,
        "uberSw": uberSw,
        "boltSw": boltSw
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br1]);

    React.useEffect(()=>{
  
      if (br1 !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA");
          console.log(value.errorCode);
          //postaviFlashPoruku(value.errorCode, "danger", "confirm");
          setConfirmSw("");
        } else if (!loading && !value.error){
          console.log("cini se da je sve ucitano");
          console.log(value.value);
          setConfirmSw("");
          setAliases(value.value.aliases);
          if (akcija === "delete")  setOdabraniAliasID("-");
          if (akcija === "add") {
            setOdabraniAliasID(value.value.id);
          }

        }
      }
    }, [loading]);
  
    React.useEffect(()=>{
      if (br === 1) {
        setAkcija("delete");
        //if (payload.id !== undefined)  setId(payload.id);
      } else if (br === 2) {
        setAkcija("add");
        if (payload.idVozaca !== undefined)  setIdVozaca(payload.idVozaca);
        if (payload.alias !== undefined)  setAlias(payload.alias);
        if (payload.uberSw !== undefined && payload.uberSw) {
          setUberSw(true);
        } else {
          setUberSw(null);
        }
        if (payload.boltSw !== undefined && payload.boltSw) {
          setBoltSw(true);
        } else {
          setBoltSw(null);
        }

      } 
    }, []);
  
  function fun(str: string) {
    if (str === "no") {
      setConfirmSw("");
    } else if (br === 1) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 2) {
      setBr1((prev)=>{return prev+1});
    } 
  }

  return (
    <div className="popup-plast-confirm-alias" onClick={(e)=>{e.stopPropagation()}}>
      <div className="popup-confirm-alias">
        {loading ? <>
          <div className="naslov">{objasnjenje}</div>
          <Spinner/>
        </> :
           <>
             <div className="naslov">{tekst}</div>
             <div className="gumbi">
               <Gumb fun={()=>{fun("no")}} tekst="No" height="30px" width="60px" />
               <Gumb fun={()=>{fun("yes")}} tekst="Yes" height="30px" width="60px" />
             </div>
           </>}
      </div>
    </div>
  )
}