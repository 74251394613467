import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { useFetch1 } from "./useFetch";
import { Signin, Signup, Signout, PhoneVerification, ActivationObavijest } from "./login";
import { FlashTip, Flash } from "./flash";
import { Loading, ukloniPrazneZnakove, ukloniPrazneZnakoveNull, unosBooleana, booleanToString, emptyIsNull, ukloniNull, 
  ConfirmChoice, novoPolje, nullToDoDaljnjeg, ispis, ispis1, ispisId, ispisSaDecimalama, ispisImena } from "./notFound";
import './obracun.scss';
import {Kontekst, FlashKontekst, StateKontekst, ADRESA1} from "./index";
import { brDanaOdPocetkaGodine, brDanaOd2000, Kalendar, brDanaOdDatumaStr, tranfsormDateStr, dodajNulu, otvoriKalendar } from "./kalendar";

type VozacType = {
    br: number;
}

type PodaciVozacTip = {
  id: number;
  name: string;
  ime: string;
  prezime: string;
  uberSw: "YES" | "NO" | null;
  boltSw: "YES" | "NO" | null;
}

type PodaciVozilaTip = {
  id: number;
  id_vozila: number;
  registracija: string;
  proizvodac: string;
  model: string;
  pocetak: string;
  kraj: string | null;
  rentSw: "YES" | "NO" | null;
  tjedna_cijena: number;
}

type PodaciPrometiTip = {
  id: number;
  datum: string;
  tip_provizije: "postotak" | "fiksni" | "provizija";
  provizija: number;
  uber_promet: null | number;
  uber_cash: null | number;
  bolt_promet: null | number;
  bolt_cash: null | number;
}

type PodaciDoprinosiTip = {
  id: number;
  pocetak: string;
  kraj: string | null;
  mjesecni_doprinos: number;
  dnevni_broj_sati: number;
}

type PodaciTransakcijeTip = {
  id: number;
  datum: string;
  tip_transakcije: string;
  iznos: number;
  biljeska: string | null;
}

export function Obracun({br=0}: VozacType) {
    const {lang, setLang, kljuc, setKljuc} = React.useContext(Kontekst);
    const [br1, setBr1] = React.useState(0);
    const [status, setStatus] = React.useState("loading");
    const [akcija, setAkcija] = React.useState("ucitaj");
    const [signoutSw, setSignoutSw] = React.useState(false);
    const [podaciVozac, setPodaciVozac] = React.useState<PodaciVozacTip>({} as PodaciVozacTip);
    const [podaciVozila, setPodaciVozila] = React.useState<PodaciVozilaTip[]>([]);
    const [podaciPrometi, setPodaciPrometi] = React.useState<PodaciPrometiTip[]>([]);
    const [podaciDoprinosi, setPodaciDoprinosi] = React.useState<PodaciDoprinosiTip[]>([]);
    const [podaciTransakcije, setPodaciTransakcije] = React.useState<PodaciTransakcijeTip[]>([]);
    const [pomak, setPomak] = React.useState(0);
    const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

    const r = React.useRef<HTMLDivElement | null>(null);
  
    const [loading, error, value] = useFetch1(ADRESA1+"/api/obracun", 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br1]);
  
    React.useEffect(()=>{
      console.log("loading2: " + loading);
      console.log("error2: " + error);
      console.log("value2: ");
      console.log(value);
  
      if (value !== undefined && typeof value !== "boolean") {
        
        if (value.error) {
          if (value.errorCode === "telefon nije verificiran") {
            setStatus("verify");
          } else if (value.errorCode === "Racun nije aktivan. Kontaktirajte administratora") {
            console.log(value.errorCode);
            postaviFlashPoruku(value.errorCode, "danger", "signin");
            setStatus("activate");
          } else {
            console.log("niste ulogirani");
            console.log(value.errorCode);
            setStatus("sign-in");
          }
        } else if (!loading && !value.error){
          console.log("ULOGIRANI ste");
          console.log(value.errorCode);
          setStatus("logged-in");
          setPodaciVozac(value.value.podaci);
          setPodaciVozila(value.value.vozila);
          setPodaciPrometi(value.value.prometi);
          setPodaciDoprinosi(value.value.doprinosi);
          setPodaciTransakcije(value.value.transakcije);
        }
      }
    }, [loading]);
  
    React.useEffect(()=>{
      if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
    }, [kljuc]);

    React.useEffect(()=>{
      if (status !== "logged-in") {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setPomak(scrollTop);
        if (r.current !== null)  r.current.style.position = "fixed";
        if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
      } else {
        if (r.current !== null)  r.current.style.top = "0px";
        if (r.current !== null)  r.current.style.position = "relative";
        window.scrollTo(0, pomak);
      }
    }, [status]);

    React.useEffect(()=>{
      console.log("Podci o vozacu");
      console.log(podaciVozac);
      console.log(podaciVozila);
      console.log(podaciPrometi);
      console.log(podaciDoprinosi);
      console.log(podaciTransakcije);
    }, [podaciVozac, podaciVozila, podaciPrometi, podaciDoprinosi]);

    const showSw = [false,true,true,true,true,true,true,true,true,true,true,true,false,true,true,true,false,false];
  
    return (
      <div className="holder" ref={r}>
      <div className="okvir">
        {status === "loading" ? <Loading sw={true}/> : <>
          {status === "sign-in" ? <Signin setStatus={setStatus}/> : null}
          {status === "sign-up" ? <Signup setStatus={setStatus}/> : null}
          {status === "verify" ? <PhoneVerification setStatus={setStatus}/> : null}
          {status === "activate" ? <ActivationObavijest setStatus={setStatus}/> : null}
          {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
          <Navbar vozacSw={true} current={5} status={status} signout={()=>{setSignoutSw(true)}}/>
          <h2 className="naslov-obracun">Obracun</h2>
          <TablicaObracun podaciVozac={podaciVozac} setPodaciVozac={setPodaciVozac} podaciTransakcije={podaciTransakcije}
             podaciPrometi={podaciPrometi} podaciVozila={podaciVozila} podaciDoprinosi={podaciDoprinosi} />
          <div className="buffer"></div>            
        </>}
      </div>
      <Footer />
      </div>
    )
  }

  function redosljedDatuma(dat1: string | null, dat2: string | null): boolean {
    if (dat1 === null) return false;
    if (dat2 === null) return true;  // do daljnjeg;

    let pp1= dat1.split(".");
    let god1 = parseInt(pp1[2]);
    let mon1 = parseInt(pp1[1]);
    let day1 = parseInt(pp1[0]);
    let br1 = brDanaOd2000(god1) + brDanaOdPocetkaGodine(god1,mon1,day1);

    let pp2= dat2.split(".");
    let god2 = parseInt(pp2[2]);
    let mon2 = parseInt(pp2[1]);
    let day2 = parseInt(pp2[0]);
    let br2 = brDanaOd2000(god2) + brDanaOdPocetkaGodine(god2,mon2,day2);

    if (br2 > br1) return true;
    return false;
  }

  function dodajNule(str: string | null): string | null {
    if (str === null) return null;
    let pp = str.split(".");
    return dodajNulu(parseInt(pp[0])) + "." + dodajNulu(parseInt(pp[1])) + "." + pp[2];
  }

  function preklapanjeFun(poc1: string, poc2: string | null, poc3: string | null, poc4: string | null): number {

    if (poc3 === null) return -2;
    let pp1= poc1.split("-");
    if (poc1.includes(".")) {
      pp1 = poc1.split(".");
      pp1 = [pp1[2], pp1[1], pp1[0]];
    }
    let god1 = parseInt(pp1[0]);
    let mon1 = parseInt(pp1[1]);
    let day1 = parseInt(pp1[2]);
    let br1 = brDanaOd2000(god1) + brDanaOdPocetkaGodine(god1,mon1,day1);

    let pp3= poc3.split("-");
    if (poc3.includes(".")) {
      pp3 = poc3.split(".");
      pp3 = [pp3[2], pp3[1], pp3[0]];
    }
    let god3 = parseInt(pp3[0]);
    let mon3 = parseInt(pp3[1]);
    let day3 = parseInt(pp3[2]);
    let br3 = brDanaOd2000(god3) + brDanaOdPocetkaGodine(god3,mon3,day3);

    if (poc2 === null) {
      if (poc4 === null) {
        return -3;
      } else {
        let pp4= poc4.split("-");
        if (poc4.includes(".")) {
          pp4 = poc4.split(".");
          pp4 = [pp4[2], pp4[1], pp4[0]];
        }
        let god4 = parseInt(pp4[0]);
        let mon4 = parseInt(pp4[1]);
        let day4 = parseInt(pp4[2]);
        let br4 = brDanaOd2000(god4) + brDanaOdPocetkaGodine(god4,mon4,day4);
        if (br1 <= br3) {
          return br4 - br3;
        } else if (br1 < br4) {
          return br4 - br1;
        } else {
          return -1;
        }
      }
    } else {
      let pp2= poc2.split("-");
      if (poc2.includes(".")) {
        pp2 = poc2.split(".");
        pp2 = [pp2[2], pp2[1], pp2[0]];
      }
      let god2 = parseInt(pp2[0]);
      let mon2 = parseInt(pp2[1]);
      let day2 = parseInt(pp2[2]);
      let br2 = brDanaOd2000(god2) + brDanaOdPocetkaGodine(god2,mon2,day2);

      if (poc4 === null) {
        if (br1 >= br3) {
          return br2 - br1;
        } else {
          if (br2 <= br3) {
            return -1;
          } else {
            return br2 - br3;
          }
        }
      } else {
        let pp4= poc4.split("-");
        if (poc4.includes(".")) {
          pp4 = poc4.split(".");
          pp4 = [pp4[2], pp4[1], pp4[0]];
        }
        let god4 = parseInt(pp4[0]);
        let mon4 = parseInt(pp4[1]);
        let day4 = parseInt(pp4[2]);
        let br4 = brDanaOd2000(god4) + brDanaOdPocetkaGodine(god4,mon4,day4);

        if (br1 >= br4) {
          return -1;
        } else if (br1 >= br3) {
          if (br2 <= br4) {
            return br2 - br1;
          } else {
            return br4 - br1;
          }
        } else {
          if (br2 <= br3) {
            return -1;
          } else if (br2 <= br4) {
            return br2 - br3;
          } else {
            return br4 - br3;
          }
        }
      }
    }

    return -1;
  }

  type TablicaObracunTip = {
    prekidaci?: boolean[];
    showSw?: boolean[];
    podaciVozac?: PodaciVozacTip;
    setPodaciVozac?: React.Dispatch<React.SetStateAction<PodaciVozacTip>>;
    podaciPrometi?: PodaciPrometiTip[];
    podaciVozila?: PodaciVozilaTip[];
    podaciDoprinosi?: PodaciDoprinosiTip[];
    podaciTransakcije?: PodaciTransakcijeTip[];
    resetBr?: number;
  }

  function TablicaObracun({prekidaci=novoPolje(true,10), showSw=novoPolje(true,10), podaciVozac={} as PodaciVozacTip, 
      setPodaciVozac=()=>{}, podaciPrometi=[], podaciVozila=[], podaciDoprinosi=[], podaciTransakcije=[], resetBr=0}: TablicaObracunTip) {
    const [swPolje, setSwPolje] = React.useState(novoPolje(0,10));
    const [userName, setUserName] = React.useState<null | string>(null);
    const [ime, setIme] = React.useState<null | string>(null);
    const [prezime, setPrezime] = React.useState<null | string>(null);
    const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
    const [pocetniDatum, setPocetniDatum] = React.useState<null | string>(null);
    const [zavrsniDatum, setZavrsniDatum] = React.useState<null | string>(null);
    const [prevPocetniDatum, setPrevPocetniDatum] = React.useState<null | string>(null);
    const [prevZavrsniDatum, setPrevZavrsniDatum] = React.useState<null | string>(null);
    const [odabraniPrometID, setOdabraniPrometID] = React.useState("-");
    const [odabranoVoziloID, setOdabranoVoziloID] = React.useState("-");
    const [odabraniDoprinosID, setOdabraniDoprinosID] = React.useState("-");
    const [odabranaTransakcijaID, setOdabranaTransakcijaID] = React.useState("-");
    const [uberPromet, setUberPromet] = React.useState<null | number>(null);
    const [uberCash, setUberCash] = React.useState<null | number>(null);
    const [boltPromet, setBoltPromet] = React.useState<null | number>(null);
    const [boltCash, setBoltCash] = React.useState<null | number>(null);
    const [tipProvizije, setTipProvizije] = React.useState<null | "postotak" | "provizija" | "fiksni">(null);
    const [provizija, setProvizija] = React.useState<null | number>(null);
    const [iznosProvizije, setIznosProvizije] = React.useState<null | number>(null);
    const [ukupniUberPromet, setUkupniUberPromet] = React.useState<null | number>(null);
    const [ukupniUberCash, setUkupniUberCash] = React.useState<null | number>(null);
    const [ukupniBoltPromet, setUkupniBoltPromet] = React.useState<null | number>(null);
    const [ukupniBoltCash, setUkupniBoltCash] = React.useState<null | number>(null);
    const [ukupnaProvizija, setUkupnaProvizija] = React.useState<null | number>(null);
    const [ukupanIznosProvizije, setUkupanIznosProvizije] = React.useState<null | number>(null);

    const [ukupniUberPrometPostotak, setUkupniUberPrometPostotak] = React.useState<null | number>(null);
    const [ukupniUberCashPostotak, setUkupniUberCashPostotak] = React.useState<null | number>(null);
    const [ukupniBoltPrometPostotak, setUkupniBoltPrometPostotak] = React.useState<null | number>(null);
    const [ukupniBoltCashPostotak, setUkupniBoltCashPostotak] = React.useState<null | number>(null);
    const [ukupniPostotak, setUkupniPostotak] = React.useState<null | number>(null);
    const [ukupanIznosPostotka, setUkupanIznosPostotka] = React.useState<null | number>(null);

    const [ukupanIznosTransakcija, setUkupanIznosTransakcija] = React.useState<null | number>(null);
    const [checkRegSw, setCheckRegSw] = React.useState(true);
    const [provizijaSw, setProvizijaSw] = React.useState(false);
    const [postotakSw, setPostotakSw] = React.useState(false);
    const [checkModelSw, setCheckModelSw] = React.useState(true);

    const [tjednaCijena, setTjednaCijena] = React.useState<null | number>(null);
    const [brojDanaVozila, setBrojDanaVozila] = React.useState<null | number | string>(null);
    const [trosakVozila, setTrosakVozila] = React.useState<null | number | string>(null);

    const [ukupnaTjednaCijena, setUkupnaTjednaCijena] = React.useState<null | number>(null);
    const [ukupniBrojDanaVozila, setUkupniBrojDanaVozila] = React.useState<null | number | string>(null);
    const [ukupniTrosakVozila, setUkupniTrosakVozila] = React.useState<null | number | string>(null);

    const [mjesecniDoprinos, setMjesecniDoprinos] = React.useState<null | number>(null);
    const [brojDnevnihSati, setBrojDnevnihSati] = React.useState<null | number>(null);
    const [brojDana, setBrojDana] = React.useState<null | number | string>(null);
    const [trosakDoprinosa, setTrosakDoprinosa] = React.useState<null | number | string>(null);

    const [ukupniMjesecniDoprinos, setUkupniMjesecniDoprinos] = React.useState<null | number>(null);
    const [ukupniBrojDnevnihSati, setUkupniBrojDnevnihSati] = React.useState<null | number | string>(null);
    const [ukupniBrojDana, setUkupniBrojDana] = React.useState<null | number | string>(null);
    const [ukupniTrosakDoprinosa, setUkupniTrosakDoprinosa] = React.useState<null | number | string>(null);

    const [iznosTransakcije, setIznosTransakcije] = React.useState<null | number>(null);
    const [tipTransakcije, setTipTransakcije] = React.useState<null | string>(null);
    const [biljeskaTransakcije, setBiljeskaTransakcije] = React.useState<null | string>(null);

    const [prek, setPrek] = React.useState(prekidaci);
    const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo

    React.useEffect(()=>{
      if (podaciPrometi.length !== 0) {
        let maxdate = podaciPrometi[0].datum;
        let mindate = podaciPrometi[0].datum;
        for (let i = 1; i < podaciPrometi.length; i++) {
          let datum = podaciPrometi[i].datum;
          if (datum < mindate)  mindate = datum;
          if (datum > maxdate)  maxdate = datum;
        }

        setPocetniDatum(tranfsormDateStr(mindate));
        setZavrsniDatum(tranfsormDateStr(brDanaOdDatumaStr(7, maxdate)));
        // {tranfsormDateStr(el.datum) + " - " + tranfsormDateStr(brDanaOdDatumaStr(7, el.datum))}
      }
      setOdabraniPrometID("-");
      setOdabranoVoziloID("-");
      setOdabraniDoprinosID("-");
      setOdabranaTransakcijaID("-");
    }, [resetBr]);

    React.useEffect(()=>{
      setUserName(podaciVozac.name);
      setIme(podaciVozac.ime);
      setPrezime(podaciVozac.prezime);
    }, [podaciVozac, resetBr]);

    React.useEffect(()=>{
      let pp = null;

      if (pocetniDatum === null && zavrsniDatum !== null) {
        setZavrsniDatum(null);
        postaviFlashPoruku("Ne mozete zadati zavrsni datum dok pocetni nije specificiran.", "danger", "tablica-obracun");      
      } else if (pocetniDatum !== null && zavrsniDatum !== null && !redosljedDatuma(pocetniDatum, zavrsniDatum)) {
        postaviFlashPoruku("Zavrsni datum mora biti poslije pocetnog datuma.", "danger", "tablica-obracun");      
        if (prevZavrsniDatum !== zavrsniDatum)  setZavrsniDatum(prevZavrsniDatum);
        if (prevPocetniDatum !== pocetniDatum)  setPocetniDatum(prevPocetniDatum);
      } else if (prevZavrsniDatum !== zavrsniDatum || prevPocetniDatum !== pocetniDatum) {
        let pp = podaciPrometi.filter((el)=>{return preklapanjeFun(el.datum, brDanaOdDatumaStr(7, el.datum), pocetniDatum, zavrsniDatum) !== -1;})
        if (pp.find((el)=>{return el.id.toString() === odabraniPrometID}) === undefined)  setOdabraniPrometID("-");
        setOdabranoVoziloID("-");
        setOdabraniDoprinosID("-");
        // ovdje korigiramo odabrani datum ukoliko interval zahvaca tjedni promet ali ne pada na pocetak/kraj tjednog prometa
        let pocetniDatum1 = pocetniDatum;
        if (pocetniDatum1 !== null) {
          for (let i = 0; i < pp.length; i++) {
            let dtr = tranfsormDateStr(pp[i].datum);
            if (!redosljedDatuma(pocetniDatum1, dtr)) pocetniDatum1 = dtr;  // dodajNule
          }
        }
        console.log("pocetni datum1 " + pocetniDatum1);

        let zavrsniDatum1 = zavrsniDatum;
        if (zavrsniDatum1 !== null) {
          for (let i = 0; i < pp.length; i++) {
            let dtr = tranfsormDateStr(brDanaOdDatumaStr(7, pp[i].datum));
            if (!redosljedDatuma(dtr, zavrsniDatum1)) zavrsniDatum1 = dtr;
          }
        }

        if (pocetniDatum !== pocetniDatum1)  setPocetniDatum(pocetniDatum1);
        if (zavrsniDatum !== zavrsniDatum1)  setZavrsniDatum(zavrsniDatum1);
        if (prevZavrsniDatum !== zavrsniDatum)  setPrevZavrsniDatum(zavrsniDatum1);
        if (prevPocetniDatum !== pocetniDatum)  setPrevPocetniDatum(pocetniDatum1);
      }

      // ovdje racunamo promete za odabrani interal
      if (pp === null) pp = podaciPrometi.filter((el)=>{return preklapanjeFun(el.datum, brDanaOdDatumaStr(7, el.datum), pocetniDatum, zavrsniDatum) !== -1;});
      let up = 0, upp = 0;
      let uc = 0, ucp = 0;
      let bp = 0, bpp = 0;
      let bc = 0, bcp = 0;
      let pr = 0, prp = 0;
      let prov = 0, provp = 0;
      setProvizijaSw(false);
      setPostotakSw(false);
      for (let i = 0; i < pp.length; i++) {
        let c = pp[i].uber_promet;
        let provizija = pp[i].provizija;
        let tipProvizije = pp[i].tip_provizije;
        if (tipProvizije === "postotak") {
          setPostotakSw(true);
          if (c !== null) {
            upp += c;
            provp += c * provizija / 100;
          }
          c = pp[i].uber_cash;
          if (c !== null)  ucp += c;
          c = pp[i].bolt_promet;
          if (c !== null) {
            bpp += c;
            provp += c * provizija / 100;
          }
          c = pp[i].bolt_cash;
          if (c !== null)  bcp += c;
        } else {
          setProvizijaSw(true);
          if (c !== null) {
            up += c;
            if (tipProvizije === "provizija") {
              prov += c * provizija / 100;
            } 
          }
          c = pp[i].uber_cash;
          if (c !== null)  uc += c;
          c = pp[i].bolt_promet;
          if (c !== null) {
            bp += c;
            if (tipProvizije === "provizija") {
              prov += c * provizija / 100;
            }
          }
          if (tipProvizije === "fiksni")  prov += provizija;
          c = pp[i].bolt_cash;
          if (c !== null)  bc += c;
        }
      }
      setUkupniUberPromet(up);
      setUkupniUberCash(uc);
      setUkupniBoltPromet(bp);
      setUkupniBoltCash(bc);
      setUkupanIznosProvizije(prov);
      let sp = sumirajPromete(up,bp);
      if (prov === 0 || sp === null) {
        setUkupnaProvizija(0);
      } else {
        setUkupnaProvizija(prov/sp*100);
      }
      setUkupniUberPrometPostotak(upp);
      setUkupniUberCashPostotak(ucp);
      setUkupniBoltPrometPostotak(bpp);
      setUkupniBoltCashPostotak(bcp);
      setUkupanIznosPostotka(provp);
      sp = sumirajPromete(upp,bpp);
      if (provp === 0 || sp === null) {
        setUkupniPostotak(0);
      } else {
        setUkupniPostotak(provp/sp*100);
      }

      // ovdje racunamo doprinose za odabrani interval
      pp = podaciDoprinosi.filter((el)=>{return preklapanjeFun(el.pocetak, el.kraj, pocetniDatum, zavrsniDatum) !== -1;});
      let ds: null | number = 0;
      let bd: "inf" | number = 0;
      let td: "inf" | number = 0;
      let md: number | null = 0;
      let statTezina = 0;
      let statTezina1 = 0;
      for (let i = 0; i < pp.length; i++) {
        
        let brdana: string | number = preklapanjeFun(pp[i].pocetak, pp[i].kraj, pocetniDatum, zavrsniDatum);
        if (brdana === -2)  brdana = preklapanjeFun(pp[i].pocetak, pp[i].kraj, pp[i].pocetak, pp[i].kraj);
        if (brdana === -3) {
          md = null;
          td = "inf";
          bd = "inf";
          ds = null;
          break;
        }
        bd += brdana;
        td += brdana / 30 * pp[i].dnevni_broj_sati / 8 * pp[i].mjesecni_doprinos; 
        ds += pp[i].dnevni_broj_sati * brdana;
        md += pp[i].mjesecni_doprinos * brdana * pp[i].dnevni_broj_sati;
        statTezina += brdana * pp[i].dnevni_broj_sati;
        statTezina1 += brdana * pp[i].mjesecni_doprinos;
      }

      setUkupniBrojDana(bd);
      setUkupniTrosakDoprinosa(td);
      if (ds === null || bd === "inf" || md === null || td === "inf") {
        setUkupniBrojDnevnihSati(null);
        setUkupniMjesecniDoprinos(null);
      } else if (bd === 0 || ds === 0) {
        setUkupniBrojDana(0);
        setUkupniTrosakDoprinosa(0);
        setUkupniBrojDnevnihSati(null);
        setUkupniMjesecniDoprinos(null);
      } else {
        setUkupniBrojDnevnihSati(ds/bd);
        setUkupniMjesecniDoprinos(td/ds*30*8);
      }

      // ovdje racunamo trosak vozila za odabrani interval
      pp = podaciVozila.filter((el)=>{return preklapanjeFun(el.pocetak, el.kraj, pocetniDatum, zavrsniDatum) !== -1;});
      let dv: "inf" | number = 0;
      let tv: "inf" | number = 0;
      for (let i = 0; i < pp.length; i++) {
        let brdana: string | number = preklapanjeFun(pp[i].pocetak, pp[i].kraj, pocetniDatum, zavrsniDatum);
        if (brdana === -2)  brdana = preklapanjeFun(pp[i].pocetak, pp[i].kraj, pp[i].pocetak, pp[i].kraj);
        if (brdana === -3) {
          dv = "inf";
          tv = "inf";
          break;
        }
        dv += brdana;
        tv += pp[i].tjedna_cijena / 7 * brdana;
      }
      setUkupniBrojDanaVozila(dv);
      setUkupniTrosakVozila(tv);
      if (tv !== "inf" && dv !== "inf" && dv !== 0) {
        setUkupnaTjednaCijena(tv/dv*7);
      } else {
        setUkupnaTjednaCijena(null);
      }

      // ovdje racunamo ukupan iznos isplacenih transakcija za odabrani interval
      pp = podaciTransakcije.filter((el)=>{return preklapanjeFun(el.datum, brDanaOdDatumaStr(1, el.datum), pocetniDatum, zavrsniDatum) !== -1;})
      let iznos = 0;
      for (let i = 0; i < pp.length; i++) {
        iznos += pp[i].iznos;
      }
      setUkupanIznosTransakcija(iznos);

    }, [pocetniDatum, zavrsniDatum, resetBr]);

    React.useEffect(()=>{
      let e = podaciPrometi.find((el)=>{return el.id.toString() == odabraniPrometID});
      if (e === undefined) {
        setUberPromet(null);
        setUberCash(null);
        setBoltPromet(null);
        setBoltCash(null);
        setTipProvizije(null);
        setProvizija(null);
        setIznosProvizije(null);
      } else {
        setUberPromet(e.uber_promet);
        setUberCash(e.uber_cash);
        setBoltPromet(e.bolt_promet);
        setBoltCash(e.bolt_cash);
        setTipProvizije(e.tip_provizije);
        setProvizija(e.provizija);

        let pr = sumirajPromete(e.uber_promet, e.bolt_promet);
        if (pr !== null) {
          setIznosProvizije(e.provizija/100*pr);
        } else {
          setIznosProvizije(null);
        }
      }
    }, [odabraniPrometID]);

    React.useEffect(()=>{
      if (odabranoVoziloID === "-") {
        setTjednaCijena(null);
        setBrojDanaVozila(null);
        setTrosakVozila(null);
      } else {
        let pp = podaciVozila.find((el)=>{return el.id.toString() === odabranoVoziloID});
        if (pp === undefined) {
          setTjednaCijena(null);
          setBrojDanaVozila(null);
          setTrosakVozila(null);
        } else {
          let brdana: string | number = preklapanjeFun(pp.pocetak, pp.kraj, pocetniDatum, zavrsniDatum);
          if (brdana === -2)  brdana = preklapanjeFun(pp.pocetak, pp.kraj, pp.pocetak, pp.kraj);

          if (brdana === -3)  brdana = "inf";
          
          setBrojDanaVozila(brdana);
          if (pp.tjedna_cijena === null) {
            setTjednaCijena(0);
          } else {
            setTjednaCijena(pp.tjedna_cijena);
          }
          if (brdana === null) {
            setTrosakVozila(null);
          } else if (pp.tjedna_cijena === null) {
            setTrosakVozila(0);
          } else if (typeof brdana === "string") {
            setTrosakVozila("inf");
          } else {
            setTrosakVozila(pp.tjedna_cijena * brdana / 7);
          }
        }
      }
    }, [odabranoVoziloID]);

    React.useEffect(()=>{
      if (odabraniDoprinosID === "-") {
        setMjesecniDoprinos(null);
        setBrojDnevnihSati(null);
        setBrojDana(null);
        setTrosakDoprinosa(null);
      } else {
        let pp = podaciDoprinosi.find((el)=>{return el.id.toString() === odabraniDoprinosID});
        if (pp === undefined) {
          setMjesecniDoprinos(null);
          setBrojDnevnihSati(null);
          setBrojDana(null);
          setTrosakDoprinosa(null);
        } else {
          let brdana: string | number = preklapanjeFun(pp.pocetak, pp.kraj, pocetniDatum, zavrsniDatum);
          if (brdana === -2)  brdana = preklapanjeFun(pp.pocetak, pp.kraj, pp.pocetak, pp.kraj);

          if (brdana === -3)  brdana = "inf";

          setBrojDana(brdana);
          setMjesecniDoprinos(pp.mjesecni_doprinos);
          setBrojDnevnihSati(pp.dnevni_broj_sati);
          
          if (brdana === null) {
            setTrosakDoprinosa(null);
          } else if (typeof brdana === "string") {
            setTrosakDoprinosa("inf");
          } else {
            setTrosakDoprinosa(pp.dnevni_broj_sati / 8 * brdana / 30 * pp.mjesecni_doprinos);
          }
        }

      }

    }, [odabraniDoprinosID]);

    React.useEffect(()=>{
      if (odabranaTransakcijaID === "-") {
        setIznosTransakcije(null);
        setTipTransakcije(null);
        setBiljeskaTransakcije(null);
      } else {
        let pp = podaciTransakcije.find((el)=>{return el.id.toString() === odabranaTransakcijaID});
        if (pp === undefined) {
          setIznosTransakcije(null);
          setTipTransakcije(null);
          setBiljeskaTransakcije(null);
        } else {
          setIznosTransakcije(pp.iznos);
          setTipTransakcije(pp.tip_transakcije);
          setBiljeskaTransakcije(pp.biljeska);
        }
      }

    }, [odabranaTransakcijaID]);

    function maxS(str: string, len: number): string {
      // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
      if (str.length > len) {
        postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica-obracun");
        return str.substring(0,len);
      }
      return str;
    }

    function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
      let id = e.currentTarget.id;
      let br = parseInt(id.substring(1));
  
      if (prek[br]) {
  
        if (e.type === "mouseenter") {
          e.currentTarget.classList.add("hover");
        } else if (e.type === "mouseleave") {
          e.currentTarget.classList.remove("hover");
        } else {
          
          rKlik.current = e.currentTarget;
          let b = (swPolje[br] + 1) % 2;
          setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})
        }
      }
      
    }

    function gumbKlik(e:React.MouseEvent<HTMLDivElement>) {
      let el = e.currentTarget;
      el.classList.add("klik-2");
      setTimeout(()=>{el.classList.remove("klik-2")}, 150);

      if (el.id === "gumb-daljnjeg") {
        setZavrsniDatum(null);
      }
    }

    function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
      if (e.currentTarget.id === "izbornik-promet") {
        setOdabraniPrometID(e.currentTarget.value);
      } else if (e.currentTarget.id === "izbornik-vozilo") {
        setOdabranoVoziloID(e.currentTarget.value);
      } else if (e.currentTarget.id === "izbornik-doprinos") {
        setOdabraniDoprinosID(e.currentTarget.value);
      } else if (e.currentTarget.id === "izbornik-transakcija") {
        setOdabranaTransakcijaID(e.currentTarget.value);
      }
    }
    
    function setCheck(br: number) {
      if (br === 1) {
        setCheckRegSw((prev)=>{return !prev;});
      } else {
        setCheckModelSw((prev)=>{return !prev;});
      }
    }

    function outputOpcije(polje: PodaciVozilaTip[]) {
      if (checkRegSw) {
        if (checkModelSw) {
          return (<>
            <option value="-" className="bez-odabira1">bez odabira</option>
            {polje.map((el)=>{return <option key={el.id} value={el.id}>
                     <span className="span-el1">{tranfsormDateStr(el.pocetak) + " - " + tranfsormDateStr(el.kraj)}</span>
                     <span className="span-el2">{el.registracija}</span>
                     <span className="span-el3">{el.proizvodac + " " + el.model}</span></option>})}
            </>)
        } else {
          return (<>
            <option value="-" className="bez-odabira2">bez odabira</option>
            {polje.map((el)=>{return <option key={el.id} value={el.id}>
                     <span className="span-el1a">{tranfsormDateStr(el.pocetak) + " - " + tranfsormDateStr(el.kraj)}</span>
                     <span className="span-el2a">{el.registracija}</span></option>})}
            </>)
        }
      } else {
        if (checkModelSw) {
          return (<>
            <option value="-" className="bez-odabira3">bez odabira</option>
            {polje.map((el)=>{return <option key={el.id} value={el.id}>
                     <span className="span-el1c">{tranfsormDateStr(el.pocetak) + " - " + tranfsormDateStr(el.kraj)}</span>
                     <span className="span-el3c">{el.proizvodac + " " + el.model}</span></option>})}
            </>)
        } else {
          return (<>
            <option value="-" className="bez-odabira4">bez odabira</option>
            {polje.map((el)=>{return <option key={el.id} value={el.id}>
                     <span className="span-el1b">{tranfsormDateStr(el.pocetak) + " - " + tranfsormDateStr(el.kraj)}</span>
                  </option>})}
            </>)
        }
      }
    }

    function tipObracunaOutput(tip: null | "fiksni" | "postotak" | "provizija") : string {
      if (tip === null) {
        return "-";
      } else if (tip === "fiksni") {
        return "fiksni iznos:";
      } else if (tip === "postotak") {
        return "postotak:";
      } else if (tip === "provizija") {
        return "provizija:";
      }
      return "-";
    }

    function tipObracunaOutput1(tip: null | "fiksni" | "postotak" | "provizija") : string {
      if (tip === null) {
        return "-";
      } else if (tip === "fiksni") {
        return "";
      } else if (tip === "postotak") {
        return "vozacev dio:";
      } else if (tip === "provizija") {
        return "iznos provizije:";
      }
      return "-";
    }

    function vratiObracun1(promet1: number | null, promet2: number | null, provizija: number | null, cash1: number | null, 
      cash2: number | null, postotak: number | null, cash1Postotak: number | null, cash2Postotak: number | null, vozilo: number | null | string, doprinosi: number | null | string, transakcije: number | null) {

      let promet = sumirajPromete(promet1, promet2);
      let cash = sumirajPromete(cash1, cash2);
      
      let rez = 0;
      if (promet === null || provizija === null || cash === null || vozilo === null || provizija === null ||
        doprinosi === null || typeof vozilo === "string" || typeof doprinosi === "string" || transakcije === null) {
        return <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupno:</p></div>
                <div className="redak-el2 crvena"><p className="p-el2">--</p></div>
               </div>;
      }
      console.log("podaci:   " + promet + ", " + provizija + ", " + vozilo + ", " + cash + ", " + doprinosi + ", " + transakcije);
      rez = promet - cash - provizija - vozilo - doprinosi - transakcije;
      
      let cashPostotak = sumirajPromete(cash1Postotak, cash2Postotak);
      if (postotak !== null && cashPostotak !== null) {
        rez += postotak - cashPostotak;
        console.log("podaci1: " + postotak + ", " + cashPostotak);
      }

      if (rez >= 0) {
        return <div className="redak">
            <div className="redak-el1"><p className="p-el">ukupno:</p></div>
            <div className="redak-el2 zelena"><p className="p-el1">{ispisSaDecimalama(rez)}</p></div>
          </div>;
      } else {
          return <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupno:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(rez)}</p></div>
               </div>;
      }
    }

    function vratiObracun(promet1: number | null, promet2: number | null, provizija: number | null, cash1: number | null, 
      cash2: number | null, vozilo: number | null | string, doprinosi: number | null | string, transakcije: number | null) {
      
      let promet = sumirajPromete(promet1, promet2);
      let cash = sumirajPromete(cash1, cash2);
      
      if (promet === null || provizija === null || cash === null || vozilo === null || provizija === null ||
        doprinosi === null || typeof vozilo === "string" || typeof doprinosi === "string" || transakcije === null) {
        return <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupno:</p></div>
                <div className="redak-el2 crvena"><p className="p-el2">--</p></div>
               </div>;
      } else {
        let rez = promet - cash - provizija - vozilo - doprinosi - transakcije;
        if (rez >= 0) {
          return <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupno:</p></div>
                <div className="redak-el2 zelena"><p className="p-el1">{ispisSaDecimalama(rez)}</p></div>
               </div>;
        } else {
          return <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupno:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(rez)}</p></div>
               </div>;
        }
      }    
    }

    return (
      <div className="tablica-obracun-cont">
        <table className="tablica-obracun"><tbody>
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica-obracun" kod2={el.kod} />})}
        </div>
        {showSw[1] ?
        <tr id="r1" className="row-obracun">
          <td className="row-obracun-el1">Username:</td>
            <td className="row-obracun-el2">{ispis(userName)}</td> 
        </tr> : null}
        {showSw[2] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r2" className="row-obracun">
          <td className="row-obracun-el1">Ime:</td>
          {swPolje[2] === 0 ?
            <td className="row-obracun-el2">{ispis(ime)}</td> :
            <td className="row-obracun-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setIme(maxS(e.target.value,32))}} id="input2" name="input2" value={ispisId(ime)}/>
            </td>
          }
        </tr> : null}
        {showSw[3] ?
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r3" className="row-obracun">
          <td className="row-obracun-el1">Prezime:</td>
          {swPolje[3] === 0 ?
            <td className="row-obracun-el2">{ispis(prezime)}</td> :
            <td className="row-obracun-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>{setPrezime(maxS(e.target.value,32))}} id="input3" name="input3" value={ispisId(prezime)}/>
            </td>
          }
        </tr> : null}
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r4" className="row-obracun">
          <td className="row-obracun-el1">Pocetni datum:</td>
          {swPolje[4] === 0 ?
            <td className="row-obracun-el2">{ispis(pocetniDatum)}</td> :
            <td className="row-obracun-el2">
              <div className="kalendar-cont">
                <Kalendar width={200} dateStr={pocetniDatum} 
                 setDateStr={setPocetniDatum} {...otvoriKalendar(pocetniDatum)}/>
              </div>
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r5" className="row-obracun">
          <td className="row-obracun-el1">Konacni datum:</td>
          {swPolje[5] === 0 ?
            <td className="row-obracun-el2">{nullToDoDaljnjeg(zavrsniDatum)}</td> :
            <td className="row-obracun-el2">
              <div className="kalendar-cont">
                <Kalendar width={200} dateStr={zavrsniDatum} 
                 setDateStr={setZavrsniDatum} {...otvoriKalendar(zavrsniDatum)}/>
                <div onClick={gumbKlik} className="div-button-2" id="gumb-daljnjeg">Do daljnjeg</div>
              </div>
            </td>
          }
        </tr>
        <tr id="r6" className="row-obracun">
          <td className="row-obracun-el1">Prometi:</td>
          <td className="row-obracun-el2">
            <select className="izbornik-b1" id="izbornik-promet" size={5} value={odabraniPrometID} onClick={(e)=>{e.stopPropagation()}} 
              onChange={odabirFun} >
              <option value="-">bez odabira</option>
              {podaciPrometi.filter((el)=>{return preklapanjeFun(el.datum, brDanaOdDatumaStr(7, el.datum), pocetniDatum, zavrsniDatum) !== -1;})
               .sort((el1, el2)=>{if (el1.datum < el2.datum) return -1; if (el1.datum > el2.datum) return 1; return 0;})
               .map((el)=>{return <option key={el.id} value={el.id}>{tranfsormDateStr(el.datum) + " - " + tranfsormDateStr(brDanaOdDatumaStr(7, el.datum))}</option>})}
            </select>
            <div className="ispis-prometa-okvir korigirani-ispis-prometa">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber promet:</div> 
                  <div className="ispis-prometa-el2">{ispis(uberPromet)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt promet:</div> 
                  <div className="ispis-prometa-el2">{ispis(boltPromet)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">{tipObracunaOutput(tipProvizije)}</div> 
                  <div className="ispis-prometa-el2">{ispis(provizija)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber cash:</div> 
                  <div className="ispis-prometa-el2">{ispis(uberCash)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt cash:</div> 
                  <div className="ispis-prometa-el2">{ispis(boltCash)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">{tipObracunaOutput1(tipProvizije)}</div> 
                  <div className="ispis-prometa-el2">{tipProvizije !== "fiksni" ? ispisSaDecimalama(iznosProvizije) : null}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        {provizijaSw ? 
        <tr id="r8" className="row-obracun">
          <td className="row-obracun-el1">Ukupni prometi{postotakSw? " (na proviziju):" : ":"}</td>
          <td className="row-obracun-el2">
            <div className="ispis-prometa-okvir korigirani-ispis-prometa">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber promet:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniUberPromet)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt promet:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniBoltPromet)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">provizija:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupnaProvizija,3)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber cash:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniUberCash)}</div>
                </div>    
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt cash:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniBoltCash)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">iznos provizije:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupanIznosProvizije)}</div>
                </div>  
              </div>
            </div>
          </td>
        </tr> : null}
        {postotakSw ?
        <tr id="r17" className="row-obracun">
          <td className="row-obracun-el1">Ukupni prometi{provizijaSw? " (na postotak):" : ":"}</td>
          <td className="row-obracun-el2">
            <div className="ispis-prometa-okvir korigirani-ispis-prometa">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber promet:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniUberPrometPostotak)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt promet:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniBoltPrometPostotak)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">postotak:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniPostotak,3)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">uber cash:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniUberCashPostotak)}</div>
                </div>    
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">bolt cash:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniBoltCashPostotak)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">vozacev dio:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupanIznosPostotka)}</div>
                </div>  
              </div>
            </div>
          </td>
        </tr> : null}

        <tr id="r9" className="row-obracun">
          <td className="row-obracun-el1">Vozila:</td>
          <td className="row-obracun-el2">
            <select className="izbornik-b2" id="izbornik-vozilo" size={5} value={odabranoVoziloID} onClick={(e)=>{e.stopPropagation()}} 
              onChange={odabirFun} >
              {outputOpcije(podaciVozila.filter((el)=>{return preklapanjeFun(el.pocetak, el.kraj, pocetniDatum, zavrsniDatum) !== -1;})
               .sort((el1, el2)=>{if (el1.pocetak < el2.pocetak) return -1; if (el1.pocetak > el2.pocetak) return 1; return 0;}))}
            </select>
            <div className="checkbox-opcije">
              <div className="checkbox-div" onClick={(e)=>{e.stopPropagation()}}>
                <input type="checkbox" checked={checkRegSw} onChange={()=>{setCheck(1)}} 
                   className="checkbox-el" id="checkbox-uber"/>
                <label className="checkbox-label-el" htmlFor="checkbox-uber">Registracija</label>
              </div>
              <div className="checkbox-div" onClick={(e)=>{e.stopPropagation()}}>
                <input type="checkbox" checked={checkModelSw} onChange={()=>{setCheck(2)}}
                  className="checkbox-el" id="checkbox-bolt"/>
                <label className="checkbox-label-el" htmlFor="checkbox-bolt">Model</label>
              </div>
            </div>
            <div className="ispis-prometa-okvir">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">tjedna cijena:</div> 
                  <div className="ispis-prometa-el2">{ispis1(tjednaCijena)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">broj dana:</div> 
                  <div className="ispis-prometa-el2">{ispis(brojDanaVozila)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">trosak:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(trosakVozila)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1"></div> 
                  <div className="ispis-prometa-el2"></div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr id="r14" className="row-obracun">
          <td className="row-obracun-el1">Ukupni trosak vozila:</td>
          <td className="row-obracun-el2">
            <div className="ispis-prometa-okvir">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">tjedna cijena:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupnaTjednaCijena)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">broj dana:</div> 
                  <div className="ispis-prometa-el2">{ispis(ukupniBrojDanaVozila)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">trosak:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniTrosakVozila)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1"></div> 
                  <div className="ispis-prometa-el2"></div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr id="r11" className="row-obracun">
          <td className="row-obracun-el1">Doprinosi:</td>
          <td className="row-obracun-el2">
            <select className="izbornik-b3" id="izbornik-doprinos" size={5} value={odabraniDoprinosID} onClick={(e)=>{e.stopPropagation()}} 
              onChange={odabirFun} >
              <option value="-">bez odabira</option>
              {podaciDoprinosi.filter((el)=>{return preklapanjeFun(el.pocetak, el.kraj, pocetniDatum, zavrsniDatum) !== -1;})
               .sort((el1, el2)=>{if (el1.pocetak < el2.pocetak) return -1; if (el1.pocetak > el2.pocetak) return 1; return 0;})
               .map((el)=>{return <option key={el.id} value={el.id}>{tranfsormDateStr(el.pocetak) + " - " + tranfsormDateStr(el.kraj)}</option>})}
            </select>
            <div className="ispis-prometa-okvir">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">180h doprinos:</div> 
                  <div className="ispis-prometa-el2">{ispis1(mjesecniDoprinos)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">dnevni sati:</div> 
                  <div className="ispis-prometa-el2">{ispis1(brojDnevnihSati)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">broj dana:</div> 
                  <div className="ispis-prometa-el2">{ispis(brojDana)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">trosak:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(trosakDoprinosa)}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr id="r13" className="row-obracun">
          <td className="row-obracun-el1">Ukupni doprinosi:</td>
          <td className="row-obracun-el2">
            <div className="ispis-prometa-okvir">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">180h doprinos:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniMjesecniDoprinos)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">dnevni sati:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniBrojDnevnihSati,3)}</div>
                </div>
              </div>
              <div className="okvir-desni">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">broj dana:</div> 
                  <div className="ispis-prometa-el2">{ispis(ukupniBrojDana)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">trosak:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(ukupniTrosakDoprinosa)}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr id="r16" className="row-obracun">
          <td className="row-obracun-el1">Transakcije:</td>
          <td className="row-obracun-el2">
            <select className="izbornik-b3" id="izbornik-transakcija" size={5} value={odabranaTransakcijaID} onClick={(e)=>{e.stopPropagation()}} 
              onChange={odabirFun} >
              <option value="-">bez odabira</option>
              {podaciTransakcije.filter((el)=>{return preklapanjeFun(el.datum, brDanaOdDatumaStr(1, el.datum), pocetniDatum, zavrsniDatum) !== -1;})
               .sort((el1, el2)=>{if (el1.datum < el2.datum) return -1; if (el1.datum > el2.datum) return 1; return 0;})
               .map((el)=>{return <option key={el.id} value={el.id}>{tranfsormDateStr(el.datum) + " - "}<span className="span-iznos">{ispisSaDecimalama(el.iznos)}</span></option>})}
            </select>
            <div className="ispis-prometa-okvir">
              <div className="okvir-lijevi">
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">tip:</div> 
                  <div className="ispis-prometa-el2">{ispis1(tipTransakcije)}</div>
                </div>
                <div className="ispis-prometa">
                  <div className="ispis-prometa-el1">iznos:</div> 
                  <div className="ispis-prometa-el2">{ispisSaDecimalama(iznosTransakcije)}</div>
                </div>
              </div>
              <div className="okvir-desni1">
                <div className="prikaz-biljeske">
                  <p className="biljeska-p">{biljeskaTransakcije}</p>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr id="r15" className="row-obracun">
          <td className="row-obracun-el1">Ukupni obracun:</td>
          <td className="row-obracun-el2">
            <div className="obracun-polje">
              {provizijaSw ? <>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupni promet:</p></div>
                <div className="redak-el2 zelena"><p className="p-el1">{ispisSaDecimalama(sumirajPromete(ukupniUberPromet,ukupniBoltPromet))}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">provizija:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(ukupanIznosProvizije)}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupni cash:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(sumirajPromete(ukupniUberCash,ukupniBoltCash))}</p></div>
              </div> 
              </> : null}
              {postotakSw ? <>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupni promet:</p></div>
                <div className="redak-el2 crna"><p className="p-el1">{ispisSaDecimalama(sumirajPromete(ukupniUberPrometPostotak,ukupniBoltPrometPostotak))}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">vozacev iznos({ispisSaDecimalama(ukupniPostotak)}%):</p></div>
                <div className="redak-el2 zelena"><p className="p-el1">{ispisSaDecimalama(ukupanIznosPostotka)}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">ukupni cash:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(sumirajPromete(ukupniUberCashPostotak,ukupniBoltCashPostotak))}</p></div>
              </div>
              </> : null}
              {postotakSw || provizijaSw ? <>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">vozilo:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(ukupniTrosakVozila)}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">doprinosi:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(ukupniTrosakDoprinosa)}</p></div>
              </div>
              <div className="redak">
                <div className="redak-el1"><p className="p-el">isplaceno:</p></div>
                <div className="redak-el2 crvena"><p className="p-el1">-{ispisSaDecimalama(ukupanIznosTransakcija)}</p></div>
              </div>
              <div className="crta">
                <div className="crta-el"></div>
              </div>
              {vratiObracun1(ukupniUberPromet, ukupniBoltPromet, ukupanIznosProvizije, ukupniUberCash, ukupniBoltCash, ukupanIznosPostotka, ukupniUberCashPostotak, ukupniBoltCashPostotak, ukupniTrosakVozila, ukupniTrosakDoprinosa, ukupanIznosTransakcija)}
              </> : null}
            </div>
          </td>
        </tr>
        

        </tbody></table>
      </div>
    )
  }

  function sumirajPromete(inp1: number | null, inp2: number | null): number | null {
    let rez: number | null = null;
    if (inp1 !== null) {
      rez = inp1;
      if (inp2 !== null) rez += inp2;
    } else if (inp2 !== null) {
      rez = inp2;
    }
    return rez;
  }

  type PodaciTip = {
    id: number;
    name: string;
    ime: string;
    prezime: string;
    uberSw: "YES" | "NO" | null;
    boltSw: "YES" | "NO" | null;
    prometi: PodaciPrometiTip[];
    doprinosi: PodaciDoprinosiTip[];
    vozila: PodaciVozilaTip[];
    transakcije: PodaciTransakcijeTip[];
  }

  export function ObracunIzbornik() {
    const [searchStr, setSearchStr] = React.useState("");
    const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
    const [activatedSw, setActivatedSw] = React.useState(true);
    const [br, setBr] = React.useState(0);
    const [br1, setBr1] = React.useState(0);
    const [resetBr, setResetBr] = React.useState(0);
    const [action, setAction] = React.useState("");
    const [status, setStatus] = React.useState("loading");
    const [podaci, setPodaci] = React.useState<PodaciTip[]>([]);
    const [podaciVozac, setPodaciVozac] = React.useState<PodaciVozacTip>({} as PodaciVozacTip);
    const [podaciVozila, setPodaciVozila] = React.useState<PodaciVozilaTip[]>([]);
    const [podaciPrometi, setPodaciPrometi] = React.useState<PodaciPrometiTip[]>([]);
    const [podaciDoprinosi, setPodaciDoprinosi] = React.useState<PodaciDoprinosiTip[]>([]);
    const [podaciTransakcije, setPodaciTransakcije] = React.useState<PodaciTransakcijeTip[]>([]);
    
    const [signoutSw, setSignoutSw] = React.useState(false);
  
    const [checkImeSw, setCheckImeSw] = React.useState(true);
    const [checkPrezimeSw, setCheckPrezimeSw] = React.useState(true);
    const [checkNickSw, setCheckNickSw] = React.useState(true);
  
    const [confirmSw, setConfirmSw] = React.useState("");
    const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
    const {kljuc, setKljuc} = React.useContext(Kontekst);
    const [pomak, setPomak] = React.useState(0);

    const {odabraniVozacIDObracuni, setOdabraniVozacIDObracuni, checkNickSwObracuni, setCheckNickSwObracuni, checkImeSwObracuni, 
      setCheckImeSwObracuni, checkPrezimeSwObracuni, setCheckPrezimeSwObracuni, searchStrObracuni, setSearchStrObracuni} = React.useContext(StateKontekst);

    const r = React.useRef<HTMLDivElement | null>(null);
  
    const [loading, error, value] = useFetch1(ADRESA1 + '/api/obracun_izbornik', 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": action
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);
  
    React.useEffect(()=>{
      console.log("loading5: " + loading);
      console.log("error5: " + error);
      console.log("value5: ");
      console.log("br: " + br);
      console.log(value);
  
      if (br !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA");
          console.log(value.errorCode);
          setStatus("sign-in");
          setPodaci([]);
          setPodaciVozila([]);
          
        } else if (!loading && !value.error){
          console.log("cini se da je sve ucitano");
          console.log(value.value);
          setStatus("logged-in");
          setPodaci(value.value.podaci);
          //setResetBr((prev)=>{return prev+1;});
        }
      }
    }, [loading]);

    React.useEffect(()=>{
      console.log("Ucitao sam podatke..");
      console.log(podaci);
    }, []);

    React.useEffect(()=>{
      if (odabraniVozacID === "-") {
        setPodaciVozac({} as PodaciVozacTip);
        setPodaciVozila([]);
        setPodaciPrometi([]);
        setPodaciDoprinosi([]);
        setPodaciTransakcije([]);
      } else {
        let pp = podaci.find((el)=>{return el.id.toString() === odabraniVozacID});
        if (pp === undefined ) {
          setPodaciVozac({} as PodaciVozacTip);
          setPodaciVozila([]);
          setPodaciPrometi([]);
          setPodaciDoprinosi([]);
          setPodaciTransakcije([]);
        } else {
          setPodaciVozac(pp);
          setPodaciVozila(pp.vozila);
          setPodaciDoprinosi(pp.doprinosi);
          setPodaciPrometi(pp.prometi);
          setPodaciTransakcije(pp.transakcije)
        }
      }
      setResetBr((prev)=>{return prev+1;});
    }, [odabraniVozacID, podaci]);
  
    React.useEffect(()=>{
      if (action !== "") {
        setBr((prev)=>{return prev+1});
      }
    }, [action, br1]);
  
    React.useEffect(()=>{
      setAction("ucitaj");
    }, []);
  
    React.useEffect(()=>{
      console.log("pokrenuo " + br1 + " " + Math.random());
      if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
    }, [kljuc]);

    React.useEffect(()=>{
      if (status !== "logged-in") {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setPomak(scrollTop);
        if (r.current !== null)  r.current.style.position = "fixed";
        if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
      } else {
        if (r.current !== null)  r.current.style.top = "0px";
        if (r.current !== null)  r.current.style.position = "relative";
        window.scrollTo(0, pomak);
      }
    }, [status]);

    React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
      if (br === 0) {
        setOdabraniVozacID(odabraniVozacIDObracuni);
        setSearchStr(searchStrObracuni);
        setCheckNickSw(checkNickSwObracuni);
        setCheckImeSw(checkImeSwObracuni);
        setCheckPrezimeSw(checkPrezimeSwObracuni);
      } else {
        setOdabraniVozacIDObracuni(odabraniVozacID);
        setSearchStrObracuni(searchStr);
        setCheckNickSwObracuni(checkNickSw);
        setCheckImeSwObracuni(checkImeSw);
        setCheckPrezimeSwObracuni(checkPrezimeSw);
      }
    }, [odabraniVozacID, searchStr, checkNickSw, checkImeSw, checkPrezimeSw]);
      
    function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
      let str = e.target.value;
      let str1 = str.toLocaleLowerCase();
      setSearchStr(str1);
      
      let sw = false;
      let sw1 = true;
      for (let i = 0; i < podaci.length; i++) {
        let el = podaci[i];
        
        if (ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw).toLowerCase().includes(str1)) {
          if (el.id.toString() === odabraniVozacID) {
            sw = true;
            break;
          }
        }
      }
      if (!sw) {
        setOdabraniVozacID("-");
        setActivatedSw(true);
      } else {
        if (sw1) {
          setActivatedSw(true);
        } else {
          setActivatedSw(false);
        }
      }
    }
  
    function odabirFun(e: React.MouseEvent<HTMLOptionElement> | React.ChangeEvent<HTMLSelectElement>) {
      setOdabraniVozacID(e.currentTarget.value);
  
      let sw1 = true;
        if (sw1) {
          setActivatedSw(true);
        } else {
          setActivatedSw(false);
        }
    }
  
    function refreshajListu(arg:{[index:string]: any}) {
      console.log("podaci za refresh: ");
      console.log(arg.pod);
      setPodaci(arg.pod);
      setOdabraniVozacID("-");
      setActivatedSw(true);
    }
  
    function setCheck(br: number) {
      if (br === 1) {
        if (checkNickSw) {
          if (provjeriChecks())  setCheckNickSw(false);
        } else {
          setCheckNickSw(true);
        }
      } else if (br === 2) {
        if (checkImeSw) {
          if (provjeriChecks())  setCheckImeSw(false);
        } else {
          setCheckImeSw(true);
        }
      } else {
        if (checkPrezimeSw) {
          if (provjeriChecks())  setCheckPrezimeSw(false);
        } else {
          setCheckPrezimeSw(true);
        }
      }
  
      function provjeriChecks() {
        let br = 0;
        if (checkNickSw) br += 1;
        if (checkImeSw) br += 1;
        if (checkPrezimeSw) br += 1;
        if (br === 1) return false;
        return true;
      }
  
    }
  
    return (
      
      <div className="holder" ref={r}>
      {status === "loading" ? <Loading sw={true}/> : <>
      <div className="vozaciIzbornik">
        {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
        <Navbar current={6} status={status} signout={()=>{setSignoutSw(true)}}/> 
        {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
        <div className="vozaci-izbornik-lijeva">
          <div className="el">
            <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
              <div className="input-div">
                <div className="input-div1"><label htmlFor="str">Upisi ime:</label></div>
                <input onChange={inputFun} value={searchStr} className="input-el" type="text" id="str" name="str" placeholder="upisi substring"/>
              </div> 
            </form>
          </div>
          <div className="el1">
            <div className="checkbox-opcije">
              <div className="checkbox-div">
                <input type="checkbox" checked={checkNickSw} onChange={()=>{setCheck(1)}} 
                   className="checkbox-el" id="checkbox-nick"/>
                <label className="checkbox-label-el" htmlFor="checkbox-Nick">Username</label>
              </div>
              <div className="checkbox-div">
                <input type="checkbox" checked={checkImeSw} onChange={()=>{setCheck(2)}}
                   className="checkbox-el" id="checkbox-ime"/>
                <label className="checkbox-label-el" htmlFor="checkbox-ime">Ime</label>
              </div>
              <div className="checkbox-div">
                <input type="checkbox" checked={checkPrezimeSw} onChange={()=>{setCheck(3)}}
                   className="checkbox-el" id="checkbox-prezime"/>
                <label className="checkbox-label-el" htmlFor="checkbox-prezime">Prezime</label>
              </div>
            </div>
            <select className="izbornik" size={5} value={odabraniVozacID} onClick={(e)=>{e.stopPropagation()}} 
               onChange={odabirFun} >
              <option value="-">bez odabira</option>
              {podaci.filter((el)=>{let ii = ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw); return ii !== "" && ii.toLowerCase().includes(searchStr)})
                .sort((el1,el2)=>{let is1 = ispisImena(el1.name,el1.ime,el1.prezime,checkNickSw,checkImeSw,checkPrezimeSw); let is2 = ispisImena(el2.name,el2.ime,el2.prezime,checkNickSw,checkImeSw,checkPrezimeSw);
                  if (is1 < is2) return -1; if (is1 > is2) return 1; return 0;})
                .map((el)=>{return <option onClick={odabirFun} value={el.id} key={el.id}>{ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw)}</option>})}
            </select>
          </div>
          <div className="el2">
            {!activatedSw ? <div onClick={()=>{setConfirmSw("activate")}} className="div-button">Activate</div> : null}
            {odabraniVozacID !== "-" ? <div onClick={()=>{setConfirmSw("erase")}} className="div-button">Erase</div> : null}
          </div>
        </div> 
        
        <div className="vozaci-izbornik-desna">
          <div className="buffer"></div>
          <TablicaObracun resetBr={resetBr} podaciVozac={podaciVozac} setPodaciVozac={setPodaciVozac} podaciTransakcije={podaciTransakcije}
            podaciPrometi={podaciPrometi} podaciVozila={podaciVozila} podaciDoprinosi={podaciDoprinosi}/>

        </div>   
      </div>
      <Footer />
      </>}
      </div>   
    )
  }