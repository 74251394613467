import React from 'react';
import ReactDOM from 'react-dom/client';
import './login.scss';
import { useFetch1 } from "./useFetch";
import { ADRESA, ADRESA1, FlashKontekst, Kontekst, localStorageSw, SettingsKontekst } from "./index";
import { Flash, FlashTip } from "./flash";
import { useNavigate } from "react-router-dom";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineTextsms } from "react-icons/md";
import './flash.scss';

type GumbTip = {
  tekst?: string;
  fun?: ()=> void;
  height?: string;
  width?: string;
  tip?: "prijava" | "sms" | "email";
}

export function Gumb({tekst="nesto", fun=()=>{}, 
  height="50px", width="80px", tip="prijava"}: GumbTip) {
  
  const r = React.useRef<HTMLDivElement>(null);
  const r1 = React.useRef<number | null>(null);
  const [gumbKlasa, setGumbKlasa] = React.useState("gumb gumb-nekliknuti")

  React.useEffect(()=>{
    return ()=>{if (r1.current) clearTimeout(r1.current)};
  }, []);

  function klik(): void {
    fun();
    setGumbKlasa("gumb gumb-kliknuti");
    r1.current = window.setTimeout(()=>{
      setGumbKlasa("gumb gumb-nekliknuti");
    }, 200);
  }

  return (
    <div ref={r} style={{"height":height, "width":width}} onClick={klik} className={gumbKlasa}>
      {tip === "prijava" ?
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="gumb-ikona bi bi-box-arrow-in-right" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
          <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
        </svg> : null}
      {tip === "sms" ? <MdOutlineTextsms className="gumb-ikona" /> : null}  
      {tip === "email" ? <AiOutlineMail className="gumb-ikona" /> : null}  
      <div>{tekst}</div>
    </div>
  )
}

type SpinnerProps = {
  kut?: number;
}

export function Spinner({kut=90} : SpinnerProps) {
  const [rez, setRez] = React.useState("");

  React.useEffect(()=>{

    let s = Math.pow(2, 0.5) * Math.sin(3.14159/180*kut/2);
    let c = Math.pow(2, 0.5) * Math.cos(3.14159/180*kut/2)
    let fak = 1.03;
    setRez("polygon(" + 50*fak*(1-s) + "% " + 50*fak*(1-c) + "%, " + "50% 50%, " + 50*fak*(1+s) + "% " + 50*fak*(1-c) + "%)");

  }, [kut]);

  return (
    <div className="spinner">
      <div style={{"clipPath":rez}} className="spinner-el">
      </div>
      <div className="spinner-el1"></div>
    </div>
  )
}

type SigninProps = {
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
  tipRacuna?: "VOZAC" | "ADMIN";
}

export function Signin({setStatus=()=>{}, tipRacuna="VOZAC"} : SigninProps) {
  const [lozinka, setLozinka] = React.useState("");
  const [login, setLogin] = React.useState("");
  const [br, setBr] = React.useState(0);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {setKljuc, setRefreshKljuc} = React.useContext(Kontekst);
                   
  const [loading, error, value] = useFetch1(ADRESA1 + '/api/signin', 
    {
      method: 'POST',
      body: JSON.stringify({
        "login": login,
        "password": lozinka,
        "tip": tipRacuna
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);

  React.useEffect(()=>{
    if (br !== 0) {
        console.log("poslao si upit " + br);
    }

    console.log("loading1: " + loading);
    console.log("error1: " + error);
    console.log("value1: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      console.log("poslao sam upit serveru " + Math.random());
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        
        setStatus("sign-in");
        if (value.errorCode === "Pokusavate se ulogirati kao VOZAC") {
          postaviFlashPoruku("Pokusavate se ulogirati sa vozackim accountom u admin stranicu", "danger", "signin");
        } else if (value.errorCode === "Pokusavate se ulogirati kao ADMIN") {
          postaviFlashPoruku("Pokusavate se ulogirati sa admin accountom u vozacevu stranicu", "danger", "signin");
        } else {
          postaviFlashPoruku(value.errorCode, "danger", "signin");
        }
        
      } else if (!loading && !value.error){
        console.log("ULOGIRANI ste");
        console.log(value.errorCode);
        setKljuc(value.value.token);
        setRefreshKljuc(value.value.refreshToken);
        
        setStatus("logged-in")
      }
    }
  }, [loading]);

  function fun() {
    if (lozinka.length < 6) {
      postaviFlashPoruku("Lozinka mora imati barem 6 znakova", "danger", "signin");
      console.log("Lozinka mora imati barem 6 znakova", "danger", "signin");
      return;
    } else {
      let swBroj = false;
      let swSlovo = false;
      let swBlank = false;
      for (let i = 0; i < lozinka.length; i++) {
        let znak = lozinka[i];
        if (znak == " ") {
          swBlank = true;
          break;
        } 
        switch (znak) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            swBroj = true;
        }
        if (znak >= "a" && znak <= "z")  swSlovo = true;
        if (znak >= "A" && znak <= "Z")  swSlovo = true;
      }

      if (swBlank) {
        postaviFlashPoruku("Lozinka ne smije imati prazan znak", "danger", "signin");
        console.log("Lozinka ne smije imati prazan znak", "danger", "signin");
        return;
      }
      if (!swSlovo) {
        postaviFlashPoruku("Lozinka mora imati bar jedno slovo", "danger", "signin");
        console.log("Lozinka mora imati bar jedno slovo", "danger", "signin");
        return;
      }
      if (!swBroj) {
        postaviFlashPoruku("Lozinka mora imati bar jedan broj", "danger", "signin");
        console.log("Lozinka mora imati bar jedan broj", "danger", "signin");
        return;
      }
      if (login === "") {
        postaviFlashPoruku("Ime/email ne moze biti prazno", "danger", "signin");
        console.log("Ime/email ne moze biti prazno", "danger", "signin");
        return;
      } else {
        for (let i = 0; i < login.length; i++) {
          if (login[i] === " ") {
            postaviFlashPoruku("Ime/email ne moze sadrzavati razmak", "danger", "signin");
            console.log("Ime/email ne moze sadrzavati razmak", "danger", "signin");
            return;
          }
        }
      }
    }

    setBr((prev)=>{return (prev+1)});
  }

  function klikFun() {
    console.log("upravo si kliknuo na ikonu... " + Math.random());
  }

  return (
    <div className="signin">
      <div className="signin-ploca">
         {loading ? <Spinner/> : null}
          <div className="flash-container gore">
            {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="signin" kod2={el.kod} />})}
          </div>
          <div className="lista-naslov">Sign in</div>
          
  
          <div className="lista-div">
            <label htmlFor="input0">Korisničko ime / email:</label><br/>
            <input type="text" className="input1" onChange={(el)=>{setLogin(el.target.value)}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
              value={login} id="input0" placeholder="upišite korisničko ime ili email"/>
          </div>  
  
          <div className="lista-div">
            <label htmlFor="input0">Lozinka:</label><br/>
            <input type="password" className="input1" onChange={(el)=>{setLozinka(el.target.value)}} value={lozinka} id="input0" placeholder="upišite lozinku"/>
          </div> 
          
          <div className="lista-div1">
            {tipRacuna === "VOZAC" ? <div className="user" onClick={()=>{setStatus("sign-up")}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="person-ikona bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
              </svg> 
            </div> : null}
            <Gumb fun={fun} tekst="prijava" height="48px" width="70%" />
          </div>

          {tipRacuna === "VOZAC" ?
            <div className="forgot-password-div">
              <HiQuestionMarkCircle className="forgot-password-ikona" onClick={()=>{setStatus("forgot password")}}/>
              <div className="div-natpis">nova lozinka</div>
            </div> : null }
      </div>
    </div>
  )
}

type ForgotPasswordProps = {
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
}

export function ForgotPassword({setStatus=()=>{}}: ForgotPasswordProps) {
  const [login, setLogin] = React.useState("");
  const [br, setBr] = React.useState(0);
  const [tip, setTip] = React.useState<"sms" | "email">("sms");
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/forgotten_password', 
    {
      method: 'POST',
      body: JSON.stringify({
        "login": login,
        "tip": tip
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);
  
  React.useEffect(()=>{
    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) { 
        if (value.errorCode === "Username nije pronaden") {
          postaviFlashPoruku("Zadani username nije pronaden.", "danger", "forgot-password");
        } else {
          postaviFlashPoruku("Dogodila se pogreska. Kontaktirajte administratora.", "danger", "forgot-password");
          console.log(value.errorCode);
        }
      } else {
        postaviFlashPoruku("Poslali smo vam lozinku. Sada cemo vas preusmjeriti na sign-in link", "success", "forgot-password");
        setTimeout(()=>{ setStatus("sign-in");}, 4000);

      }
    }
  }, [loading]);

  function fun(str: "email" | "sms") {
    //postaviFlashPoruku("Upravo saljemo vasu novu lozinku", "success", "forgot-password");
    setTip(str);
    setBr((prev)=>{return prev+1});
  }

  return (
    <div className="popup-plast">
      <div className="forgot-password">
        {loading ? <Spinner/> : null}
        <div className="flash-container gore">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="forgot-password" kod2={el.kod} />})}
        </div>
        <div className="lista-naslov">Password reset</div>

        <div className="lista-div">
          <label htmlFor="input0">Korisničko ime / email:</label><br/>
          <input type="text" className="input1" onChange={(el)=>{setLogin(el.target.value)}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
            value={login} id="input0" placeholder="upišite korisničko ime ili email"/>
        </div>

        <p className="forgot-password-p1">Odaberite metodu slanja resetirane lozinke</p>
        <div className="gumbi">
          <div className="user" onClick={()=>{setStatus("sign-in")}}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="person-ikona bi bi-person" viewBox="0 0 16 16">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
            </svg> 
          </div>
          <Gumb fun={()=>{fun("email")}} tekst="email" height="48px" width="90px" tip="email" />
          <Gumb fun={()=>{fun("sms")}} tekst="sms" height="48px" width="90px" tip="sms" />
        </div>
      </div>
    </div>
  )
}

type SignupProps = {
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
}

export function Signup({setStatus=()=>{}}: SignupProps) {

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [lozinka, setLozinka] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {setKljuc, setRefreshKljuc} = React.useContext(Kontekst);
  const [br, setBr] = React.useState(0);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/signup', 
    {
      method: 'POST',
      body: JSON.stringify({
        "email": email,
        "username": username,
        "password": lozinka,
        "phone_number": phoneNumber
      }),
      headers: {
        'Content-type': 'application/json'
      }
    }, [br]);

    React.useEffect(()=>{
      console.log("loading3: " + loading);
      console.log("error3: " + error);
      console.log("value3: ");
      console.log("br: " + br);
      console.log(value);
  
      if (br !== 0 && value !== undefined && typeof value !== "boolean") {
        if (value.error) {
          console.log("GRESKA");
          console.log(value.errorCode);
          if (value.errorCode === "Vas account treba biti odobren. Molimo kontaktirajte administratora.") {
            setStatus("activate");
            //postaviFlashPoruku("VaS racun mora biti odobren. Molimo kontaktirajte administratora.", "danger", "signup");
          } else {
            setStatus("sign-up");
            postaviFlashPoruku(value.errorCode, "danger", "signup");
          }
        } else if (!loading && !value.error){
          console.log("ULOGIRANI ste");
          console.log(value.errorCode);
          setKljuc(value.value.token);
          setRefreshKljuc(value.value.refreshToken);
          setStatus("logged-in");
        }
      }
    }, [loading]);

  function fun() {
    let regex = /\A[\w+\-.]+@[a-z\d\-.]+\.[a-z]+\z/i
    regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    //console.log("pokrenuo " + Math.random());

    if (lozinka.length < 6) {
      postaviFlashPoruku("Lozinka mora imati barem 6 znakova", "danger", "signup");
      return;
    } else if (lozinka !== confirm) {
      postaviFlashPoruku("Lozinka i njena potvrda nisu identične.", "danger", "signup");
      return;
    } else {
      let swBroj = false;
      let swSlovo = false;
      let swBlank = false;
      for (let i = 0; i < lozinka.length; i++) {
        let znak = lozinka[i];
        if (znak == " ") {
          swBlank = true;
          break;
        } 
        switch (znak) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            swBroj = true;
        }
        if (znak >= "a" && znak <= "z")  swSlovo = true;
        if (znak >= "A" && znak <= "Z")  swSlovo = true;
      }

      if (swBlank) {
        postaviFlashPoruku("Lozinka ne smije imati prazan znak", "danger", "signup");
        return;
      }
      if (!swSlovo) {
        postaviFlashPoruku("Lozinka mora imati bar jedno slovo", "danger", "signup");
        return;
      }
      if (!swBroj) {
        postaviFlashPoruku("Lozinka mora imati bar jedan broj", "danger", "signup");
        return;
      }
      if (!regex.test(email)) {
        postaviFlashPoruku("Niste upisali dobar email. Probajte ponovo", "danger", "signup");
        return;
      }

      if (username.trim() !== username) {
        postaviFlashPoruku("Ne mozete koristiti razmak na pocetku ili kraju stringa. Probajte ponovo", "danger", "signup");
        return;
      } else {
        let slovo = false;
        for (let i = 0; i < username.length; i++) {
          if (username[i].match(/[a-z]/i)) slovo = true;
          if (username[i] === " ") {
            postaviFlashPoruku("Korisnicko ime mora biti jedna rijec. Probajte ponovo", "danger", "signup");
            return;
          }
        }
        if (!slovo) {
          postaviFlashPoruku("Korisnicko ime mora imati barem jedno slovo. Probajte ponovo", "danger", "signup");
          return;
        }
      }

      let brZnamenki = 0;
      petlja: for (let i = 0; i < phoneNumber.length; i++) {
        switch (phoneNumber[i]) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            brZnamenki += 1;
            break;
          case " ":
            break;
          default:
            postaviFlashPoruku("Broj mobitela nije ispravan", "danger", "signup");
            break petlja;
        }
      }
      if (brZnamenki < 6 || brZnamenki > 14) {
        postaviFlashPoruku("Broj mobitela mora imati 6-14 znamenki", "danger", "signup");
      }
    }
    setBr((prev)=>{return (prev+1)});
  }

  function ponisti(sw: boolean) {
    console.log("pokrenuo si ponisti funkciju " + Math.random);
  }

  return (
    <div className="popup-plast">
        <div className="popup-signup">
          {loading ? <Spinner/> : null}
          <div className="flash-container">
            {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="signup" kod2={el.kod} />})}
          </div>
          
          <div className="lista-naslov-1">Sign up</div>
          <div className="lista-div-1">
            <label htmlFor="input0">Korisničko ime:</label><br/>
            <input type="text" className="input1-1" onChange={(el)=>{setUsername(el.target.value)}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
              value={username} id="input0" placeholder="upišite korisničko ime"/>
          </div>  

          <div className="lista-div-1">
            <label htmlFor="input0">Korisnikov mob:</label><br/>
            <input type="text" className="input1-1" onChange={(el)=>{setPhoneNumber(el.target.value)}} value={phoneNumber} id="input0" placeholder="upišite vaš mobitel"/>
          </div> 
  
          <div className="lista-div-1">
            <label htmlFor="input0">Korisnikov email:</label><br/>
            <input type="text" className="input1-1" onChange={(el)=>{setEmail(el.target.value)}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
              value={email} id="input0" placeholder="upišite korisnikov email"/>
          </div>
  
          <div className="lista-div-1">
            <label htmlFor="input0">Lozinka:</label><br/>
            <input type="password" className="input1-1" onChange={(el)=>{setLozinka(el.target.value)}} value={lozinka} id="input0" placeholder="upišite lozinku"/>
          </div> 
  
          <div className="lista-div-1">
            <label htmlFor="input0">Potvrdite Lozinku:</label><br/>
            <input type="password" className="input1-1" onChange={(el)=>{setConfirm(el.target.value)}} value={confirm} id="input0" placeholder="upišite ponovo lozinku"/>
          </div> 
            
          <div className="lista-div1-1">
            <div className="user-1" onClick={()=>{console.log("klik " + Math.random());setStatus("sign-in")}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="person-ikona bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
              </svg> 
            </div>
            <Gumb fun={fun} tekst="prijava" height="48px" width="70%" />
          </div>
          
        </div>
      </div>
  )
}

type ActivationObavijestTip = {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

export function ActivationObavijest({setStatus=()=>{}}:ActivationObavijestTip) {
  const [br, setBr] = React.useState(0);
  const {kljuc, setKljuc, refreshKljuc, setRefreshKljuc} = React.useContext(Kontekst);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const navigate = useNavigate();

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/signout', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    if (!loading && error === undefined && value !== undefined &&
         typeof value !== "boolean") {
      if (value.error) {
        console.log("Signout1 nije bio uspijesan. Pokusajte ponovo.", "danger", "signout");
        setTimeout(()=>{setStatus("sign-in")}, 2000);
      } else {
        setKljuc("");
        setRefreshKljuc("");
        setTimeout(()=>{setStatus("sign-in"); navigate(ADRESA + "/");}, 2000);
      }
    }

  }, [loading, error, value]);

  React.useEffect(()=>{
    setTimeout(()=>{
      setBr((prev)=>{return prev+1});
      console.log("Odlogiramo se upravo... " + Math.random());
    }, 5000);
  }, []);

  return (
    <div className="popup-plast">
      <div className="popup-activation-info">
        <p className="obavijest">Ovaj account prvo treba biti aktiviran. Molim Vas obavijestite administratora.</p>
      </div>
    </div>
  )
}

type SignoutProps = {
  ponisti?: React.Dispatch<React.SetStateAction<boolean>>;
  signin?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Signout({ponisti=()=>{}}: SignoutProps) {
  const [sw, setSw] = React.useState(false);
  const [br, setBr] = React.useState(0);
  const {kljuc, setKljuc, refreshKljuc, setRefreshKljuc} = React.useContext(Kontekst);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const navigate = useNavigate();

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/signout', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    console.log("Loading: " + loading);
    console.log("Error: " + error);
    console.log("Value: ");
    console.log(value);

    if (!loading && error === undefined && value !== undefined &&
         typeof value !== "boolean") {
      if (value.error) {
        postaviFlashPoruku("Signout nije bio uspijesan. Pokusajte ponovo.", "danger", "signout");
        setTimeout(()=>{ponisti(false)}, 2000);
      } else {
        /*if (localStorageSw) {
          localStorage.setItem("kljuc", "");
          localStorage.setItem("refreshKljuc", "");
        } else {
          sessionStorage.setItem("kljuc", "");
          sessionStorage.setItem("refreshKljuc", "");
        }*/
        setKljuc("");
        setRefreshKljuc("");
        postaviFlashPoruku("Upravo ste se odjavili. Sada vas preusmjeravam.", "success", "signout");
        setTimeout(()=>{ponisti(false); navigate(ADRESA + "/");}, 2000);
      }
    }

  }, [loading, error, value]);

  function fun(e: string) {
    if (e === "yes") {
      setSw(true);
      setBr((prev)=>{return (prev+1)});
    } else {
      ponisti(false);
    }
  }

  return (
    <div className="popup-plast">
      <div className="popup-signout">
        {sw ? <>
          <div className="naslov">You are currently signing out. Please wait...</div>
          <Spinner/>
        </> :
           <>
             <div className="naslov">You are about to leave. Are you sure?</div>
             <div className="gumbi">
               <Gumb fun={()=>{fun("no")}} tekst="No" height="30px" width="60px" />
               <Gumb fun={()=>{fun("yes")}} tekst="Yes" height="30px" width="60px" />
             </div>
           </>}
      </div>
    </div>
  )
}

type PhoneVerificationProps = {
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
  resetBr?: React.Dispatch<React.SetStateAction<number>>;
}

export function PhoneVerification({resetBr=()=>{}, setStatus=()=>{}}: PhoneVerificationProps) {
  const [code, setCode] = React.useState("");
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [br2, setBr2] = React.useState(0);
  const [action, setAction] = React.useState("");
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {kljuc, setKljuc, setRefreshKljuc} = React.useContext(Kontekst);
  const [sw, setSw] = React.useState(true);
  const navigate = useNavigate();
  const { verificationType } = React.useContext(SettingsKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/verify', 
  {
    method: 'POST',
    body: JSON.stringify({
      "code": code,
      "token": kljuc,
      "akcija": action
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  // kod za signout nakon sto si izvrsio verifikaciju
  const [loading1, error1, value1] = useFetch1(ADRESA1 + '/api/signout', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br2]);

  React.useEffect(()=>{
    
    if (br2 !== 0 && !loading && error === undefined && value !== undefined &&
         typeof value !== "boolean") {
      if (value.error) {
        //postaviFlashPoruku("Signout nije bio uspijesan. Pokusajte ponovo.", "danger", "verify1");
      } else {
        /*if (localStorageSw) {
          localStorage.setItem("kljuc", "");
          localStorage.setItem("refreshKljuc", "");
        } else {
          sessionStorage.setItem("kljuc", "");
          sessionStorage.setItem("refreshKljuc", "");
        }*/
        
        //postaviFlashPoruku("Upravo ste se odjavili. Sada vas preusmjeravam.", "success", "signout");
        setTimeout(()=>{
          setKljuc("");
          setRefreshKljuc("");
          setStatus("logged-in")
          navigate(ADRESA + "/");}, 4000);
      }
    }

  }, [loading1, error1, value1]);

  React.useEffect(()=>{
    console.log("loading4: " + loading);
    console.log("error4: " + error);
    console.log("value4: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        if (value.errorCode === "Kod nije ispravan") {
          postaviFlashPoruku("Unijeli ste neispravan kod", "danger", "verify");
        } else {
          postaviFlashPoruku(value.errorCode, "danger", "verify");
        }
        
      } else if (!loading && !value.error){
        console.log("ULOGIRANI ste");
        if (action === "verificiraj") {
          //setStatus("logged-in");
          if (value.value.activatedSw === "YES") {
            setStatus("logged-in");
            resetBr((prev)=>{return prev+1});
          } else {
            setSw(false);
            setTimeout(()=>{
              setBr2((prev)=>{return prev+1});
            }, 5000);
          }
        }
        if (action === "obrisi")  setStatus("sign-up");
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (action !== "") {
      setBr((prev)=>{return prev+1});
    }
  }, [action, br1]);

  React.useEffect(()=>{
    console.log("Verifikacijski tip je postavljen na " + verificationType);
  }, [verificationType])

  function unos(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    if (str !== "") {
      switch (str[str.length-1]) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          setCode(str);
          break;
        default:
          postaviFlashPoruku("Ilegalni znak, dopusteni su samo brojevi", "danger", "verify");
      }
    } else {
      setCode("");
    }

  }

  function sendFun() {
    if (code.length !== 6) {
      postaviFlashPoruku("Kod mora imati tocno 6 znamenki.", "danger", "verify");
    } else {
      setBr1((prev)=>{return prev+1})
      setAction("verificiraj");
    }
  }

  function noviKod() {
    setBr1((prev)=>{return prev+1})
    setAction("novi kod");
    if (verificationType === "email") {
      postaviFlashPoruku("Saljemo vam novi kod na Vas email.", "success", "verify");
    } else {
      postaviFlashPoruku("Saljemo vam novi sms kod.", "success", "verify");
    }
  }

  function obrisi() {
    setBr1((prev)=>{return prev+1})
    setAction("obrisi");
    postaviFlashPoruku("Vracate se na pocetak sign-up procesa.", "danger", "verify");
  }

  return (
    <div className="popup-plast">
      {sw ?
      <div className="popup-verification">
        {loading ? <Spinner/> : null}
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="verify" kod2={el.kod} />})}
        </div>
        <div className="lista-naslov">{verificationType === "email" ? "Poslali smo vam email poruku sa kodom. Molim vas da ga ovdje upišete." :
           "Poslali smo vam sms poruku sa kodom na vaš mobitel. Molim vas da ga ovdje upišete."}</div>
        <div className="lista-div">
          <input type="text" className="input" onChange={unos} value={code} id="input0" placeholder="upisite kod"/>
          <div className="gumb" onClick={sendFun}>Enter</div>
        </div>
        <div className="lista-naslov">Ukoliko niste dobili poruku, pričekajte do jedne minute, pa kliknite za ponovno slanje.</div>
        <div className="element">
          <div className="gumb1" onClick={noviKod}>Šalji novi kod</div>
          <div className="gumb1" onClick={obrisi}>Vrati se natrag</div>
        </div>
      </div> : 

      <div className="popup-obavijest">
        <p className="p-el">Ovaj račun prvo treba biti verificiran od strane administratora. Molim Vas da ga kontaktirate.</p>
      </div>}
        
    </div>
  )
}
