import React from 'react';
import ReactDOM from 'react-dom/client';
import './prometi.scss';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Loading, ispisImena, novoPolje, ispis, ispisId, ukloniPrazneZnakove, filterStr, tipProvizijeKod } from "./notFound";
import { ADRESA1, FlashKontekst, Kontekst, StateKontekst, SettingsKontekst } from "./index";
import { useFetch1 } from "./useFetch";
import { Signin, Signout, Gumb, Spinner } from "./login";
import { brDanaOdDatuma, Kalendar, dodajNulu } from "./kalendar";
import { FlashTip, Flash } from "./flash";

function ispisiTjedniInterval(datum: string) {
    // datum je oblika 2023-07-18
    let pod = datum.split("-");
    let rez = brDanaOdDatuma(6, parseInt(pod[0]), parseInt(pod[1]), parseInt(pod[2]));
    return pod[2] + "." + pod[1] + "." + pod[0] + ". - " + dodajNulu(rez[0]) + "." + dodajNulu(rez[1]) + "." + rez[2];
}

type Promet = {
  id: number;
  datum: string;
  tip_provizije: "provizija" | "postotak" | "fiksni";
  provizija: number;
  uber_promet: number | null;
  uber_cash: number | null;
  bolt_promet: number | null;
  bolt_cash: number | null;
}

type PodaciTip = {
  id: number;
  name: string;
  ime: string;
  prezime: string;
  prometi: Promet[];
}

type Tip = "ADD" | "MODIFY" | "SHOW";

type AliasTip = {
  id: number;
  id_vozaca: number;
  alias: string;
  uberSw: true | null;
  boltSw: true | null;
}

export function PrometiIzbornik() {
  const [searchStr, setSearchStr] = React.useState("");
  const [status, setStatus] = React.useState("loading");
  const [signoutSw, setSignoutSw] = React.useState(false);
  const [checkImeSw, setCheckImeSw] = React.useState(true);
  const [checkPrezimeSw, setCheckPrezimeSw] = React.useState(true);
  const [checkNickSw, setCheckNickSw] = React.useState(true);
  const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
  const [odabraniPrometID, setOdabraniPrometID] = React.useState("-");
  const [podaci, setPodaci] = React.useState<PodaciTip[]>([]);
  const [aliases, setAliases] = React.useState<AliasTip[]>([]);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [action, setAction] = React.useState("");
  const { kljuc } = React.useContext(Kontekst);
  const [confirmSw, setConfirmSw] = React.useState("");
  const [tip, setTip] = React.useState<Tip>("SHOW");
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const [pomak, setPomak] = React.useState(0);
  const {odabraniVozacIDPrometi, setOdabraniVozacIDPrometi, odabraniPrometIDPrometi, setOdabraniPrometIDPrometi,  
    checkNickSwPrometi, setCheckNickSwPrometi, checkImeSwPrometi, setCheckImeSwPrometi, checkPrezimeSwPrometi, 
    setCheckPrezimeSwPrometi, searchStrPrometi, setSearchStrPrometi} = React.useContext(StateKontekst);

  const r = React.useRef<HTMLDivElement | null>(null);
  const r1 = React.useRef<HTMLDivElement | null>(null);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/admin/prometi', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": action
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading5: " + loading);
    console.log("error5: " + error);
    console.log("value5: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        setStatus("sign-in");
        setPodaci([]);
        setAliases([]);
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setStatus("logged-in");
        setPodaci(value.value.podaci);
        setAliases(value.value.aliases);
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (action !== "") {
      setBr((prev)=>{return prev+1});
    }
  }, [action, br1]);

  React.useEffect(()=>{
    setOdabraniPrometID("-");
  }, [odabraniVozacID]);

  React.useEffect(()=>{
    if (kljuc !== "-") setBr1((prev)=>{return prev+1});
  }, [kljuc]);

  React.useEffect(()=>{
    setAction("ucitaj");
  }, []);

  React.useEffect(()=>{
    if (tip === "SHOW") {
      if (r.current !== null) r.current.style.display = "none";
    } else {
      if (r.current !== null) r.current.style.display = "block";
    }
  }, [tip]);

  React.useEffect(()=>{
    if (status !== "logged-in") {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setPomak(scrollTop);
      if (r1.current !== null)  r1.current.style.position = "fixed";
      if (r1.current !== null)  r1.current.style.top = -1*scrollTop + "px";
    } else {
      if (r1.current !== null)  r1.current.style.top = "0px";
      if (r1.current !== null)  r1.current.style.position = "relative";
      window.scrollTo(0, pomak);
    }
  }, [status]);

  React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
    if (br === 0) {
      setOdabraniVozacID(odabraniVozacIDPrometi);
      setOdabraniPrometID(odabraniPrometIDPrometi);
      setSearchStr(searchStrPrometi);
      setCheckNickSw(checkNickSwPrometi);
      setCheckImeSw(checkImeSwPrometi);
      setCheckPrezimeSw(checkPrezimeSwPrometi);
    } else {
      setOdabraniVozacIDPrometi(odabraniVozacID);
      setOdabraniPrometIDPrometi(odabraniPrometID);
      setSearchStrPrometi(searchStr);
      setCheckNickSwPrometi(checkNickSw);
      setCheckImeSwPrometi(checkImeSw);
      setCheckPrezimeSwPrometi(checkPrezimeSw);
    }
  }, [odabraniVozacID, odabraniPrometID, searchStr, checkNickSw, checkImeSw, checkPrezimeSw]);

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    
      let str = e.target.value;
      let str1 = str.toLocaleLowerCase();
      setSearchStr(str1);
    
      let sw = false;
      for (let i = 0; i < podaci.length; i++) {
        let el = podaci[i];
      
        if (ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw).toLowerCase().includes(str1)) {
          if (el.id.toString() === odabraniVozacID) {
            sw = true;
            break;
          }
        }
      }
      if (!sw) {
        setOdabraniVozacID("-");
      }
    
  }

  function setCheck(br: number) {
    if (br === 1) {
      if (checkNickSw) {
        if (provjeriChecks())  setCheckNickSw(false);
      } else {
        setCheckNickSw(true);
      }
    } else if (br === 2) {
      if (checkImeSw) {
        if (provjeriChecks())  setCheckImeSw(false);
      } else {
        setCheckImeSw(true);
      }
    } else {
      if (checkPrezimeSw) {
        if (provjeriChecks())  setCheckPrezimeSw(false);
      } else {
        setCheckPrezimeSw(true);
      }
    }
  
    function provjeriChecks() {
      let br = 0;
      if (checkNickSw) br += 1;
      if (checkImeSw) br += 1;
      if (checkPrezimeSw) br += 1;
      if (br === 1) return false;
      return true;
    }
  }

  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>, br: number) {
    
      if (br === 0) {
        setOdabraniVozacID(e.currentTarget.value);
      } else {
        setOdabraniPrometID(e.currentTarget.value);
      }
  }

  function klik(e:React.MouseEvent<HTMLDivElement>, akcija:string = "") {
    let el = e.currentTarget;
    el.classList.add("klik");
    setTimeout(()=>{el.classList.remove("klik")}, 150);

    if (el.id === "gumb-erase") {
      setConfirmSw("delete");
    } else if (el.id === "gumb-add") {
      setTip("ADD");
      setOdabraniPrometID("-");
      postaviFlashPoruku("Unesite novi tjedni promet u tablicu", "success", "tablica-prometi");
    } else if (el.id === "gumb-modify") {
      setTip("MODIFY");
      postaviFlashPoruku("Unesite modifikacije odabranog tjedna u tablicu", "success", "tablica-prometi");
    } else if (el.id === "gumb-csv-input") {
      setConfirmSw("automatic_input")
      postaviFlashPoruku("Copy-pastom unesite Uberov ili Boltov csv file", "success", "automatski-unos");
    }
  }

  return (
    <div className="holder" ref={r1}>
    {status === "loading" ? <Loading sw={true}/> : <>
      <div className="prometi-izbornik-aa">
        {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
        <Navbar current={4} status={status} signout={()=>{setSignoutSw(true)}}/> 
        {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
        <div className="prometi-izbornik-lijeva">
          <div className="el">
            <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
              <div className="input-div">
                <div className="input-div1"><label htmlFor="str">Upisi ime:</label></div>
                <input onChange={inputFun} value={searchStr} className="input-el" type="text" id="str" name="str" placeholder="upisi substring"/>
              </div> 
            </form>
          </div>
          <div className="el1">
            <div className="checkbox-opcije">
              <div className="checkbox-div">
                <input type="checkbox" checked={checkNickSw} onChange={()=>{setCheck(1)}} 
                   className="checkbox-el" id="checkbox-nick"/>
                <label className="checkbox-label-el" htmlFor="checkbox-Nick">Username</label>
              </div>
              <div className="checkbox-div">
                <input type="checkbox" checked={checkImeSw} onChange={()=>{setCheck(2)}}
                   className="checkbox-el" id="checkbox-ime"/>
                <label className="checkbox-label-el" htmlFor="checkbox-ime">Ime</label>
              </div>
              <div className="checkbox-div">
                <input type="checkbox" checked={checkPrezimeSw} onChange={()=>{setCheck(3)}}
                   className="checkbox-el" id="checkbox-prezime"/>
                <label className="checkbox-label-el" htmlFor="checkbox-prezime">Prezime</label>
              </div>
            </div>
            <select className="izbornik" size={5} value={odabraniVozacID} onClick={(e)=>{e.stopPropagation()}} 
               onChange={(e)=>{odabirFun(e,0)}} >
              <option value="-">bez odabira</option>
              {podaci.filter((el)=>{let ii = ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw); return ii !== "" && ii.toLowerCase().includes(searchStr)})
                .sort((el1,el2)=>{let is1 = ispisImena(el1.name,el1.ime,el1.prezime,checkNickSw,checkImeSw,checkPrezimeSw); let is2 = ispisImena(el2.name,el2.ime,el2.prezime,checkNickSw,checkImeSw,checkPrezimeSw);
                  if (is1 < is2) return -1; if (is1 > is2) return 1; return 0;})
                .map((el)=>{return <option value={el.id} key={el.id}>{ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw)}</option>})}
            </select>
          </div>

          <div className="el1">
            <select className="izbornik" size={5} value={odabraniPrometID} onClick={(e)=>{e.stopPropagation()}} 
               onChange={(e)=>{odabirFun(e,1)}} >
              <option value="-">bez odabira</option>
              {podaci.find((el)=>{if (el.id.toString() === odabraniVozacID) return true; return false;})?.prometi
              .sort((el1, el2)=>{if (el1.datum < el2.datum) return 1; if (el1.datum === el2.datum) return 0; return -1;})
              .map((el)=>{return <option value={el.id} key={el.id}>{ispisiTjedniInterval(el.datum)}</option>})}
            </select>

            <div className="el1-gumbi">
              {odabraniVozacID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-add" className="div-button">Add</div> : null}
              {odabraniPrometID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-modify" className="div-button">Modify</div> : null}
              {odabraniPrometID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-erase" className="div-button">Erase</div> : null}
            </div>
          </div>
          <div ref={r} className="antiklik-pokrivac"></div>
        </div>
        <div className="prometi-izbornik-desna">
          <div className="buffer"></div>
          <div className="button-cont">
            <div onClick={(e)=>{klik(e)}} id="gumb-csv-input" className="div-button">CSV Input</div>
          </div>
          <TablicaPrometi odabraniVozacID={odabraniVozacID} odabraniPrometID={odabraniPrometID} 
            podaci={podaci} setPodaci={setPodaci} tip={tip} setTip={setTip}/>
        </div> 
        {confirmSw === "delete" ? <ConfirmPrometi br={1} setConfirmSw={setConfirmSw} tekst="Upravo cete OBRISATI odabrani tjedni promet. Da li ste sigurni?" 
           payload={{"id": odabraniPrometID}} setOdabraniPrometID={setOdabraniPrometID} setPodaci={setPodaci} objasnjenje="BRISEM tjedni promet. Pricekajte..."/> : null}
        {confirmSw === "automatic_input" ? <AutomatskiUnosPopUp setPodaci={setPodaci} setConfirmSw={setConfirmSw} aliases={aliases}/> : null}
      </div>
      <Footer />
      </>}
    </div>
  )
}

type AutomatskiUnosPopUpTip = {
  setConfirmSw?: React.Dispatch<React.SetStateAction<string>>;
  path?: string;
  aliases?: AliasTip[];
  setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
}

type PrometTip = {
  idVozaca?: number;
  datum: string;
  ime: string;
  /*provizija: number;*/
  uberPromet: null | number;
  uberCash: null | number;
  boltPromet: null | number;
  boltCash: null | number;
}


function AutomatskiUnosPopUp({setPodaci=()=>{}, setConfirmSw=()=>{}, path="/api/admin/automatski_prometi", aliases=[]}: AutomatskiUnosPopUpTip) {
  const [br, setBr] = React.useState(0);
  const [strInput, setStrInput] = React.useState<null | string>(null);
  const [checkUberSw, setCheckUberSw] = React.useState(false);
  const [checkBoltSw, setCheckBoltSw] = React.useState(false);
  const [prometi, setPrometi] = React.useState<PrometTip[]>([]);
  const [greske, setGreske] = React.useState<string[]>([]);
  const [greskeBaze, setGreskeBaze] = React.useState<number[]>([]);
  const [rezSw, setRezSw] = React.useState(false);
  const [platforma, setPlatforma] = React.useState<null | "uber" | "bolt">(null);
  const [akcija, setAkcija] = React.useState<"dodaj" | "brisi">("dodaj");

  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const { kljuc } = React.useContext(Kontekst);
  const { provizija } = React.useContext(SettingsKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "platforma": platforma,
        "prometi": prometi,
        "provizija": provizija
      }),
      headers: {
        'Content-type': 'application/json'
      }
  }, [br]);

  React.useEffect(()=>{
    console.log("aliases postavljeni na:");
    console.log(aliases);
    console.log("==================================");
    console.log(prometi);
    console.log(greske);
  }, [aliases, prometi, greske]);

  React.useEffect(()=>{
    console.log("loading10: " + loading);
    console.log("error10: " + error);
    console.log("value10: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
      } else if (!loading && !value.error){
        setPodaci(value.value.podaci);
        setGreskeBaze(value.value.rez);
        console.log("novi podaci su ");
        console.log(value.value.podaci);
        setRezSw(true);
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (checkUberSw) {
      setPlatforma("uber");
    } else {
      if (checkBoltSw) {
        setPlatforma("bolt");
      } else {
        setPlatforma(null);
      }
    }
  }, [checkUberSw, checkBoltSw]);

  function unesiStr(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, 
    setStr: React.Dispatch<React.SetStateAction<string | null>>, maxStr: number = 1024, 
    upperCase: boolean = false) : void {
  
    let str = e.currentTarget.value;
    if (upperCase) str = str.toUpperCase();

    if (str === null || str === "") {
      setStr(null);
    } else {
      if (str.length <= maxStr) {
        setStr(str);
      } else {
        postaviFlashPoruku("Za ovaj field maksimalna duljina je 256kB.", "danger", "automatski-unos");
      }
    }
  }

  function zatvoriFun() {
    if (!loading) {
      setConfirmSw("");
    }
  }

  function setCheck(br: number): void  {
    if (br === 1) {
      if (!checkUberSw && checkBoltSw)  setCheckBoltSw(false);
      setCheckUberSw((prev)=>{return !prev;});
    } else {
      if (!checkBoltSw && checkUberSw)  setCheckUberSw(false);
      setCheckBoltSw((prev)=>{return !prev;});
    }
  }

  function provjeriTockeIBrojeve(str: string, maxJednaTocka: boolean = false): boolean {
    let len = str.length;
    let sw = true;
    let brTocka = 0;
    for (let i = 0; i < len; i++) {
      let c = str[i];
      switch (c) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          break;
        case '.':
          brTocka++;
          if (maxJednaTocka && brTocka > 1)  sw = false;
          break;
        default:
          sw = false;
      }
      if (!sw) break;
    }
    return sw;
  }

  function brojDecimala(str:string): number {
    let rezBr = 0;
    let sw = false;
    for (let i = str.length-1; i > -1; i--) {
      let c = str[i];
      if (c === ".") {
        sw = true;
        break;
      }
      rezBr++;
    }
    if (sw) return rezBr;
    return 0;
  }

  function vratiPodatke(str: string): [number, PrometTip] {
    // 1 za previse input polja u retku, 2 za nelegalni datum, 3 za nelegalni prvi iznos, 4 za nelegalni drugi iznos
    // 5 je rezervirana u funkciji klik za nepronadeni alias
    let pp = str.split(",");
    if (pp.length !== 4)  return [1, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];

    let dat = ukloniPrazneZnakove(pp[0]);
    if (!provjeriTockeIBrojeve(dat))  return [2, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    let dat1 = dat.split(".");
    if (dat1.length < 3)  return [2, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    let dan = parseInt(dat1[0]);
    let mjesec = parseInt(dat1[1]);
    let godina = parseInt(dat1[2]);
    // ovdje ces kasnije uvesti sofisticiraniju provjeru datuma, na vidis da li je
    // ponedjeljak, zatim ispravan dan u mjesecu (28, 29, 30 ili 31 dan)
    if (dan < 1 || dan > 31 || mjesec < 1 || mjesec > 12 || godina < 2000 || godina > 2100)  return [2, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];

    let brStr1 = ukloniPrazneZnakove(pp[2]);
    if (!provjeriTockeIBrojeve(brStr1, true))  return [3, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    if (brojDecimala(brStr1) > 2)  return [3, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    
    let brStr2 = ukloniPrazneZnakove(pp[3]);
    if (!provjeriTockeIBrojeve(brStr2, true))  return [4, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    if (brojDecimala(brStr2) > 2)  return [4, {datum:"",ime:"",uberCash:null,uberPromet:null,boltPromet:null,boltCash:null}];
    
    if (platforma === "uber") {
      return [0, {datum:dodajNulu(godina)+"-"+dodajNulu(mjesec)+"-"+dodajNulu(dan),ime:ukloniPrazneZnakove(pp[1]),uberPromet:parseFloat(brStr1),uberCash:parseFloat(brStr2),boltPromet:null,boltCash:null}];
    } else {
      return [0, {datum:dodajNulu(godina)+"-"+dodajNulu(mjesec)+"-"+dodajNulu(dan),ime:ukloniPrazneZnakove(pp[1]),uberPromet:null,uberCash:null,boltPromet:parseFloat(brStr1),boltCash:parseFloat(brStr2)}];
    }
  }

  function klik(e:React.MouseEvent<HTMLDivElement>) {
    let el = e.currentTarget;
    el.classList.add("klik");
    setTimeout(()=>{el.classList.remove("klik")}, 150);
    if (!checkUberSw && !checkBoltSw) {
      postaviFlashPoruku("Niste odabrali platformu (Uber ili Bolt).", "danger", "automatski-unos");
      return;
    }

    let pp = strInput?.split(/\r?\n/);
    let len = 0;
    if (pp !== undefined) {
      len = pp.length;
      let rezPrometi: PrometTip[] = [];
      let rezGreske: string[] = [];
      for (let i = 0; i < len; i++) {
        let rez: [number, PrometTip] = vratiPodatke(pp[i]);
        
        if (rez[0] === 0) {
          let idVozaca = aliases.find((el)=>{return el.alias === rez[1].ime && ((checkUberSw && el.uberSw) || (checkBoltSw && el.boltSw))})?.id_vozaca;
          if (idVozaca === undefined) {
            rezGreske.push("Error5" + "  -  " + pp[i]);
          } else {
            rez[1].idVozaca = idVozaca;
            rezPrometi.push(rez[1]);
          }
        } else {
          rezGreske.push("Error" + rez[0] + "  -  " + pp[i]);
        }
      }
      setPrometi(rezPrometi);
      setGreske(rezGreske);
      if (e.currentTarget.id === "gumb-csv-input1") {
        setAkcija("dodaj");
      } else {
        setAkcija("brisi");
      }
      setBr((prev)=>{return prev+1});
    } else {
      postaviFlashPoruku("Niste unijeli nikakav tekst.", "danger", "automatski-unos");
    }
    console.log("podaci o csv fileu:");
    console.log("broj redaka: " + len);
  }

  function vratiGreskeBaze(prometi: PrometTip[], poljeIndexa: number[]): string[] {
    let rezultat: string[] = [];
    let len = poljeIndexa.length;
    for (let i = 0; i < len; i++) {
      let el: PrometTip = prometi[poljeIndexa[i]];
      let pp = el.datum.split("-");
      let noviDatum = pp[2] + "." + pp[1] + "." + pp[0] + ".";
      let rez = noviDatum + "," + el.ime + ",";
      if (platforma === "uber") {
        rez += el.uberPromet + "," + el.uberCash;
      } else {
        rez += el.boltPromet + "," + el.boltCash;
      }
      rezultat.push(rez);
    }
    return rezultat;
  }

  return (
    <div className="automatski-unos-popup">
      <div className="automatski-unos-okvir">
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="automatski-unos" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        <div className="automatski-unos-gumbi">
          <div className="checkbox-opcije">
            <div className="checkbox-div">
              <input type="checkbox" checked={checkUberSw} onChange={()=>{setCheck(1)}} 
                 className="checkbox-el" id="checkbox-uber"/>
              <label className="checkbox-label-el" htmlFor="checkbox-uber">Uber</label>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" checked={checkBoltSw} onChange={()=>{setCheck(2)}}
                 className="checkbox-el" id="checkbox-bolt"/>
              <label className="checkbox-label-el" htmlFor="checkbox-bolt">Bolt</label>
            </div>
          </div>
          <div onClick={klik} id="gumb-csv-input1" className="div-button1">Unesi</div>
          <div onClick={klik} id="gumb-csv-delete1" className="div-button1">Brisi</div>
        </div>
        {!rezSw ? 
          <textarea rows={8} cols={30} onClick={(e)=>{e.stopPropagation()}} className="automatski-unos-textarea" placeholder="u ovo polje unesite copy-pastom csv file Ubera ili Bolta"
                 onChange={(e)=>{unesiStr(e,setStrInput,1024*256)}} id="input-unos" name="input-unos" value={ispisId(strInput)}/> :
          <div className="automatski-unos-textarea">
            {greske.map((el, ind)=>{return <p className={"greska " + el.split(" - ")[0]} key={el+ind}>{el.split(" - ")[1]}</p>})}
            {vratiGreskeBaze(prometi, greskeBaze).map((el, ind)=>{return <p className="greska Error6" key={el+ind}>{el}</p>})}
          </div>
        }
        <div className="krizic"  onClick={zatvoriFun}>
          <div className="krizic-el">
            <div className="el1a"></div>
            <div className="el2a"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

type TablicaPrometiProps = {
  odabraniVozacID?: string;
  odabraniPrometID?: string;
  setOdabraniPrometID?: React.Dispatch<React.SetStateAction<string>>;
  path?: string;
  podaci?: PodaciTip[];
  setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
  tip?: Tip;
  setTip?: React.Dispatch<React.SetStateAction<Tip>>;
}

function TablicaPrometi({odabraniVozacID="-", odabraniPrometID="-", setOdabraniPrometID=()=>{}, podaci=[], 
  setPodaci=()=>{}, path="/api/admin/prometi", tip="SHOW", setTip=()=>{}}: TablicaPrometiProps) {

  const [imeVozaca, setImeVozaca] = React.useState<null | string>(null);
  const [tjedan, setTjedan] = React.useState<null | string>(null);
  const [datumPocetka, setDatumPocetka] = React.useState<null | string>(null);
  const [dateStr, setDateStr] = React.useState<null | string>(null);
  const [tipProvizije1, setTipProvizije1] = React.useState<null | "provizija" | "postotak" | "fiksni">(null);
  const [provizija1, setProvizija1] = React.useState<null | string>(null);
  const [uberPromet, setUberPromet] = React.useState<null | string>(null);
  const [uberCash, setUberCash] = React.useState<null | string>(null);
  const [boltPromet, setBoltPromet] = React.useState<null | string>(null);
  const [boltCash, setBoltCash] = React.useState<null | string>(null);
  const [br, setBr] = React.useState(0);
  const [brRefresh, setBrRefresh] = React.useState(0);
  const { kljuc } = React.useContext(Kontekst);
  const [swPolje, setSwPolje] = React.useState(novoPolje(0,6));
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const [confirmSw, setConfirmSw] = React.useState("");
  const { provizija, tipProvizije } = React.useContext(SettingsKontekst);

  const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo

  const todayYear = (new Date()).getFullYear();
  const todayMonth = (new Date()).getMonth() + 1;

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": "modify"
    }),
     headers: {
       'Content-type': 'application/json'
     }
  }, [br]);

  React.useEffect(()=>{
    if (dateStr !== null) {
      let pod = dateStr.split(".");
      let tj = pod[2] + "-" + format0(pod[1]) + "-" + format0(pod[0]);
      let rez = ispisiTjedniInterval(tj);
      let pronadenSw = false;
      let e = podaci.find((el)=>{return el.id.toString() === odabraniVozacID});
      console.log("isporedujemo sa " + tj);
      if (e !== undefined) {
        let f = e.prometi.find((el)=>{return el.datum === tj});
        if (f !== undefined)  pronadenSw = true;
      }

      if (pronadenSw) {
        postaviFlashPoruku("Vec imate ovaj interval u bazi podataka", "danger", "tablica-prometi");
        setDateStr(null);
        setTjedan(null);
        setDatumPocetka(null);
      } else {
        postaviFlashPoruku("Postavili ste tjedan na " + rez, "success", "tablica-prometi");
        setTjedan(rez);
        setDatumPocetka(tj);
      }
    }
  }, [dateStr]);

  React.useEffect(()=>{
    console.log("novi datumPocetka je " + datumPocetka);
  }, [datumPocetka])

  React.useEffect(()=>{
    let el = podaci.find((el)=>{return el.id.toString() === odabraniVozacID});
    if (el === undefined) {
      setImeVozaca(null);
      setTjedan(null);
      setDatumPocetka(null);
      setTipProvizije1(null);
      setProvizija1(null);
      setUberPromet(null);
      setUberCash(null);
      setBoltPromet(null);
      setBoltCash(null);
    } else {
      setImeVozaca(ispisiImeVozaca(el));
      let d = el.prometi.find((el)=>{return el.id.toString() === odabraniPrometID});
      if (d !== undefined) {
        setTjedan(ispisiTjedniInterval(d.datum));
        setDatumPocetka(d.datum);
        setTipProvizije1(d.tip_provizije);
        setProvizija1(d.provizija.toString());
        if (d.uber_promet !== null) {
          setUberPromet(d.uber_promet.toString());
        } else {
          setUberPromet(null);
        }
        if (d.uber_cash !== null) {
          setUberCash(d.uber_cash.toString());
        } else {
          setUberCash(null);
        }
        if (d.bolt_promet !== null) {
          setBoltPromet(d.bolt_promet.toString());
        } else {
          setBoltPromet(null);
        }
        if (d.bolt_cash !== null) {
          setBoltCash(d.bolt_cash.toString());
        } else {
          setBoltCash(null);
        }    
      } else {
        setTjedan(null);
        setDatumPocetka(null);
        setTipProvizije1(null);
        setProvizija1(null);
        setUberPromet(null);
        setUberCash(null);
        setBoltPromet(null);
        setBoltCash(null);
      }
      console.log("polje podataka je");
      console.log(d);
      
    }
    console.log("broj je");
  }, [odabraniVozacID, odabraniPrometID, brRefresh]);

  React.useEffect(()=>{ //ovaj effekt-hook postavlja proviziju na default vrijednost za slucaj rucnog unosa
    if (tip === "ADD") {
      setTipProvizije1(tipProvizije);
      setProvizija1(provizija);
    }
  }, [tip]);

  function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
    let id = e.currentTarget.id;
    let br = parseInt(id.substring(1));
    if (tip !== "SHOW") {
      if (e.type === "mouseenter") {
        e.currentTarget.classList.add("hover");
      } else if (e.type === "mouseleave") {
        e.currentTarget.classList.remove("hover");
      } else {
        
        rKlik.current = e.currentTarget;
        let b = (swPolje[br] + 1) % 2;
        setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})

      }
    }
  }

  function format0(br: string): string {
    if (br.length === 1) br = "0" + br;
    return br;
  }

  function ispisiImeVozaca(el: PodaciTip | undefined) {
    if (el === undefined)  return "-";
    return ispis(el.ime) + " " + ispis(el.prezime);
  }

  function maxS(str: string, len: number): string {
    // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
    if (str.length > len) {
      postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica-prometi");
      return str.substring(0,len);
    }
    return str;
  }

  function postaviIznos(e: React.ChangeEvent<HTMLInputElement>, setCallback:React.Dispatch<React.SetStateAction<string | null>>, 
                        kod:number = 0, filterFun: (str:string)=>boolean = ()=>{return true}) {
    let str = e.target.value;
    if (!filterFun(str)) return;
    let sw = false;

    if (str.length === 2 && str[0] === "-") {

    } else if (str.length === 0) {
      setCallback(null);
      return;
    } else {
      let c = str[str.length-1];
      switch (c) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case ".":
          sw = true;
          //setProvizija(str);
      }
    }

    if (sw) {
      let br = 0;
      let decSw = false;
      let brDec = 0;
      for (let i = 0; i < str.length; i++) {
        if (decSw) {
          brDec += 1;
        }
        if (str[i] === ".") {
          br += 1;
          decSw = true;
        }
      }
      if (kod === 0) {
        if (br > 1) {
          postaviFlashPoruku("Postotak moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-prometi");
        } else if (parseFloat(str) < 0 || parseFloat(str) > 100) {
          postaviFlashPoruku("Iznos provizije mora biti u postotcima (izmedu 0 i 100)", "danger", "tablica-prometi");
        } else {
          setCallback(maxS(str, 7));
        }
      } else if (kod === 1) {
        if (br > 1) {
          postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-prometi");
        } else if (brDec > 2) {
          postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica-prometi");  
        } else if (parseFloat(str) < 0 || parseFloat(str) > 100000) {
          postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 100000", "danger", "tablica-prometi");
        } else {
          setCallback(maxS(str, 8));
        }
      } else if (kod === 2) {
        if (br > 1) {
            postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-prometi");
        } else if (brDec > 2) {
            postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica-prometi");
        } else if (parseFloat(str) < 0 || parseFloat(str) > 999.99) {
            postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 1000", "danger", "tablica-prometi");
        } else {
            setCallback(maxS(str, 8));
        }
      }
    }
  }

  function klikFun(e:React.MouseEvent<HTMLDivElement>) {
    if (e.currentTarget.id === "gumb-back-1") {
      setTip("SHOW");
      setBrRefresh((prev)=>{return prev+1});
    } else if (e.currentTarget.id === "gumb-modify-1") {
      if (uberPromet !== null && uberCash === null) {
        postaviFlashPoruku("Ne mozete imati uber promet a da niste zadali uber cash", "danger", "tablica-prometi");  
      } else if (boltPromet !== null && boltCash === null) {
        postaviFlashPoruku("Ne mozete imati bolt promet a da niste zadali bolt cash", "danger", "tablica-prometi");  
      } else {
        setConfirmSw("modify");
      }
    } else if (e.currentTarget.id === "gumb-add-1") {
      if (datumPocetka === null) {
        postaviFlashPoruku("Niste zadali tjedan.", "danger", "tablica-prometi");
      } else if (provizija1 === null) {
        postaviFlashPoruku("Niste zadali proviziju.", "danger", "tablica-prometi");
      } else {
        if (uberPromet !== null && uberCash === null) {
          postaviFlashPoruku("Ne mozete imati uber promet a da niste zadali uber cash", "danger", "tablica-prometi");  
        } else if (boltPromet !== null && boltCash === null) {
          postaviFlashPoruku("Ne mozete imati bolt promet a da niste zadali bolt cash", "danger", "tablica-prometi");  
        } else {
          setConfirmSw("add");
        }
      }
    } else if (e.currentTarget.id === "div-provizijaSw") {
      e.stopPropagation(); 
  
      if (tipProvizije1 === "fiksni" && provizija1 !== null && parseFloat(provizija1) > 100) {
        setProvizija1("10");
      }
      setTipProvizije1((prev)=>{
        if (prev === "provizija") {
          return "fiksni";
        } else if (prev === "fiksni") {
          return "postotak";
        } else {
          return "provizija";
        }
      })
    }
  }

  function ispisProvizije() {
    if (tipProvizije1 === null) return <td className="row-el2">-</td>;
    return <td className="row-el2">{tipProvizije1} - {provizija1}{tipProvizije1 === "fiksni" ? "eu" : "%"}</td>;
  }

  return (
    <div className="tablica-prometi-cont">
      <table className="tablica-prometi"><tbody>
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica-prometi" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        <tr id="r6" className="row">
          <td className="row-el1">ID:</td>
          <td className="row-el2">{ispis(odabraniPrometID)}</td> 
        </tr>
        <tr id="r7" className="row">
          <td className="row-el1">Vozac:</td>
          <td className="row-el2">{imeVozaca}</td> 
        </tr>
        <tr  onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun} 
           id="r5" className="row">
          <td className="row-el1">Tjedan:</td>
          {swPolje[5] === 1 && tip === "ADD" ?
            <td className="row-el2">
              <div className="div-tjedan">
                <Kalendar dateStr={dateStr} setDateStr={setDateStr} width={200} 
                mjesecOpen={todayMonth} godinaOpen={todayYear} ponSw={true} />
              </div>
            </td> :
            <td className="row-el2">{ispis(tjedan)}</td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r0" className="row">
          <td className="row-el1">Tip obracuna:</td>
          {swPolje[0] === 0  ||  tip === "SHOW" ?
            ispisProvizije() :
            <td className="row-el3">
              <div onClick={klikFun} className="div-button-3" id="div-provizijaSw">{tipProvizije1}</div>
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input19a" name="input19a" value={ispisId(provizija1)}
                   onChange={(e)=>{postaviIznos(e,setProvizija1,tipProvizijeKod(tipProvizije1), (str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r1" className="row">
          <td className="row-el1">Uber neto promet:</td>
          {swPolje[1] === 0  ||  tip === "SHOW"  ?
            <td className="row-el2">{ispis(uberPromet)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input1" name="input1" value={ispisId(uberPromet)}
                 onChange={(e)=>{postaviIznos(e,setUberPromet,1,(str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r2" className="row">
          <td className="row-el1">Uber cash:</td>
          {swPolje[2] === 0  ||  tip === "SHOW"  ?
            <td className="row-el2">{ispis(uberCash)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input2" name="input2" value={ispisId(uberCash)}
                 onChange={(e)=>{postaviIznos(e,setUberCash,1,(str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r3" className="row">
          <td className="row-el1">Bolt neto promet:</td>
          {swPolje[3] === 0  ||  tip === "SHOW"  ?
            <td className="row-el2">{ispis(boltPromet)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input3" name="input3" value={ispisId(boltPromet)}
                 onChange={(e)=>{postaviIznos(e,setBoltPromet,1,(str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr>
        <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r4" className="row">
          <td className="row-el1">Bolt cash:</td>
          {swPolje[4] === 0  ||  tip === "SHOW"  ?
            <td className="row-el2">{ispis(boltCash)}</td> :
            <td className="row-el2">
              <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input4" name="input4" value={ispisId(boltCash)}
                 onChange={(e)=>{postaviIznos(e,setBoltCash,1,(str)=>{return filterStr(str,false,true)})}} />
            </td>
          }
        </tr>
        {tip !== "SHOW" ?
          <tr className="row"> 
            <td className="row-el2" colSpan={2} >
              {tip === "MODIFY" ?
                <div className="gumbi-1">
                  <div onClick={klikFun} id="gumb-back-1" className="div-button-1">Back</div>
                  <div onClick={klikFun} id="gumb-modify-1" className="div-button-1">Modify</div>
                </div> : null
              }
              {tip === "ADD" ?
                <div className="gumbi-1">
                  <div onClick={klikFun} id="gumb-back-1" className="div-button-1">Back</div>
                  <div onClick={klikFun} id="gumb-add-1" className="div-button-1">Add</div>
                </div> : null
              }
            </td>  
          </tr> : null}
      </tbody></table>
      {confirmSw === "modify" ? <ConfirmPrometi br={2} setConfirmSw={setConfirmSw} tekst="Upravo cete MODIFICIRATI odabrani tjedni promet. Da li ste sigurni?" 
           setTip={setTip} payload={{"id": odabraniPrometID,"tipProvizije":tipProvizije1,"provizija":provizija1,"uberPromet":uberPromet,
                   "uberCash":uberCash,"boltPromet":boltPromet,"boltCash":boltCash}} 
                   setOdabraniPrometID={setOdabraniPrometID} setPodaci={setPodaci} objasnjenje="MODIFICIRAM tjedni promet. Pricekajte..."/> : null}
      {confirmSw === "add" ? <ConfirmPrometi br={3} setConfirmSw={setConfirmSw} tekst="Upravo cete DODATI tjedni promet. Da li ste sigurni?" 
           setTip={setTip} payload={{"id": odabraniPrometID,"idVozaca": odabraniVozacID, "datum":datumPocetka,"tipProvizije":tipProvizije1,"provizija":provizija1,"uberPromet":uberPromet,
                   "uberCash":uberCash,"boltPromet":boltPromet,"boltCash":boltCash}} 
                   setOdabraniPrometID={setOdabraniPrometID} setPodaci={setPodaci} objasnjenje="DODAJEM tjedni promet. Pricekajte..."/> : null}
    </div>
  )
}

type ConfirmPrometiProps = {
    setConfirmSw?: React.Dispatch<React.SetStateAction<string>>;
    br?: number;
    tekst?: string;
    objasnjenje?: string;
    payload?: {[index:string]: any};
    path?: string;
    setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
    setOdabraniPrometID: React.Dispatch<React.SetStateAction<string>>;
    setTip?: React.Dispatch<React.SetStateAction<Tip>>;
  }

function ConfirmPrometi({setConfirmSw=()=>{}, br=1, tekst="neko pitanje...", 
  objasnjenje="neko objasnjenje akcije...", payload={"id":""}, setPodaci=()=>{}, 
  setOdabraniPrometID=()=>{}, setTip=()=>{}, path="/api/admin/prometi"}: ConfirmPrometiProps) {

  const [sw, setSw] = React.useState(false);
  const [akcija, setAkcija] = React.useState("");
  const [id, setId] = React.useState("");
  const [idVozaca, setIdVozaca] = React.useState<null | string>(null);
  const [datum, setDatum] = React.useState<null | string>(null);
  const [tipProvizije, setTipProvizije] = React.useState<null | string>(null);
  const [provizija, setProvizija] = React.useState<null | string>(null);
  const [uberPromet, setUberPromet] = React.useState<null | string>(null);
  const [uberCash, setUberCash] = React.useState<null | string>(null);
  const [boltPromet, setBoltPromet] = React.useState<null | string>(null);
  const [boltCash, setBoltCash] = React.useState<null | string>(null);

  const {kljuc} = React.useContext(Kontekst);
  const [br1, setBr1] = React.useState(0);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": akcija,
      "id": id,
      "id_vozaca": idVozaca, 
      "datum": datum,
      "tip_provizije": tipProvizije,
      "provizija": provizija,
      "uber_promet": uberPromet,
      "uber_cash": uberCash,
      "bolt_promet": boltPromet,
      "bolt_cash": boltCash
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br1]);

  React.useEffect(()=>{
    console.log("loading6: " + loading);
    console.log("error6: " + error);
    console.log("value6: ");
    console.log("br: " + br1);
    console.log(value);

    if (br1 !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        postaviFlashPoruku(value.errorCode, "danger", "confirm");
        setTip("SHOW");
        setConfirmSw("");
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setConfirmSw("");
        setPodaci(value.value.podaci);
        if (akcija === "delete")  setOdabraniPrometID("-");
        if (akcija === "modify") {
          setTip("SHOW");
        }
        if (akcija === "add") {
          setOdabraniPrometID(value.value.idNovi);
          setTip("SHOW");
          
        }
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (br === 1) {
      setAkcija("delete");
      if (payload.id !== undefined)  setId(payload.id);
    } else if (br === 2) {
      setAkcija("modify");
      if (payload.id !== undefined)  setId(payload.id);
      if (payload.tipProvizije !== undefined)  setTipProvizije(payload.tipProvizije);
      if (payload.provizija !== undefined)  setProvizija(payload.provizija);
      if (payload.uberPromet !== undefined)  setUberPromet(payload.uberPromet);
      if (payload.uberCash !== undefined)  setUberCash(payload.uberCash);
      if (payload.boltPromet !== undefined)  setBoltPromet(payload.boltPromet);
      if (payload.boltCash !== undefined)  setBoltCash(payload.boltCash);
    } else if (br === 3) {
      setAkcija("add");
      if (payload.datum !== undefined)  setDatum(payload.datum);
      if (payload.idVozaca !== undefined)  setIdVozaca(payload.idVozaca);
      if (payload.tipProvizije !== undefined)  setTipProvizije(payload.tipProvizije);
      if (payload.provizija !== undefined)  setProvizija(payload.provizija);
      if (payload.uberPromet !== undefined)  setUberPromet(payload.uberPromet);
      if (payload.uberCash !== undefined)  setUberCash(payload.uberCash);
      if (payload.boltPromet !== undefined)  setBoltPromet(payload.boltPromet);
      if (payload.boltCash !== undefined)  setBoltCash(payload.boltCash);
    }
  }, []);

  function fun(str: string) {
    if (str === "no") {
      setConfirmSw("");
    } else if (br === 1) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 2) {
      setBr1((prev)=>{return prev+1});
    } else if (br === 3) {
      setBr1((prev)=>{return prev+1});
    }
  }

  return (
    <div className="popup-plast-confirm-prometi">
      <div className="popup-confirm-prometi">
        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="confirm" kod2={el.kod} />})}
        </div>
        {loading ? <>
          <div className="naslov">{objasnjenje}</div>
          <Spinner/>
        </> :
           <>
             <div className="naslov">{tekst}</div>
             <div className="gumbi">
               <Gumb fun={()=>{fun("no")}} tekst="No" height="30px" width="60px" />
               <Gumb fun={()=>{fun("yes")}} tekst="Yes" height="30px" width="60px" />
             </div>
           </>}
      </div>
    </div>
  )
}