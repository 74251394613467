import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import './settings.scss';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Loading, ispisImena1, novoPolje, ispis, ispisId, filterStr, ukloniPrazneZnakove, ispisSaDecimalama, tipProvizijeKod } from "./notFound";
import { ADRESA1, FlashKontekst, Kontekst, SettingsKontekst } from "./index";
import { useFetch1 } from "./useFetch";
import { Signin, Signout, Gumb, Spinner } from "./login";
import { brDanaOdDatuma, Kalendar, dodajNulu, tranfsormDateStr, otvoriKalendar } from "./kalendar";
import { FlashTip, Flash } from "./flash";

export function Settings() {
  const [status, setStatus] = React.useState("loading");
  const [signoutSw, setSignoutSw] = React.useState(false);
  const [br, setBr] = React.useState(0);
    
  const [swPolje, setSwPolje] = React.useState(novoPolje(0,10));
  const { kljuc } = React.useContext(Kontekst);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const {email, setEmail, activationSw, setActivationSw, verificationSw, setVerificationSw, 
    verificationType, setVerificationType, tipProvizije, setTipProvizije, provizija, setProvizija, mjesecniDoprinos, setMjesecniDoprinos, flashMessageTime, setFlashMessageTime, 
    flashMessageSw, setFlashMessageSw} = React.useContext(SettingsKontekst);

  const [akcija, setAkcija] = React.useState("ucitaj");
  const r1 = React.useRef<HTMLDivElement | null>(null);
  const rKlik = React.useRef<HTMLTableRowElement>(); // postavljam u ovaj ref redak koji sam kliknuo
  const path = "/api/admin/settings";

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "email": email,
        "verificationSw": verificationSw,
        "verification_type": verificationType,
        "activationSw": activationSw,
        "tip_provizije": tipProvizije,
        "provizija": provizija,
        "mjesecni_doprinos": mjesecniDoprinos,
        "flash_messageSw": flashMessageSw,
        "flash_message_time": flashMessageTime
      }),
       headers: {
         'Content-type': 'application/json'
       }
    }, [br]);
  
  React.useEffect(()=>{  
    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);    
        if (akcija === "ucitaj") {
          setStatus("sign-in");
        } else {
          postaviFlashPoruku("GRESKA! Snimanje postavki u bazu podataka nije bilo uspijesno.", "danger", "tablica-settings");
        }
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        if (akcija === "ucitaj") {
          setStatus("logged-in");
        } else {
          postaviFlashPoruku("Snimanje postavki u bazu podataka je bilo uspijesno.", "success", "tablica-settings");
        }

      }
    }
  }, [loading]);

  React.useEffect(()=>{
    //setStatus("logged-in");
    setBr((prev)=>{return prev+1});
  }, []);

  function maxS(str: string, len: number): string {
    // ova funkcija cap-a string, i slat ce upozorenje da je maksimalan input len znakova
    if (str.length > len) {
      postaviFlashPoruku("Za ovaj field maksimalna duljina je " + len, "danger", "tablica-settings");
      return str.substring(0,len);
    }
    return str;
  }

    function postaviIznos(e: React.ChangeEvent<HTMLInputElement>, setCallback: React.Dispatch<React.SetStateAction<string | null>>,
        kod: number = 0, filterFun: (str: string) => boolean = () => { return true }) {
        let str = e.target.value;
        if (!filterFun(str)) return;
        let sw = false;

        if (str.length === 2 && str[0] === "-") {

        } else if (str.length === 0) {
            setCallback(null);
            return;
        } else {
            let c = str[str.length - 1];
            switch (c) {
                case "0":
                case "1":
                case "2":
                case "3":
                case "4":
                case "5":
                case "6":
                case "7":
                case "8":
                case "9":
                case ".":
                    sw = true;
                //setProvizija(str);
            }
        }

        if (sw) {
            let br = 0;
            let decSw = false;
            let brDec = 0;
            for (let i = 0; i < str.length; i++) {
                if (decSw) {
                    brDec += 1;
                }
                if (str[i] === ".") {
                    br += 1;
                    decSw = true;
                }
            }
            if (kod === 0) {
                if (br > 1) {
                    postaviFlashPoruku("Postotak moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-settings");
                } else if (parseFloat(str) < 0 || parseFloat(str) > 100) {
                    postaviFlashPoruku("Iznos provizije mora biti u postotcima (izmedu 0 i 100)", "danger", "tablica-settings");
                } else {
                    setCallback(maxS(str, 7));
                }
            } else if (kod === 1) {
                if (br > 1) {
                    postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-settings");
                } else if (brDec > 2) {
                    postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica-settings");
                } else if (parseFloat(str) < 0 || parseFloat(str) > 100000) {
                    postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 100000", "danger", "tablica-settings");
                } else {
                    setCallback(maxS(str, 8));
                }
            } else if (kod === 2) {
              if (br > 1) {
                  postaviFlashPoruku("Novcani iznos moze sadrzavati samo jednu decimalnu tocku.", "danger", "tablica-settings");
              } else if (brDec > 2) {
                  postaviFlashPoruku("Novcani iznos moze sadrzavati samo dvije decimale (eurocenti).", "danger", "tablica-settings");
              } else if (parseFloat(str) < 0 || parseFloat(str) > 999.99) {
                  postaviFlashPoruku("Iznos mora biti pozitivan broj manji od 1000", "danger", "tablica-settings");
              } else {
                  setCallback(maxS(str, 8));
              }
            }
        }
    }
  
  function postaviFlashTime(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    if (!filterStr(str, false, false)) return false;
    if (str.length > 6) {
      postaviFlashPoruku("Maksimalno moguce vrijeme je 999999 msec (1000s)", "danger", "tablica-settings");
      return;
    }
    setFlashMessageTime(str);
  }

  function klikFun(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation(); 
    let id = e.currentTarget.id;

    if (id === "gumb-1") {
      setVerificationSw((prev)=>{if (prev === null) return true; return !prev;})    
    } else if (id === "gumb-2") {
      if (verificationSw === null || !verificationSw) {
        postaviFlashPoruku("Trenutno je verifikacija iskljucena.", "danger", "tablica-settings");
      } else if (verificationType === null) {
        setVerificationType("email");
      } else if (verificationType === "email") {
        setVerificationType("sms");
      } else if (verificationType === "sms") {
        setVerificationType("whatsApp");
      } else {
        setVerificationType("email");
      }
    } else if (id === "gumb-3") {
      setActivationSw((prev)=>{return !prev});
    } else if (id === "gumb-4") {
      setFlashMessageSw((prev)=>{return !prev});
    } else if (id === "gumb-modify-1") {
      let regex = /\A[\w+\-.]+@[a-z\d\-.]+\.[a-z]+\z/i
      regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
      if (email === null || !regex.test(email)) {
        postaviFlashPoruku("Niste upisali dobar email. Probajte ponovo", "danger", "tablica-settings");
        return;
      }
      setAkcija("modify");
      setBr((prev)=>{return prev+1});
    } else if (id === "gumb-8") {
      if (tipProvizije === "fiksni" && provizija !== null && parseFloat(provizija) > 100) {
        setProvizija("10");
      }
      setTipProvizije((prev)=>{
        if (prev === "provizija") {
          return "fiksni";
        } else if (prev === "fiksni") {
          return "postotak";
        } else {
          return "provizija";
        }
      })
    }
  }

  function inputFun(e: React.ChangeEvent<HTMLInputElement>, filterFun: (str:string)=>boolean = ()=>{return true}) {
    let str = e.target.value;
    let str1 = str.toLocaleLowerCase();
    if (!filterFun(str1)) return;

    let br = 0;
    let sw = true;
    let brDec = 0;
    for (let i = str1.length - 1; i > -1; i--) {
      if (str1[i] === ".") {
        br++;
        sw = false;
        if (br > 1)  break;
      }
      if (sw) {
        brDec++;
      }
    }
    if (parseFloat(str) < 0 || parseFloat(str) >= 10000) {
      postaviFlashPoruku("Mjesecni doprinos mora biti pozitivni broj manji od 10000 sa do dvije decimale", "danger", "tablica-settings");
      return;
    } else if (brDec > 2 && br === 1) {
      postaviFlashPoruku("Mjesecni doprinos moze imati do dvije decimale za eurocente", "danger", "tablica-settings");  
      return;
    }
    if (br <= 1 && (brDec < 3 || br === 0))  setMjesecniDoprinos(str1);

    
  }

  function redakEventFun(e: React.MouseEvent<HTMLTableRowElement>) {
    let id = e.currentTarget.id;
    let br = parseInt(id.substring(1));

    if (e.type === "mouseenter") {
      e.currentTarget.classList.add("hover");
    } else if (e.type === "mouseleave") {
      e.currentTarget.classList.remove("hover");
    } else {
      rKlik.current = e.currentTarget;
      let b = (swPolje[br] + 1) % 2;
      setSwPolje((prev)=>{let prev1 = [...prev].map((el)=>{if (el === 1) return 0; return el;}); prev1[br] = b; return prev1})
    }
  }

  function ispisiTip(sw: boolean | null, tip: null | string) {
    if (sw === null || !sw) {
      return "-";
    } else {
      return ispis(tip);
    }
  }

  return (
    <div className="holder" ref={r1}>
    {status === "loading" ? <Loading sw={true}/> : <>
      <div className="prometi-izbornik">
        {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
        <Navbar current={8} status={status} signout={()=>{setSignoutSw(true)}}/> 
        {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
        <table className="tablica-prometi"><tbody>
          <div className="flash-container">
            {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="tablica-settings" kod2={el.kod} />})}
          </div>
          {loading ? <Spinner/> : null}
          
          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
            id="r0" className="row">
            <td className="row-el1">Kontakt email:</td>
            {swPolje[0] === 0 ?
              <td className="row-el2">{ispis(email)}</td> :
              <td className="row-el2">
                <input type="text" onClick={(e)=>{e.stopPropagation()}} autoComplete="off" autoCapitalize="off" autoCorrect="off"
                 onChange={(e)=>{setEmail(maxS(e.target.value,128))}} id="input0" name="input0" value={ispisId(email)}/>
              </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r1" className="row">
            <td className="row-el1">Account verification:</td>
            {swPolje[1] === 0 ?
              <td className="row-el2">{ispis(verificationSw)}</td> :
            <td className="row-el2">
              <div onClick={klikFun} id="gumb-1" className="div-button">{ispis(verificationSw)}</div>
            </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r2" className="row">
            <td className="row-el1">Verification type:</td>
            {swPolje[2] === 0 ?
              <td className="row-el2">{ispisiTip(verificationSw, verificationType)}</td> :
            <td className="row-el2">
              <div onClick={klikFun} id="gumb-2" className="div-button prosireni">{ispisiTip(verificationSw, verificationType)}</div>
            </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r3" className="row">
            <td className="row-el1">Aktivacija racuna:</td>
            {swPolje[3] === 0 ?
              <td className="row-el2">{ispis(activationSw)}</td> :
            <td className="row-el2">
              <div onClick={klikFun} id="gumb-3" className="div-button">{ispis(activationSw)}</div>
            </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r8" className="row">
            <td className="row-el1">Tip obracuna:</td>
            {swPolje[8] === 0 ?
              <td className="row-el2">{tipProvizije}</td> :
              <td className="row-el2">
                <div onClick={klikFun} id="gumb-8" className="div-button">{tipProvizije}</div>
              </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r4" className="row">
            <td className="row-el1">{tipProvizije === "postotak" ? "Promet" : "Provizija"}({tipProvizije === "fiksni" ? "eu" : "%"}):</td>
            {swPolje[4] === 0 ?
              <td className="row-el2">{ispis(provizija)}</td> :
              <td className="row-el2">
                <input type="text" onClick={(e)=>{e.stopPropagation()}} id="input4" name="input4" value={ispisId(provizija)}
                   onChange={(e)=>{postaviIznos(e,setProvizija,tipProvizijeKod(tipProvizije), (str)=>{return filterStr(str,false,true)})}} />
              </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r5" className="row">
            <td className="row-el1">Mjesecni doprinos:</td>
            {swPolje[5] === 0 ?
              <td className="row-el2">{ispis(mjesecniDoprinos)}</td> :
              <td className="row-el2">
                <input onClick={(e)=>{e.stopPropagation()}} className="input-el-a" type="text" id="str-mjesec" name="str-mjesec" placeholder="upisi default mjesecni doprinos"
                  onChange={(e)=>{inputFun(e, (str)=>{return filterStr(str, false, true)})}} value={mjesecniDoprinos} />
              </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r6" className="row">
            <td className="row-el1">Flash poruke:</td>
            {swPolje[6] === 0 ?
              <td className="row-el2">{ispis(flashMessageSw)}</td> :
            <td className="row-el2">
              <div onClick={klikFun} id="gumb-4" className="div-button">{ispis(flashMessageSw)}</div>
            </td>
            }
          </tr>

          <tr onMouseEnter={redakEventFun} onMouseLeave={redakEventFun} onClick={redakEventFun}
           id="r7" className="row">
            <td className="row-el1">Vrijeme flash poruke:</td>
            {swPolje[7] === 0 ?
              <td className="row-el2">{ispis(flashMessageTime)}</td> :
              <td className="row-el2">
                <input type="text" onClick={(e)=>{e.stopPropagation()}}
                   onChange={postaviFlashTime} id="input7" name="input7" value={ispisId(flashMessageTime)}/>
              </td>
            }
          </tr>

          <tr className="row"> 
            <td className="row-el2" colSpan={2} >
              <div className="gumbi-1">
               <div onClick={klikFun} id="gumb-modify-1" className="div-button-1">Modify</div>
              </div> 
            </td>  
          </tr>
        </tbody></table>
      </div>
      <Footer />
      </>}
    </div>
  )
}