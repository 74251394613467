import React from 'react';
import ReactDOM from 'react-dom/client';
import './doprinosi.scss';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { ADRESA1, FlashKontekst, Kontekst, StateKontekst, SettingsKontekst } from "./index";
import { useFetch1 } from "./useFetch";
import { Signin, Signout, Gumb, Spinner } from "./login";
import { Loading, ukloniNull, nullToDoDaljnjeg, ConfirmChoice, ispis, filterStr, ispisImena } from "./notFound";
import { FlashTip, Flash } from "./flash";
import { Kalendar, brDanaOd2000, brDanaOdPocetkaGodine } from "./kalendar";
import { selSw } from "./admin"; 


type PodaciDoprinosTip = {
  id_veze: number;
  pocetak: string;
  kraj: string | null;
  mjesecni_doprinos: number;
  dnevni_broj_sati: number; 
}

type PodaciTip = {
  id: number;
  name: string;
  ime: string | null;
  prezime: string | null;
  intervali: PodaciDoprinosTip[];
}

type AkcijaTip = "dodaj_doprinos" | "modificiraj_doprinos";

export function DoprinosiIzbornik() {

  const [action, setAction] = React.useState("");
  const [status, setStatus] = React.useState("loading");
  const { kljuc } = React.useContext(Kontekst);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [signoutSw, setSignoutSw] = React.useState(false);
  const [searchStr, setSearchStr] = React.useState("");
  const [odabraniVozacID, setOdabraniVozacID] = React.useState("-");
  const [odabraniDoprinosID, setOdabraniDoprinosID] = React.useState("-");
  const [podaci, setPodaci] = React.useState<PodaciTip[]>([]);
  const [doprinosi, setDoprinosi] = React.useState<PodaciDoprinosTip[]>([]);
  const [confirmBr, setConfirmBr] = React.useState(0);
  const [akcija, setAkcija] = React.useState<AkcijaTip>("dodaj_doprinos");
  const [confirmAction, setConfirmAction] = React.useState<string | null>(null);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const [checkImeSw, setCheckImeSw] = React.useState(true);
  const [checkPrezimeSw, setCheckPrezimeSw] = React.useState(true);
  const [checkNickSw, setCheckNickSw] = React.useState(true);
  const {odabraniVozacIDDoprinosi, setOdabraniVozacIDDoprinosi, odabraniDoprinosIDDoprinosi, setOdabraniDoprinosIDDoprinosi,  
         checkNickSwDoprinosi, setCheckNickSwDoprinosi, checkImeSwDoprinosi, setCheckImeSwDoprinosi, checkPrezimeSwDoprinosi, 
         setCheckPrezimeSwDoprinosi, searchStrDoprinosi, setSearchStrDoprinosi} = React.useContext(StateKontekst);
  const [pomak, setPomak] = React.useState(0);

  const r = React.useRef<HTMLDivElement | null>(null);

  const [loading, error, value] = useFetch1(ADRESA1 + '/api/admin/doprinosi', 
  {
    method: 'POST',
    body: JSON.stringify({
      "token": kljuc,
      "akcija": action,
      "id": odabraniDoprinosID
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading9: " + loading);
    console.log("error9: " + error);
    console.log("value9: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
        if (value.errorCode === "GRESKA! Brisanje doprinosa u bazi podataka nije uspijelo.") {
          postaviFlashPoruku("Snimanje nije uspijelo. Pokusajte ponovo.", "danger", "dodjela-doprinosa");
        } else {
          setStatus("sign-in");
          setPodaci([]);
        }
        
      } else if (!loading && !value.error){
        console.log("cini se da je sve ucitano");
        console.log(value.value);
        setStatus("logged-in");
        setPodaci(value.value.podaci);
        if (action === "obrisi_doprinos") {
          postaviFlashPoruku("Upravo ste uspijesno obrisali doprinos.", "success", "dodjela-doprinosa");
          setOdabraniDoprinosID("-");
        }       
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    if (odabraniVozacID === "-") {
      setDoprinosi([]);
    } else {
      let p = podaci.find((el)=>{if (el.id.toString() === odabraniVozacID) return true; return false;});
      if (p !== undefined) {
        setDoprinosi(p.intervali.sort((a,b)=>{if (a.pocetak < b.pocetak) return -1; if (a.pocetak === b.pocetak) return 0; return 1;}));
      } else {
        setDoprinosi([]);
      }
    }
  }, [odabraniVozacID, podaci])

  React.useEffect(()=>{
    if (action !== "") {
      setBr((prev)=>{return prev+1});
    }
  }, [action, br1]);

  React.useEffect(()=>{
    setAction("ucitaj");
  }, []);

  React.useEffect(()=>{
    console.log("pokrenuo " + br1 + " " + Math.random());
    if (kljuc !== "-")  setBr1((prev)=>{return prev+1});
  }, [kljuc]);

  React.useEffect(()=>{
    setOdabraniDoprinosID("-");
  }, [odabraniVozacID]);

  React.useEffect(()=>{
    if (confirmAction !== null) {
      if (confirmAction === "yes") {
        if (confirmBr === 1) {
          console.log("Upravo si potvrdio akciju BRISANJA" + Math.random());
          console.log("id koji ces obrisati " + odabraniDoprinosID);
          setAction("obrisi_doprinos");
        } else {
          console.log("Upravo si potvrdio GENERALNU akciju" + Math.random());
        }
        //setBr1((prev)=>{return prev+1});
        setConfirmBr(0);
      } else {
        setConfirmBr(0);
      }
      setConfirmAction(null);
    }
  }, [confirmAction]);

  React.useEffect(()=>{
    if (status !== "logged-in") {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setPomak(scrollTop);
      if (r.current !== null)  r.current.style.position = "fixed";
      if (r.current !== null)  r.current.style.top = -1*scrollTop + "px";
    } else {
      if (r.current !== null)  r.current.style.top = "0px";
      if (r.current !== null)  r.current.style.position = "relative";
      window.scrollTo(0, pomak);
    }
  }, [status]);

  React.useEffect(()=>{  //  iniciramo zapamcene vrijednosti odabira
    if (br === 0) {
      setOdabraniVozacID(odabraniVozacIDDoprinosi);
      setOdabraniDoprinosID(odabraniDoprinosIDDoprinosi);
      setSearchStr(searchStrDoprinosi);
      setCheckNickSw(checkNickSwDoprinosi);
      setCheckImeSw(checkImeSwDoprinosi);
      setCheckPrezimeSw(checkPrezimeSwDoprinosi);
    } else {
      setOdabraniVozacIDDoprinosi(odabraniVozacID);
      setOdabraniDoprinosIDDoprinosi(odabraniDoprinosID);
      setSearchStrDoprinosi(searchStr);
      setCheckNickSwDoprinosi(checkNickSw);
      setCheckImeSwDoprinosi(checkImeSw);
      setCheckPrezimeSwDoprinosi(checkPrezimeSw);
    }
  }, [odabraniVozacID, odabraniDoprinosID, searchStr, checkNickSw, checkImeSw, checkPrezimeSw]);

  function setCheck(br: number) {
    if (br === 1) {
      if (checkNickSw) {
        if (provjeriChecks())  setCheckNickSw(false);
      } else {
        setCheckNickSw(true);
      }
    } else if (br === 2) {
      if (checkImeSw) {
        if (provjeriChecks())  setCheckImeSw(false);
      } else {
        setCheckImeSw(true);
      }
    } else {
      if (checkPrezimeSw) {
        if (provjeriChecks())  setCheckPrezimeSw(false);
      } else {
        setCheckPrezimeSw(true);
      }
    }

    function provjeriChecks() {
      let br = 0;
      if (checkNickSw) br += 1;
      if (checkImeSw) br += 1;
      if (checkPrezimeSw) br += 1;
      if (br === 1) return false;
      return true;
    }

  }

  function inputFun(e: React.ChangeEvent<HTMLInputElement>) {
    let str = e.target.value;
    let str1 = str.toLocaleLowerCase();
    setSearchStr(str1);

    let sw = false;
    let pp = podaci.filter((el)=>{return ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw).toLowerCase().includes(str1)});
    for (let i = 0; i < pp.length; i++) {
      if (pp[i].id.toString() === odabraniVozacID) {
        sw = true;
        return;
      }
    }

    if (!sw)  setOdabraniVozacID("-");
  }

  function odabirFun(e: React.ChangeEvent<HTMLSelectElement>) {
    console.log("Kliknuo si na " + e.currentTarget.value);
    setOdabraniVozacID(e.currentTarget.value);
  }

  function odabirFun1(e: React.ChangeEvent<HTMLSelectElement>) {
    console.log("Kliknuo si na " + e.currentTarget.value);
    setOdabraniDoprinosID(e.currentTarget.value);
  }

  function klik(e:React.MouseEvent<HTMLDivElement>, akcija:string = "") {
    let el = e.currentTarget;
    el.classList.add("klik");
    setTimeout(()=>{el.classList.remove("klik")}, 150);

    if (el.id === "gumb-erase") {
      setConfirmBr(1);
    } else if (el.id === "gumb-add") {
      let ee = podaci.find((el)=>{if (el.id.toString() === odabraniVozacID) return true; return false})?.intervali.find((el)=>{if (el.kraj === null) return true; return false;});
      if (ee === undefined) {
        setConfirmBr(2);
        setAkcija("dodaj_doprinos");
      } else {
        postaviFlashPoruku("Ne mozes dodati novi interval ako postoji otvoreni interval.", "danger", "doprinosi");
      }
    } else if (el.id === "gumb-modify") {
      setConfirmBr(2);
      setAkcija("modificiraj_doprinos");
    }
  }

  return (
    <div className="holder" ref={r}>
      {status === "loading" ? <Loading sw={true}/> : <>
      <div className="doprinosi-izbornik">
        {status === "sign-in" ? <Signin tipRacuna="ADMIN" setStatus={setStatus}/> : null}
        <Navbar current={3} status={status} signout={()=>{setSignoutSw(true)}}/>
        {signoutSw ? <Signout ponisti={setSignoutSw}/> : null}
        <div className="el">
          <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
            <div className="input-div">
              <div className="flash-container">
                {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="doprinosi" kod2={el.kod} />})}
              </div>
              <div className="input-div1"><label htmlFor="str">Upisi ime:</label></div>
              <input onChange={inputFun} value={searchStr} className="input-el" type="text" id="str" name="str" placeholder="upisi substring"/>
            </div> 
          </form>
        </div>
      
        <div className="el1">
          <div className="flash-container">
            {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="dodjela-doprinosa" kod2={el.kod} />})}
          </div>

  
          <div className="checkbox-opcije">
            <div className="checkbox-div">
              <input type="checkbox" checked={checkNickSw} onChange={()=>{setCheck(1)}} 
                 className="checkbox-el" id="checkbox-nick"/>
              <label className="checkbox-label-el" htmlFor="checkbox-Nick">Username</label>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" checked={checkImeSw} onChange={()=>{setCheck(2)}}
                 className="checkbox-el" id="checkbox-ime"/>
              <label className="checkbox-label-el" htmlFor="checkbox-ime">Ime</label>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" checked={checkPrezimeSw} onChange={()=>{setCheck(3)}}
                 className="checkbox-el" id="checkbox-prezime"/>
              <label className="checkbox-label-el" htmlFor="checkbox-prezime">Prezime</label>
            </div>
          </div>

          <div className="select-opcije">
            <select className="izbornik" size={5} value={odabraniVozacID} onClick={(e)=>{e.stopPropagation()}} 
                onChange={odabirFun} >
              <option value="-">bez odabira</option>
              {podaci.filter((el)=>{let ii = ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw); return ii !== "" && ii.toLowerCase().includes(searchStr)})
               .sort((el1,el2)=>{let is1 = ispisImena(el1.name,el1.ime,el1.prezime,checkNickSw,checkImeSw,checkPrezimeSw); let is2 = ispisImena(el2.name,el2.ime,el2.prezime,checkNickSw,checkImeSw,checkPrezimeSw);
                  if (is1 < is2) return -1; if (is1 > is2) return 1; return 0;})
               .map((el)=>{return <option value={el.id} key={el.id}>{ispisImena(el.name,el.ime,el.prezime,checkNickSw,checkImeSw,checkPrezimeSw)}</option>})}
            </select>

            <select className="izbornik" size={5} value={odabraniDoprinosID} onClick={(e)=>{e.stopPropagation()}} 
               onChange={odabirFun1} >
              <option value="-">bez odabira</option>
               {podaci.find((el)=>{if (el.id.toString() === odabraniVozacID) return true; return false})
               ?.intervali.map((el)=>{return <option value={el.id_veze} key={el.id_veze}>{el.pocetak + " - " + nullToDoDaljnjeg(el.kraj)}</option>})}
            </select>
          </div>

        </div>
        <div className="el2">
          {odabraniVozacID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-add" className="div-button">Add</div> : null}
          {odabraniDoprinosID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-modify" className="div-button">Modify</div> : null}
          {odabraniDoprinosID !== "-" ? <div onClick={(e)=>{klik(e)}} id="gumb-erase" className="div-button">Erase</div> : null}
        </div>
        {confirmBr === 1 ? <ConfirmChoice setConfirmAction={setConfirmAction} 
         tekst="Upravo cete obrisati odabrani interval. Da li ste sigurni?"/> : null}
        {confirmBr === 2 ? <PridruziDoprinosPopUp odabraniVozacID={odabraniVozacID} doprinosi={doprinosi}
          akcija={akcija} odabraniDoprinosID={odabraniDoprinosID} path={"/api/admin/doprinosi"} setPodaci={setPodaci} setConfirmBr={setConfirmBr}/> : null} 
      </div>
      <Footer />
      </>}
    </div>
  )
}

type IntervalTip = {
  idVeze: number;
  pocetak: number;
  kraj: number | null;
}

type PridruziDoprinosPopUpProps = {
  odabraniVozacID?: string;
  doprinosi?: PodaciDoprinosTip[];
  akcija?: AkcijaTip;
  odabraniDoprinosID?: string;
  path?: string;
  setPodaci?: React.Dispatch<React.SetStateAction<PodaciTip[]>>;
  setConfirmBr?: React.Dispatch<React.SetStateAction<number>>;
}

function PridruziDoprinosPopUp({odabraniVozacID="-", doprinosi=[], akcija="dodaj_doprinos",
        odabraniDoprinosID="-", path="/api/admin/doprinosi", setPodaci=()=>{}, setConfirmBr=()=>{}}: PridruziDoprinosPopUpProps) {
  
  const [datumPocetka, setDatumPocetka] = React.useState<null | string>(null);
  const [datumZavrsetka, setDatumZavrsetka] = React.useState<null | string>(null);
  const [br, setBr] = React.useState(0);
  const [br1, setBr1] = React.useState(0);
  const [intervali, setIntervali] = React.useState<IntervalTip[]>([]);
  const [stanje, setStanje] = React.useState(0);
  const [searchStr, setSearchStr] = React.useState("");
  const [mjesecniDoprinos1, setMjesecniDoprinos1] = React.useState("256");
  const [dnevniBrojSati, setDnevniBrojSati] = React.useState("");
  const [dateStr, setDateStr] = React.useState<string | null>(null);
  const {flashPoruke, setFlashPoruke, postaviFlashPoruku} = React.useContext(FlashKontekst);
  const { kljuc } = React.useContext(Kontekst);
  const { mjesecniDoprinos } = React.useContext(SettingsKontekst);

  const danasnjiMjesec = (new Date()).getMonth() + 1;
  const danasnjaGodina = (new Date()).getFullYear();

  const [loading, error, value] = useFetch1(ADRESA1 + path, 
    {
      method: 'POST',
      body: JSON.stringify({
        "token": kljuc,
        "akcija": akcija,
        "id_veze": odabraniDoprinosID,
        "id_vozaca": odabraniVozacID,
        "pocetak": datumPocetka,
        "kraj": datumZavrsetka,
        "mjesecni_doprinos": mjesecniDoprinos1,
        "dnevni_broj_sati": dnevniBrojSati
      }),
      headers: {
        'Content-type': 'application/json'
      }
  }, [br]);

  React.useEffect(()=>{
    console.log("loading10: " + loading);
    console.log("error10: " + error);
    console.log("value10: ");
    console.log("br: " + br);
    console.log(value);

    if (br !== 0 && value !== undefined && typeof value !== "boolean") {
      if (value.error) {
        console.log("GRESKA");
        console.log(value.errorCode);
      } else if (!loading && !value.error){
        
        setPodaci(value.value.podaci);
        
        zatvoriPopup();
        postaviFlashPoruku("Upravo ste unijeli novi doprinos.", "success", "dodjela-doprinosa");
      }
    }
  }, [loading]);

  React.useEffect(()=>{
    console.log("dateStr je postavljen na " + Math.random());
    console.log(dateStr);
    if (stanje === 0) {
      if (dateStr !== null) {
        console.log("okinuo sam " + Math.random());
        if (odabraniVozacID === "-") {
          // ovaj slucaj se nikada ne bi trebao dogoditi zato sto u ovom slucaju nema gumba add
          postaviFlashPoruku("Prije odabira datuma morate prvo odabrati vozaca", "danger", "dodjela-doprinosa1");
          setDateStr(null);
        } else {
          if (akcija === "dodaj_doprinos") {
            if (intervali.find((el)=>{if (el.kraj === null) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novi interval doprinosa dok je neki interval nezatvoren (postavljen 'do daljnjeg')", "danger", "dodjela-doprinosa1");
              setDateStr(null);
              return
            }
            let p = dateStr.split(".");
            let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));

            if (intervali.find((el)=>{if (el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj)) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, nalazi se unutar vec postojeceg intervala doprinosa.", "danger", "dodjela-doprinosa1");
              setDateStr(null);
              return;
            }
            setDatumPocetka(dateStr);
          } else {
            if (intervali.find((el)=>{if (el.kraj === null && el.idVeze.toString() !== odabraniDoprinosID) return true; return false;}) !== undefined) {
              postaviFlashPoruku("Ne mozete otvoriti novi interval doprinosa dok je neki interval nezatvoren (postavljen 'do daljnjeg')", "danger", "dodjela-doprinosa1");
              setDateStr(null);
              return
            }
            let p = dateStr.split(".");
            let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));
            if (intervali.find((el)=>{if ((el.kraj === null || (brojD >= el.pocetak && brojD < el.kraj))  &&  el.idVeze.toString() !== odabraniDoprinosID) return true; return false}) !== undefined) {
              // pocetak intervala je unutar vec postojeceg intervala
              postaviFlashPoruku("Datum je nelegalan, nalazi se unutar vec postojeceg intervala doprinosa.", "danger", "dodjela-doprinosa1");
              setDateStr(null);
              return;
            }
            setDatumPocetka(dateStr);
          }
        }
      }
    } else if (stanje === 1) {
      if (dateStr !== null && datumPocetka !== null) {
        console.log("okinuo sam1 " + Math.random());
        let p = dateStr.split(".");
        let brojD = brDanaOd2000(parseInt(p[2])) + brDanaOdPocetkaGodine(parseInt(p[2]),parseInt(p[1]),parseInt(p[0]));

        let p1 = datumPocetka.split(".");
        let brojD1 = brDanaOd2000(parseInt(p1[2])) + brDanaOdPocetkaGodine(parseInt(p1[2]),parseInt(p1[1]),parseInt(p1[0]));

        if (brojD1 >= brojD) {
          postaviFlashPoruku("Datum odjave ne moze biti jednak ili manji od datuma pocetka doprinosa", "danger", "dodjela-doprinosa1");
          setDateStr(null);
          return;
        }
        if (akcija === "dodaj_doprinos"  &&  intervali.find((el)=>{if (el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak)) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom doprinosa", "danger", "dodjela-doprinosa1");
          setDateStr(null);
          return;
        } 

        if (akcija === "modificiraj_doprinos"  &&  intervali.find((el)=>{if ((el.kraj === null || (brojD1 < el.pocetak && brojD > el.pocetak))  &&  el.idVeze.toString() !== odabraniDoprinosID) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Zadani interval se preklapa sa vec postojecim intervalom doprinosa", "danger", "dodjela-doprinosa1");
          setDateStr(null);
          return;
        } 
        setDatumZavrsetka(dateStr);
      }
    }
  }, [dateStr]);

  React.useEffect(()=>{
    let rez : IntervalTip[] = [];
    for (let i = 0; i < doprinosi.length; i++) {
      let pocetak = doprinosi[i].pocetak;
      let kraj = doprinosi[i].kraj;
      let idVeze = doprinosi[i].id_veze;
      let p = pocetak.split("-");
      let br1 = brDanaOd2000(parseInt(p[0])) + brDanaOdPocetkaGodine(parseInt(p[0]),parseInt(p[1]),parseInt(p[2]));
      let br2 = null;
      if (kraj !== null) {
        p = kraj.split("-");
        br2 = brDanaOd2000(parseInt(p[0])) + brDanaOdPocetkaGodine(parseInt(p[0]),parseInt(p[1]),parseInt(p[2]));
      }
      rez.push({"idVeze": idVeze, "pocetak": br1, "kraj": br2});
    }
    setIntervali(rez);

    if (akcija === "dodaj_doprinos") {
      if (doprinosi.length === 0) {
        setStanje(0);
        setDatumPocetka(null);
        setDatumZavrsetka(null);
      }
      if (odabraniVozacID === "-") {
        postaviFlashPoruku("Vozac nije odabran. Zatvorite prozor, odaberite vozaca, i onda pokusajte ponovo", "danger", "dodjela-doprinosa1");
      } else {
        postaviFlashPoruku("Odaberite datum za pocetak doprinosa vozaca.", "success", "dodjela-doprinosa1");
      }
      setMjesecniDoprinos1(mjesecniDoprinos);
    } else {
      let el = doprinosi.find((el)=>{return el.id_veze.toString() === odabraniDoprinosID});
      if (el !== undefined) {
        let elp = el.pocetak.split("-");
        setDatumPocetka(elp[2] + "." + elp[1] + "." + elp[0]);
        if (el.kraj === null) {
          setDatumZavrsetka(null);
        } else {
          elp = el.kraj.split("-");
          setDatumZavrsetka(elp[2] + "." + elp[1] + "." + elp[0]);
        }
        setMjesecniDoprinos1(el.mjesecni_doprinos.toString());
        setDnevniBrojSati(el.dnevni_broj_sati.toString());
        setStanje(1);
        postaviFlashPoruku("Ovdje modificirate doprinos. Odaberite datum za kraj doprinosa.", "success", "dodjela-doprinosa1");
      } else {
        postaviFlashPoruku("Dogodila se GRESKA(1).", "danger", "dodjela-doprinosa1");
      }
    }

  },[]);

  React.useEffect(()=>{
    if (br1 !== 0) {
      if (akcija === "dodaj_doprinos")  setBr((prev)=>{return prev+1});
      if (akcija === "modificiraj_doprinos") {
        //postaviFlashPoruku("Ovo je simulacija modifikacije doprinosa.", "success", "dodjela-doprinosa1");
        setBr((prev)=>{return prev+1});
      }
    }
  }, [br1]);

  function zatvoriPopup() {
    setConfirmBr(0);
  }

  function gumbKlik(e:React.MouseEvent<HTMLDivElement>) {
    let el = e.currentTarget;
    el.classList.add("klik-1");
    setTimeout(()=>{el.classList.remove("klik-1")}, 150);

    if (el.id === "gumb-ponisti") {
      setDatumPocetka(null);
      setDateStr(null);
      postaviFlashPoruku("Upravo ste se vratili na pocetak", "success", "dodjela-doprinosa1");
    } else if (el.id === "gumb-sljedece") {
      setDateStr(null);
      setStanje(1);
      postaviFlashPoruku("Unesite datum odjave doprinosa ili kliknite na gumb 'Do daljnjeg'", "success", "dodjela-doprinosa1");
    } else if (el.id === "gumb-natrag") {
      setDateStr(datumPocetka);
      setStanje(0);
      setDatumZavrsetka(null);
      postaviFlashPoruku("Vratili ste se na datum pocetka doprinosa.", "success", "dodjela-doprinosa1");
    } else if (el.id === "gumb-daljnjeg") {
      setDatumZavrsetka(null);
      
      if (datumPocetka !== null) {
        let p1 = datumPocetka.split(".");
        let brojD1 = brDanaOd2000(parseInt(p1[2])) + brDanaOdPocetkaGodine(parseInt(p1[2]),parseInt(p1[1]),parseInt(p1[0]));
      
        if (intervali.find((el)=>{if (el.kraj === null || brojD1 < el.pocetak) return true; return false}) !== undefined) {
          // intervali se preklapaju
          postaviFlashPoruku("Ne mozete odabrati do daljnjeg zato sto u buducnosti vozaca imate zadan interval doprinosa", "danger", "dodjela-doprinosa1");
          return;
        }
    
        if (dnevniBrojSati === "") {
          postaviFlashPoruku("Niste zadali dnevno radno vrijeme vozaca", "danger", "dodjela-doprinosa1");
        } else if (mjesecniDoprinos1 === "") {
          postaviFlashPoruku("Niste zadali iznos mjesecnog doprinosa za puno radno vrijeme", "danger", "dodjela-doprinosa1");
        } else {
          setBr1((prev)=>{return prev+1});
        }
      }

    } else if (el.id === "gumb-odaberi") {
      if (dnevniBrojSati === "") {
        postaviFlashPoruku("Niste zadali dnevno radno vrijeme vozaca", "danger", "dodjela-doprinosa1");
      } else if (mjesecniDoprinos1 === "") {
        postaviFlashPoruku("Niste zadali iznos mjesecnog doprinosa za puno radno vrijeme", "danger", "dodjela-doprinosa1");
      } else {
        setBr1((prev)=>{return prev+1});
      }
    }
    
    //if (odabraniVozacID !== "-") setBr1((prev)=>{return prev+1});
  }

  function inputFun(e: React.ChangeEvent<HTMLInputElement>, filterFun: (str:string)=>boolean = ()=>{return true}) {
    let str = e.target.value;
    let str1 = str.toLocaleLowerCase();
    if (!filterFun(str1)) return;

    if (e.target.id === "str") {
      setSearchStr(str1);
    } else if (e.target.id === "str-mjesec") {
      let br = 0;
      let sw = true;
      let brDec = 0;
      for (let i = str1.length - 1; i > -1; i--) {
        if (str1[i] === ".") {
          br++;
          sw = false;
          if (br > 1)  break;
        }
        if (sw) {
          brDec++;
        }
      }
      if (parseFloat(str) < 0 || parseFloat(str) >= 10000) {
        postaviFlashPoruku("Mjesecni doprinos mora biti pozitivni broj manji od 10000 sa do dvije decimale", "danger", "dodjela-doprinosa1");
        return;
      } else if (brDec > 2 && br === 1) {
        postaviFlashPoruku("Mjesecni doprinos moze imati do dvije decimale za eurocente", "danger", "dodjela-doprinosa1");  
        return;
      }
      if (br <= 1 && (brDec < 3 || br === 0))  setMjesecniDoprinos1(str1);
    } else {
      let br = 0;
      let sw = true;
      let brDec = 0;
      for (let i = str1.length - 1; i > -1; i--) {
        if (str1[i] === ".") {
          br++;
          sw = false;
          if (br > 1)  break;
        }
        if (sw) {
          brDec++;
        }
      }
      if (br <= 1 && (brDec < 3 || br === 0))  setDnevniBrojSati(str1);
    }

  }

  return (
    <div className="pridruzi-doprinos-popup">
      <div className="okvir-menu">
      

        <div className="flash-container">
          {flashPoruke.map((el: FlashTip)=>{return <Flash key={el.id} id={el.id} setFlashPoruke={setFlashPoruke} tip={el.tip} poruka={el.poruka} kod1="dodjela-doprinosa1" kod2={el.kod} />})}
        </div>
        {loading ? <Spinner/> : null}
        <table className="tablica"><tbody>
          <tr id="r0" className="row">
            <td className="row-el1">Datum dodjele:</td>
            <td className="row-el2">{ispis(datumPocetka)}</td>
          </tr>
          <tr id="r0" className="row">
            <td className="row-el1">Datum povrata:</td>
            <td className="row-el2">{ispis(datumZavrsetka)}</td>
          </tr>
        </tbody></table>
        <div className="kalendar-cont">
          
          
          <div className="lijeva-strana">
            <div className="el-a">
              <form onSubmit={(e)=>{e.preventDefault()}} acceptCharset="UTF-8">
                <div className="input-div-a">
                  <div className="input-div1-a"><label htmlFor="str-mjesec">Upisi mjesecni doprinos:</label></div>
                  <input onChange={(e)=>{inputFun(e, (str)=>{return filterStr(str, false, true)})}} value={mjesecniDoprinos1} className="input-el-a" type="text" id="str-mjesec" name="str-mjesec" placeholder="upisi doprinos za mjesec"/>
                  <div className="input-div1-a"><label htmlFor="str-sati">Upisi dnevni broj sati:</label></div>
                  <input onChange={(e)=>{inputFun(e, (str)=>{return filterStr(str, false, true)})}} value={dnevniBrojSati} className="input-el-a" type="text" id="str-sati" name="str-sati" placeholder="upisi dnevni broj sati"/>
                  <div className="input-div1-a"><label htmlFor="str">Upisi search substring:</label></div>
                  <input onChange={inputFun} value={searchStr} className="input-el-a" type="text" id="str" name="str" placeholder="upisi substring"/>
                </div> 
              </form>
            </div>
            <div className="el1-a">
              <select className="izbornik-a no-click" size={5} onClick={(e)=>{e.stopPropagation(); e.preventDefault()}} 
                 onChange={(e)=>{}} >
                <option value="-">doprinosi:</option>
                {doprinosi.filter((el)=>{return (el.pocetak + " - " + nullToDoDaljnjeg(el.kraj)).toLowerCase().includes(searchStr.toLowerCase())  })
                 .map((el)=>{return <option key={el.id_veze}  selected={akcija !== "dodaj_doprinos" && selSw((el.id_veze).toString(), odabraniDoprinosID)}>{el.pocetak + " - " + nullToDoDaljnjeg(el.kraj)}</option>})}
                
              </select>
            </div>


          </div>

          <Kalendar width={200} mjesecOpen={danasnjiMjesec} godinaOpen={danasnjaGodina} 
              dateStr={dateStr} setDateStr={setDateStr}/>
        </div>
       
        <div className="gumbi">
          {stanje === 0 && datumPocetka !== null ? <div onClick={gumbKlik} id="gumb-ponisti" className="div-button-1">Ponisti</div> : null}
          {stanje === 0 && datumPocetka !== null ? <div onClick={gumbKlik} id="gumb-sljedece" className="div-button-1">Sljedece</div> : null}
          {stanje === 1 ? <div onClick={gumbKlik} className="div-button-1" id="gumb-natrag">Natrag</div> : null}
          {stanje === 1 ? <div onClick={gumbKlik} className="div-button-1" id="gumb-daljnjeg">Do daljnjeg</div> : null}
          {stanje === 1 && datumPocetka !== null ? <div onClick={gumbKlik} className="div-button-1" id="gumb-odaberi">Odaberi</div> : null}
        </div>
        <div className="krizic" onClick={zatvoriPopup}>
          <div className="krizic-el" onClick={()=>{}}>
            <div className="el1a"></div>
            <div className="el2a"></div>
          </div>
        </div>

      </div>
    </div>
  )
}