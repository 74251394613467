import React, { ReactElement, ReactHTMLElement } from 'react';
import ReactDOM from 'react-dom/client';
import './kalendar.scss';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { NumericLiteral } from 'typescript';

function isLeapYear(year: number) : boolean {
    if (year % 4 === 0) {
      if (year % 100 === 0 && year % 400 !== 0)  return false;
      return true;
    }
    return false;
} 

export function tranfsormDateStr(str: string | null): string {
  if (str === null) {
    return "DO DALJNJEG";
  } else if (str.includes("-")) {
    let pp = str.split("-");
    return dodajNulu(parseInt(pp[2])) + "." + dodajNulu(parseInt(pp[1])) + "." + dodajNulu(parseInt(pp[0])) + ".";
  } else {
    let pp = str.split(".");
    return dodajNulu(parseInt(pp[2])) + "-" + dodajNulu(parseInt(pp[1])) + "-" + dodajNulu(parseInt(pp[0]));
  }
}

export function dodajNulu(inp: number): string {
  if (inp < 10) return "0" + inp.toString();
  return inp.toString();
}

export function brDanaOdDatumaStr(br: number, datum: string) {
  // ovo je wrapper funkcija za donju, zato procitaj dolje opis. ova funkcija uzima datum
  // u obliku stringa, pomice datum za par dana i vraca ponovo datum u obliku stringa

  let pp = null;
  let sw = false;
  if (datum.includes(".")) {
    sw = true;
    pp = datum.split(".");
    pp = [pp[0], pp[1], pp[2]];
  } else {
    pp = datum.split("-");
    pp = [pp[2], pp[1], pp[0]];
  }
  let rr = brDanaOdDatuma(br, parseInt(pp[2]), parseInt(pp[1]), parseInt(pp[0]));
  if (sw) {
    return dodajNulu(rr[0])+"."+dodajNulu(rr[1])+"."+dodajNulu(rr[2]);
  } else {
    return dodajNulu(rr[2])+"-"+dodajNulu(rr[1])+"-"+dodajNulu(rr[0]);
  }
}

export function brDanaOdDatuma(br: number, year: number, month: number, day: number) {
  // ova funkcija uzima br dana od nekog datuma zadanog sa year, month, day, te vraca novi
  // datum pomaknut za toliko dana, br mora biti manji ili jednak 365, funkcija je zamisljena
  // da vraca npr 7 dana naprijed ili mjesec dana, a ne godinu ili vise
  
  let sw = isLeapYear(year);
  let br1 = brDanaOdPocetkaGodine(year, month, day) + br;
  let y = 0;
  if (sw) {
    if (br1 >= 366) {
      br1 -= 366;
      y += 1;
      sw = false;
    }
  } else {
    if (br1 >= 365) {
      br1 -= 365;
      y += 1;
      sw = isLeapYear(year+1);
    }
  }
  let rez = brDanaToDatum(br1, sw);
  return [rez[0], rez[1], year+y];
}

export function brDanaOd2000(year: number) : number {
  // ova pomocna funcija proracunava broj proteklih dana od 1.1.2000. do 1.1. zadane godine
  let br = year - 2000 - 1;
  let brLeap = 1;
  brLeap += Math.floor(br/4);
  brLeap -= Math.floor(br/100);
  brLeap += Math.floor(br/400);
  return 365*(year-2000) + brLeap;
}

function brDanaToDatum(br: number, leapSw: boolean) : [number,number] {
  // ova funkcija prima broj dana od pocetka godine i da li je godina prijestupna i vraca dan u mjesecu i mjesec
  let b = 0;
  if (leapSw) b = 1;

  if (br < 0) {
    return [31+br+1, 12];
  } else if (br < 31) {
    return [br+1, 1];
  } else if (br < 31+28+b) {
    return [br-31+1, 2];
  } else if (br < 31+28+31+b) {
    return [br-31-28+1-b, 3];
  } else if (br < 31+28+31+30+b) {
    return [br-31-28-31+1-b, 4];
  } else if (br < 31+28+31+30+31+b) {
    return [br-31-28-31-30+1-b,5];
  } else if (br < 31+28+31+30+31+30+b) {
    return [br-31-28-31-30-31+1-b,6];
  } else if (br < 31+28+31+30+31+30+31+b) {
    return [br-31-28-31-30-31-30+1-b,7];
  } else if (br < 31+28+31+30+31+30+31+31+b) {
    return [br-31-28-31-30-31-30-31+1-b,8];
  } else if (br < 31+28+31+30+31+30+31+31+30+b) {
    return [br-31-28-31-30-31-30-31-31+1-b,9];
  } else if (br < 31+28+31+30+31+30+31+31+30+31+b) {
    return [br-31-28-31-30-31-30-31-31-30+1-b,10];
  } else if (br < 31+28+31+30+31+30+31+31+30+31+30+b) {
    return [br-31-28-31-30-31-30-31-31-30-31+1-b,11];
  } else if (br < 31+28+31+30+31+30+31+31+30+31+30+31+b) {
    return [br-31-28-31-30-31-30-31-31-30-31-30+1-b,12];
  } else {
    return [br-31-28-31-30-31-30-31-31-30-31-30-31+1-b,1];
  }

  //return [-1,-1];
}

export function brDanaOdPocetkaGodine(year: number, month: number, day: number) : number {
  let leapSw = isLeapYear(year);
  let rez = 0;
  if (month > 2 && leapSw)  rez = 1;
  switch (month) {
    case 1:
      rez += 0;
      break;
    case 2:
      rez += 31;
      break;
    case 3:
      rez += 59;
      break;
    case 4:
      rez += 90;
      break;
    case 5:
      rez += 120;
      break;
    case 6:
      rez += 151;
      break;
    case 7: 
      rez += 181
      break;
    case 8: 
      rez += 212;
      break;
    case 9:
      rez += 243;
      break;
    case 10:
      rez += 273;
      break;
    case 11:
      rez += 304;
      break;
    case 12:
      rez += 334;
      break;
    default:        
  }
  
  return rez + day - 1;
}

function danUTjednuFun(br: number) : string {
  // br je broj dana od 1.1.2000. kada je bila subota
  let b = br % 7;
  switch (b) {
    case 0:
      return "Sub";
    case 1:
      return "Ned";
    case 2:
      return "Pon";
    case 3:
      return "Uto";
    case 4:
      return "Sri";
    case 5:
      return "Cet";
    case 6:
      return "Pet";     
    default:
      return "Greska"
  }
}

function brDanaOd2000a(year: number) : number {
  let rez = 0;
  for (let i = 2000; i < year; i++) {
    if (isLeapYear(i)) {
      rez += 366;
    } else {
      rez += 365;
    }
  }
  return rez;
}

function mjesecStr(mjesec: number) : string {
  switch (mjesec) {
    case 1:
      return "Sijecanj";
    case 2:
      return "Veljaca";
    case 3:
      return "Ozujak";
    case 4:
      return "Travanj";
    case 5:
      return "Svibanj";
    case 6:
      return "Lipanj";
    case 7:
      return "Srpanj";
    case 8:
      return "Kolovoz";
    case 9:
      return "Rujan";
    case 10:
      return "Listopad";
    case 11:
      return "Studeni";
    case 12:
      return "Prosinac";
    default:
      return "Greska";
  }
}

export function otvoriKalendar(str: string | null) {
  if (str === null) return {};
  let el = str.split(".");
  let rez = {"mjesecOpen": parseInt(el[1]), "godinaOpen": parseInt(el[2])}
  return rez;
}

type KalendarProps = {
  width?: number;  // sirina u pixelima
  dateStr?: string | null;
  setDateStr?: React.Dispatch<React.SetStateAction<string | null>>;
  mjesecOpen?: number;  // koji mjesec default otvaramo
  godinaOpen?: number;  // koju godinu default otvaramo
  ponSw?: boolean;  // za true su samo ponedjeljci clickable, za false su svi
}

export function Kalendar({width=400, dateStr=null, setDateStr=()=>{},
      mjesecOpen=1, godinaOpen=2023, ponSw=false}: KalendarProps) {
  const [dan, setDan] = React.useState(-1);
  const [mjesec, setMjesec] = React.useState(-1);
  const [godina, setGodina] = React.useState(-1);
  const [odabraniDan, setOdabraniDan] = React.useState<number | null>(null);
  const [odabraniMjesec, setOdabraniMjesec] = React.useState<number | null>(null);
  const [odabranaGodina, setOdabranaGodina] = React.useState<number | null>(null);
  const [poljeEl, setPoljeEl] = React.useState<ReactElement[]>([]);
  const [pocSw, setPocSw] = React.useState(true);
  
  const r = React.useRef<HTMLDivElement | null>(null);

  const stil1 = {"width": width+"px", "height": 9/8*width+"px"}
  const stil2 = {"fontSize": 3/50*width+"px"};
  const stil3 = {"height": 1/15*width+"px", "width": 1/15*width+"px", "top": (width*9/8*0.11111/2 -  1/15/2*width)+"px", "left": 1/45*width+"px"};
  const stil4 = {"height": 1/15*width+"px", "width": 1/15*width+"px", "top": (width*9/8*0.11111/2 -  1/15/2*width)+"px", "right": 1/45*width+"px"};
  const stil5 = {"fontSize": 1/20*width+"px"};
  const stil6 = {"fontSize": 1/20*width+"px"};

  React.useEffect(()=>{
    setMjesec(mjesecOpen);
    setGodina(godinaOpen);
  }, [])

  React.useEffect(()=>{
    if (dateStr !== null) {
      let [el1, el2, el3] = dateStr.split(".");
      setOdabranaGodina(parseInt(el3));
      setOdabraniMjesec(parseInt(el2));
      setOdabraniDan(parseInt(el1));
      //console.log("postavio sam dan, mjesec i godinu");
    } else {
      console.log("postavljam na nule" + Math.random());
      setOdabranaGodina(null);
      setOdabraniMjesec(null);
      setOdabraniDan(null)
      setDan(-1);
      
      if (r.current !== null)  r.current.classList.remove("kliknuti");
    }
    setPocSw(false);
  }, [dateStr]);

  React.useEffect(()=>{
    if (!pocSw && odabranaGodina !== null && odabraniMjesec !== null && odabraniDan !== null) {
      setDateStr(odabraniDan+"."+odabraniMjesec+"."+odabranaGodina);
      console.log("postavljam datum " + odabraniDan+"."+odabraniMjesec+"."+odabranaGodina)
    }
    //console.log("postavljam datum " + odabraniDan+"."+odabraniMjesec+"."+odabranaGodina)
  }, [odabraniDan, odabraniMjesec, odabranaGodina]);

  React.useEffect(()=>{
    if (!pocSw && dan !== -1) {
      setOdabraniDan(dan);
      setOdabraniMjesec(mjesec);
      setOdabranaGodina(godina);
      //console.log("postavio sam dan1, mjesec1, godinu1");
    }
  }, [dan]);

  React.useEffect(()=>{
    let br1 = brDanaOd2000(godina);
    let br2 = brDanaOdPocetkaGodine(godina, mjesec, 1);
    let pomak = (br1+br2)%7 - 2;
    if (pomak < 0)  pomak += 7;

    let meda1 = br1 + br2 - pomak;
    let meda2 = meda1 + 42;
    
    setPoljeEl(layout(meda1, meda2));

  }, [mjesec, godina, odabraniDan, odabraniMjesec, odabranaGodina]);

  function layout(meda1: number, meda2: number) : ReactElement[] {
    let polje: ReactElement[] = [];

    let br1 = brDanaOd2000(godina);
    let sw = isLeapYear(godina);

    for (let i = meda1; i < meda2; i++) {
      let pozX = ((i-meda1) % 7 * 14.2857) + "%";
      let pozY = (Math.floor((i-meda1) / 7) * 16.6667) + "%";

      let [dan1, mjesec1] = brDanaToDatum(i-br1, sw);
      let klasa = "el-div";
      let klasa1 = "kruzic"
      if (mjesec1 !== mjesec)  klasa1 = "kruzic-no-hover";
      if (danUTjednuFun(i) === "Ned") klasa += " nedjelja";
      if (ponSw && danUTjednuFun(i) !== "Pon") klasa1 += " unclickable";
      if (dan1 === odabraniDan && mjesec1 === odabraniMjesec && godina === odabranaGodina) {
        klasa1 += " kliknuti";
        let element = <div ref={r} id={dan1 + "." + mjesec1} onClick={klik} className={klasa1}>{dan1}</div>
        
        polje.push(<div key={godina+mjesecStr(mjesec)+i} className={klasa} style={{"left": pozX, "top": pozY}}>
        {element}</div>);
      } else {
        polje.push(<div key={godina+mjesecStr(mjesec)+i} className={klasa} style={{"left": pozX, "top": pozY}}>
          <div id={dan1 + "." + mjesec1} onClick={klik} className={klasa1}>{dan1}</div>
        </div>)
      }
    }
    return polje;
  }

  function klik(e: React.MouseEvent<HTMLDivElement>) {
    if (e.currentTarget.classList.contains("unclickable")) {
      console.log("kliknuo si na unclickable element " + Math.random());
      return;
    }
    let str = e.currentTarget.id;
    //console.log("Kliknuo si na " + str + " / " + Math.random());
    let [dan1, mjesec1] = str.split(".");

    if (mjesec === parseInt(mjesec1)) {
      setDan(parseInt(dan1));
      if (r.current !== null)  r.current.classList.remove("kliknuti");
      e.currentTarget.classList.add("kliknuti");
      r.current = e.currentTarget;
    }
  }
  
  return (
    <div className="kalendar-okvir" style={stil1} onClick={(e)=>{e.stopPropagation()}}>
      <div className="el1-kalendar" style={stil2}>
        <div className="mjesec-el">
          <div className="gumb1-kalendar" style={stil3}  onClick={()=>{setMjesec((prev)=>{return (prev-2+12)%12+1})}}>
            <AiOutlineLeft className="ikona"/>
          </div>
          <div className="gumb2-kalendar" style={stil4} onClick={()=>{setMjesec((prev)=>{return (prev+12)%12+1})}}>
            <AiOutlineRight className="ikona"/>
          </div>
          <p className="p-el-kalendar">{mjesecStr(mjesec)}</p>
        </div>
        <div className="godina-el">
          <div className="gumb3-kalendar" style={stil3} onClick={()=>{setGodina((prev)=>{return prev-1})}}>
            <AiOutlineLeft className="ikona"/>
          </div>
          <div className="gumb4-kalendar" style={stil4} onClick={()=>{setGodina((prev)=>{return prev+1})}}>
            <AiOutlineRight className="ikona"/>
          </div>
          <p className="p-el-kalendar">{godina}</p>
        </div>
      </div>
      <div className="el3-kalendar" style={stil5}>
        <div className="dani">Pon</div>
        <div className="dani">Uto</div>
        <div className="dani">Sri</div>
        <div className="dani">Cet</div>
        <div className="dani">Pet</div>
        <div className="dani">Sub</div>
        <div className="dani ned">Ned</div>
      </div>
      <div className="el2-kalendar" style={stil6}>
        {poljeEl}
      </div>
    </div>
  )
}